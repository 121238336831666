import CommonModalContainer from '@components/common/atoms/CommonModalContainer';
import { COLOR } from '@constants/Color';
import { Modal } from '@mui/material';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

interface ICommonModalProps {
  onClose: () => void;
  open: boolean;
  children: ReactNode | ReactNode[];
  title?: ReactNode;
  animationHeight: number | string;
  backdrop?: boolean;
  onModalOpenAfter?: () => void;
  onModalOpenBefore?: () => void;
  onModalCloseAfter?: () => void;
  onModalCloseBefore?: () => void;
}

const CommonModal: FC<ICommonModalProps> = ({
  open,
  onClose,
  children,
  title,
  animationHeight,
  backdrop,
  onModalOpenAfter,
  onModalOpenBefore,
  onModalCloseAfter,
  onModalCloseBefore,
}) => {
  const [showModal, isShowModal] = useState(false);
  useEffect(() => {
    open && isShowModal(true);
  }, [open]);

  // useEffect(() => {
  //   !showModal && onClose();
  // }, [showModal, onClose]);
  return (
    <Modal
      open={open}
      onClose={() => {
        isShowModal(false);
      }}>
      <CommonModalContainer
        animationHeight={animationHeight}
        active={showModal}
        onAnimationStart={() => {
          if (showModal) {
            onModalOpenBefore && onModalOpenBefore();
          } else {
            onModalCloseBefore && onModalCloseBefore();
          }
        }}
        onAnimationEnd={(event) => {
          if (event.animationName !== 'mui-auto-fill-cancel')
            if (showModal) {
              onModalOpenAfter && onModalOpenAfter();
            } else {
              onClose();
              onModalCloseAfter && onModalCloseAfter();
            }
        }}>
        {title && <TitleContainer>{title}</TitleContainer>}
        {children}
      </CommonModalContainer>
    </Modal>
  );
};

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  border-bottom: 1px ${COLOR.RED} solid;
  position: sticky;
  background: inherit;
  z-index: 4;
  top: 0;
  flex: 1;
`;
export default CommonModal;
