import { useEmailToSabun } from '@apis/common';
import { COLOR } from '@constants/Color';
import CloseIcon from '@mui/icons-material/Close';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import HambergerIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import { useHeaderStore } from '@stores/headerStore';
import { FC, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router';
import { EnumRouteUrl } from 'src/routers/Routers';
import { useAuthorization } from 'src/util/auth';
import styled, { css } from 'styled-components';
import HorizontalBlank from '../atoms/HorizontalBlank';
import CommonUserInfo from './CommonUserInfo';
import GNB from './GNB';
interface CommonHeaderInterface {
  left?: ReactNode;
  right?: ReactNode;
}

const CommonHeader: FC<CommonHeaderInterface> = () => {
  const [menuActive, isMenuActive] = useState(false);
  const [userActive, isUserActive] = useState(false);
  const navigate = useNavigate();
  const { title, isHome } = useHeaderStore();
  const { isLoggedin, handleLogin, tokenData } = useAuthorization();
  const { data: userSearchDataList, error } = useEmailToSabun({
    email: tokenData?.account?.username,
    name: tokenData?.account?.name,
  });
  return (
    <>
      <Header userActive={userActive} menuActive={menuActive}>
        <MenuIcon active={menuActive} onClick={() => isMenuActive((menuActive) => !menuActive)}>
          {menuActive ? <CloseIcon /> : <HambergerIcon />}
        </MenuIcon>
        <TitleFont>{title}</TitleFont>
        {isHome ? (
          <UserIconContainer>
            <IconSmoothBorder active={userActive} />
            {!userSearchDataList?.name && (
              <UserIcon
                active={userActive}
                onClick={() => {
                  isLoggedin ? isUserActive((userActive) => !userActive) : handleLogin();
                }}>
                <PersonIcon />
              </UserIcon>
            )}

            {userSearchDataList?.name && (
              <>
                <Tawon
                  active={userActive}
                  onClick={() => {
                    isLoggedin ? isUserActive((userActive) => !userActive) : handleLogin();
                  }}>
                  <Name>{userSearchDataList?.name}</Name>
                </Tawon>
              </>
            )}
          </UserIconContainer>
        ) : (
          <Icon onClick={() => navigate(EnumRouteUrl.HOME)}>
            <HomeRoundedIcon />
          </Icon>
        )}
      </Header>
      {isHome && <CommonUserInfo open={userActive} />}
      <GNB open={menuActive} onClose={() => isMenuActive(false)} />
      <HorizontalBlank height={60} />
    </>
  );
};

interface IHeaderProps {
  userActive: boolean;
  menuActive: boolean;
}

const Header = styled.header<IHeaderProps>`
  z-index: 4;
  display: flex;
  position: fixed;
  top: 0;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 60px;
  background: ${COLOR.YELLOW};
  /* background: ${COLOR.YELLOW}; */
  border-bottom: 4px solid ${COLOR.RED};
  padding: 0 10px;
  transform: translateX(0);
  transition: all 0.3s ease-in-out;
  ${({ userActive }) =>
    userActive &&
    css`
      border-bottom-color: ${COLOR.YELLOW};
    `}
  ${({ menuActive }) =>
    menuActive &&
    css`
      border-bottom-color: ${COLOR.YELLOW};
      transform: translateX(80vw);
    `}
`;

const TitleFont = styled.span`
  color: ${COLOR.RED};
  font-size: 20px;
  font-weight: 700;
`;

const UserIconContainer = styled.div`
  position: relative;
`;

const IconSmoothBorder = styled.div<ICommonInput>`
  overflow: hidden;
  background: ${COLOR.YELLOW};
  content: '';
  height: 15px;
  position: absolute;
  left: -17px;
  top: 39.5px;
  border-bottom-right-radius: 32px;
  box-shadow: 1px 7.5px 0px 0 ${COLOR.BG_GRAY};
  transition: all 0.2s ease-in-out;
  opacity: 0;
  ${({ active }) =>
    active &&
    css`
      opacity: 1;
    `};
`;
const Name = styled.div`
  font-size: 12px;
  color: white;
`;
const Icon = styled.button<ICommonInput>`
  background: unset;
  border: unset;
  width: 50px;
  height: 45px;
  font-size: 25px;
  color: ${COLOR.RED};
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  :active {
    background-color: ${COLOR.ACTIVE_BACKGROUND};
  }
`;
const MenuIcon = styled(Icon)``;

const UserIcon = styled(Icon)`
  ${({ active }) =>
    active &&
    css`
      transform: translateY(30px);
      color: ${COLOR.YELLOW};
      background: ${COLOR.RED};
      border: solid 5px ${COLOR.BG_GRAY};
      transition: all 0.2s ease-in-out;
    `}
`;

const Tawon = styled(Icon)`
  height: 33px;
  background: #d40511;
  border-radius: 47%;
  ${({ active }) =>
    active &&
    css`
      transform: translateY(30px);
      color: ${COLOR.YELLOW};
      background: ${COLOR.RED};
      border: solid 5px ${COLOR.BG_GRAY};
      transition: all 0.2s ease-in-out;
    `}
`;

export default CommonHeader;
