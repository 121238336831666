import { CSSProperties, FC } from 'react';

interface CommonContainerInterface {
  children: React.ReactNode | React.ReactNodeArray;
  center?: boolean;
  justifyCenter?: boolean;
  alignCenter?: boolean;
  spaceBetween?: boolean;
  direction?: 'row' | 'column';
  flex?: number;
  style?: CSSProperties;
  className?: string;
}
const CommonContainer: FC<CommonContainerInterface> = ({
  children,
  center,
  justifyCenter,
  alignCenter,
  spaceBetween,
  direction = 'column',
  style,
  ...restProps
}) => {
  const defaultStyle: CSSProperties = {
    backgroundColor: 'inherit',
    width: '100%',
    height: '100%',
    padding: 0,
    display: 'flex',
    flexDirection: direction,
    justifyContent: (spaceBetween && 'space-between') || (justifyCenter && 'center') || (center && 'center') || undefined,
    // default: flex-start
    alignItems: (alignCenter && 'center') || (center && 'center') || undefined,
    ...style,
  };

  return (
    <div style={defaultStyle} {...restProps}>
      {children}
    </div>
  );
};

export default CommonContainer;
