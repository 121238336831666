import { useDiseaseFamilyList } from '@apis/disease';
import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonSelect from '@components/common/molecules/CommonSelect';
import { FC } from 'react';

interface IFamilyCodeSelect {
  onChange: (value: string) => void;
}

const FamilyCodeSelect: FC<IFamilyCodeSelect> = ({ onChange }) => {
  const { data: familyCodeList } = useDiseaseFamilyList();

  return (
    <CommonFormField
      name={'famCd'}
      rules={[
        {
          required: true,
        },
      ]}>
      <CommonSelect
        title='가족관계'
        options={familyCodeList?.map((item) => ({
          label: item.codeNm,
          value: item.code.split('|')[0],
        }))}
        onChangeItem={(value) => onChange(value)}
      />
    </CommonFormField>
  );
};

export default FamilyCodeSelect;
