import { IApprovalListInterface } from '@apis/approval';
import ApplStatusCdTemplate from '@components/common/atoms/ApplStatusCdTemplate';
import VerticalBlank from '@components/common/atoms/VerticalBlank';
import { COLOR } from '@constants/Color';
import { GlobalFormat } from '@constants/Format';
import CancelIcon from '@mui/icons-material/Cancel';
import { Divider, Stack } from '@mui/material';
import VacationRequestModal from '@pages/vacation/templates/VacationRequestModal';
import { FC, ReactText, useState } from 'react';
import { formatDate } from 'src/util/commonUtil';
import styled from 'styled-components';
interface IApprovalListProps {
  data: IApprovalListInterface;
  index: ReactText;
}

const ApprovalItem: FC<IApprovalListProps> = ({ data, index }) => {
  const [requestModalShow, isRequestModalShow] = useState(false);

  const renderApprovalModal = () => {
    switch (data.applCd) {
      case '22':
        return (
          <VacationRequestModal
            key={data.applSeq}
            onClose={() => {
              isRequestModalShow(false);
            }}
            open={requestModalShow}
            searchApplSeq={data.applSeq}
            searchApplCd={data.applCd}
            // searchGntCd={data.gntCd}
            // gntGubunCd={data.gntGubunCd}
            title={data.applNm}
            authPg={'결재'}
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      <ItemContainer
        onClick={(e) => {
          isRequestModalShow(true);
        }}>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <HeadDiv>
            <TitleFont>{data.title}</TitleFont>
            <VerticalBlank width={10} />
            <ApplStatusCdTemplate status={data.applStatusCd} />
          </HeadDiv>
        </Stack>
        {data.applStatusCd !== '99' &&
          (data.applStatusCd === '11' ? (
            <HandleIcon onClick={(e) => e.stopPropagation()}>
              <CancelIcon />
            </HandleIcon>
          ) : (
            <HandleIcon onClick={(e) => e.stopPropagation()}></HandleIcon>
          ))}
        <Divider style={{ margin: '0px ​0px 10px 0p' }} />
        <Row>
          <DetailTitleFont>신청서종류</DetailTitleFont>
          <ContentFont>{data.applNm}</ContentFont>
        </Row>
        <Row>
          <DetailTitleFont>기안일</DetailTitleFont>
          <ContentFont>{formatDate(data.applYmd, GlobalFormat.DATE_DASH)}</ContentFont>
        </Row>
        <Row>
          <DetailTitleFont>기안자</DetailTitleFont>
          <ContentFont>
            {data.applName} {data.applJikweeNm}
          </ContentFont>
        </Row>
        <Row>
          <DetailTitleFont>최종결재자</DetailTitleFont>
          <ContentFont>{data.finAgreeName}</ContentFont>
        </Row>
      </ItemContainer>
      {data.applSeq && requestModalShow && renderApprovalModal()}
    </>
  );
};

const ItemContainer = styled.div`
  padding: 13px;
  background-color: ${COLOR.BG_LIGHTGRAY};
  margin-bottom: 13px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 30%);
`;
const Row = styled.div`
  margin: 4px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const HeadDiv = styled.div`
  display: inline-flex;
  flex-direction: row;
`;

const TitleFont = styled.div`
  font-size: 16px;
  color: ${COLOR.BLACK};
  margin-bottom: 10px;
  padding-right: 10px;
`;

const DetailTitleFont = styled.div`
  font-size: 12px;
  color: #a0a0a0;
  flex: 25;
  text-align: right;
  padding: 1px;
`;

const ContentFont = styled.div`
  font-size: 12px;
  color: ${COLOR.GRAY};
  flex: 75;
  padding-left: 10px;
`;

const HandleIcon = styled.div`
  color: ${COLOR.RED};
  float: right;
  margin-top: -32px;
  :active {
    background-color: ${COLOR.ACTIVE_BACKGROUND};
  }
`;

export default ApprovalItem;
