import { useUserInfo } from '@components/common/molecules/CommonUserInfo';
import { axiosInstance, useRequest } from 'src/axios/fetcher';

export interface IApprovalRequestParam {
  appBizCd?: string;
  applSeq?: string;
  applStatusCd?: string;
  sabun?: string;
  sdt?: string;
  edt?: string;
}

export interface IApprovalListInterface {
  applSeq: string;
  title: string;
  applCd: string;
  applNm: string;
  applYmd: string;
  applSabun: string;
  applName: string;
  applAlias: string;
  applJikgubNm: string;
  applJikweeNm: string;
  selfSabun: string;
  selfName: string;
  selfAlias: string;
  selfJikgubNm: string;
  selfJikweeNm: string;
  curSabun: string;
  curName: string;
  curAlias: string;
  curJikgubNm: string;
  curJikweeNm: string;
  applTypeCdNm: string;
  agreeYmd: string;
  memo: string;
  applStatusCd: string;
  applStatusCdNm: string;
  applInSabun: string;
  finAgreeName: string;
}

export interface IApprovalPostParam {
  sabun?: string;
  applSeq: string;
  title: string;
  applCd: string;
  applNm: string;
  applYmd: string;
  applSabun: string;
  applName: string;
  applAlias: string;
  applJikgubNm: string;
  applJikweeNm: string;
  selfSabun: string;
  selfName: string;
  selfAlias: string;
  selfJikgubNm: string;
  selfJikweeNm: string;
  curSabun: string;
  curName: string;
  curAlias: string;
  curJikgubNm: string;
  curJikweeNm: string;
  applTypeCdNm: string;
  agreeYmd: string;
  memo: string;
  applStatusCd: string;
  applStatusCdNm: string;
  applInSabun: string;
}

export const useApprovalList = (params: IApprovalRequestParam) => {
  const { sabun } = useUserInfo();
  return useRequest<IApprovalListInterface[]>({
    url: '/dhl/kr/myhr/approval/getAppBeforeLstList',
    params: { sabun, ...params },
  });
};

export const useApprovalDetail = (params: IApprovalRequestParam) => {
  const { sabun } = useUserInfo();
  return useRequest<IApprovalListInterface[]>({
    url: '/dhl/kr/myhr/approval/getAppBeforeLstList',
    params: { sabun, ...params },
  });
};

export const postApproval = async (params: IApprovalPostParam) => {
  return await axiosInstance.post('/dhl/kr/myhr/certificate/saveCertificate', params);
};
