import { useAddress } from '@apis/proofRequest';
import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonInput from '@components/common/atoms/CommonInput';
import CommonInputDateRange from '@components/common/atoms/CommonInputDateRange';
import { currentCommonMessageAtom } from '@components/common/atoms/CommonMessage';
import { FormInstance } from 'rc-field-form';
import { FC, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';

interface IProofOfEmpKorModalProps {
  form: FormInstance<any>;
  addressType: string;
  tenure?: string;
  sYmd?: string;
  eYmd?: string;
}

const ProofOfGapgTax: FC<IProofOfEmpKorModalProps> = ({ form, addressType, tenure, sYmd, eYmd }) => {
  const { data: addrData } = useAddress({ searchAddrSelect: addressType });
  const setMessage = useSetRecoilState(currentCommonMessageAtom);
  form.setFieldsValue({ empYmd: tenure });
  useEffect(() => {
    form.setFieldsValue({ address: addrData?.[0]?.engAddr ?? '' });
  }, [addrData, form]);
  return (
    <ModalContainer>
      <CommonFormField
        name={'sYmd'}
        rules={[
          {
            required: true,
          },
        ]}>
        <CommonInputDateRange fromLabel='신청기간시작' toLabel='신청기간종료' type={'month'} value={[sYmd, eYmd]} />
      </CommonFormField>
      <CommonFormField
        name={'purpose'}
        rules={[
          {
            required: true,
          },
          {
            validator: (_, value) => {
              if (!value) {
                setMessage({
                  open: true,
                  message: '용도를 입력하여 주십시오.',
                  type: 'error',
                });
                return Promise.reject('용도를 입력하여 주십시오.');
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}>
        <CommonInput label='용도' variant='outlined' />
      </CommonFormField>
      <CommonFormField name={'submitOffice'}>
        <CommonInput label='제출처' variant='outlined' />
      </CommonFormField>
      <CommonFormField name={'etc'}>
        <CommonInput label='기타' variant='outlined' />
      </CommonFormField>
    </ModalContainer>
  );
};

const ModalContainer = styled.div`
  padding: 15px 0;
  display: grid;
  gap: 16px;
  overflow-y: scroll;
  position: relative;
`;

export default ProofOfGapgTax;
