import { COLOR } from '@constants/Color';
import { TextField, TextFieldProps } from '@mui/material';
import { useMemo, useState } from 'react';
import { IFieldChildrenProps } from './CommonForm';
import CommonVibe from './CommonVibe';
interface ICommonTextareaProps {
  readOnly?: boolean;
  fontSize?: number;
  labelFontSize?: number;
}
const CommonTextarea = ({
  iserror,
  meta,
  readOnly,
  fontSize = 16,
  labelFontSize = 16,
  ...props
}: TextFieldProps & IFieldChildrenProps & ICommonTextareaProps) => {
  const [active, setActive] = useState(false);

  const validBorder = useMemo(() => {
    const isRed = !active && iserror;
    return isRed ? { boxShadow: `0px 0px 0px 2px ${COLOR.RED}`, width: 'calc(100% - 4px)', margin: '0 2px' } : {};
  }, [active, iserror]);

  return (
    <CommonVibe iserror={!!iserror}>
      <TextField
        multiline
        minRows={3}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        color={'secondary'}
        {...props}
        InputLabelProps={{
          ...props.InputLabelProps,
          shrink: active || !!props.value,
          style: {
            fontSize: labelFontSize,
          },
        }}
        value={props.value ?? undefined}
        style={{ width: '100%' }}
        InputProps={{
          readOnly,
          ...props.InputProps,
          style: {
            fontSize: fontSize,
            height: 'auto',
            ...validBorder,
          },
        }}
      />
    </CommonVibe>
  );
};

export default CommonTextarea;
