import { useUserInfo } from '@components/common/molecules/CommonUserInfo';
import { currentVacationCancelSearchYear } from '@pages/vacation-cancel/templates/VacationCancelSearchForm';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { axiosInstance, useRequest } from 'src/axios/fetcher';

export interface IRegisteredVacationRequestParam {
  searchApplCd?: string;
  gntCd?: string;
  sYmd?: string;
  eYmd?: string;
}

export interface IVacationCancelListResponse {
  applYmd: string;
  applStatusCd: string;
  applStatusCdNm: string;
  applSeq: string;
  applSabun: string;
  applInSabun: string;
  bApplSeq: string;
  gntCd: string;
  sabun: string;
  year: string;
  gntNm: string;
  sYmd: string;
  eYmd: string;
  holDay: string;
  closeDay: string;
  gntReqReason: string;
  gntGubunCd: string;
}

export interface IVacationCancelDetailResponse {
  sabun: string;
  applStatusCd: string;
  applSeq: string;
  bApplSeq: string;
  gntCd: string;
  sYmd: string;
  eYmd: string;
  bSYmd: string;
  bEYmd: string;
  bHolDay: string;
  bCloseDay: string;
  gntReqReason: string;
}

export interface ICancelGntCodeItem {
  code: string;
  codeNm: string;
  gntGubunCd: string;
}

export interface ICancelVacationPostParam {
  sabun?: string;
  gntCd?: string;
  sYmd?: string;
  applSeq?: string;
  bApplSeq?: string;
  gntReqReason?: string;
}

// 근태취소 코드 조회
export const useCancelGntCodeList = () => {
  return useRequest<ICancelGntCodeItem[]>({
    url: '/dhl/kr/myhr/timemgt/vacationCancelCodeList',
  });
};

// 근태 취소내역 조회
export const useVacationCancleList = () => {
  const { sabun: searchSabun } = useUserInfo();
  const year = useRecoilValue(currentVacationCancelSearchYear);
  const resultSwr = useRequest<IVacationCancelListResponse[]>({
    url: `/dhl/kr/myhr/timemgt/searchVacationCancelList`,
    params: { searchSabun, year },
  });

  const { mutate } = resultSwr;
  useEffect(() => {
    mutate();
  }, [year, mutate]);
  return resultSwr;
};

// 근태취소 상세정보 조회
export const useVacationCancelDetail = (searchApplSeq?: string) => {
  return useRequest<IVacationCancelDetailResponse[]>({
    url: '/dhl/kr/myhr/timemgt/getVacationUpdAppDetList',
    params: { searchApplSeq },
  });
};

// 근태취소 - 휴가내역 조회
export const useRegisteredVacationCancleList = (params?: IRegisteredVacationRequestParam) => {
  const { sabun: searchSabun } = useUserInfo();
  return useRequest<IVacationCancelListResponse[]>({
    url: '/dhl/kr/myhr/timemgt/getVacationUpdAppDetPopupList',
    params: { searchSabun, ...params },
  });
};

// 근태취소 저장
export const postCancelVacation = async (params: ICancelVacationPostParam) => {
  return await axiosInstance.post('/dhl/kr/myhr/timemgt/saveVacationUpdAppDet', params);
};
