import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import CommonConfirm from '@components/common/atoms/CommonConfirm';
import CommonMessage from '@components/common/atoms/CommonMessage';
import CommonProgress from '@components/common/atoms/CommonProgress';
import { COLOR } from '@constants/Color';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Button, createTheme, ThemeProvider } from '@mui/material';
import { orange } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { BrowserView, MobileView } from 'react-device-detect';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { SWRConfig } from 'swr';
import './App.css';
import { msalConfig } from './authConfig';
import CommonLayout from './components/common/organisms/CommonLayout';
import Routers from './routers/Routers';
import React, { useEffect } from 'react';

export const msalInstance = new PublicClientApplication(msalConfig);

const DHLTheme = createTheme({
  palette: {
    primary: {
      main: COLOR.RED,
    },
    secondary: {
      main: COLOR.YELLOW,
    },
  },
  typography: {
    fontFamily: `Delivery`,
  },
});

function App() {
 // Frame Buster Script
 useEffect(() => {
  if (window.top !== window.self && window.top) {
    window.top.location = window.self.location;
  }
}, []);

  return (
    <div className='App'>
      <header className='App-header'>
        <BrowserView>
          <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <a href='https://mykulwspc000235.kul-dc.dhl.com:24154/Login.do'>
              <ColorButton style={{ height: '50px', fontSize: '20px' }} variant='contained'>
                웹 버전 myHR로 이동하기
              </ColorButton>
            </a>
          </div>
        </BrowserView>
        <MobileView>
          <MsalProvider instance={msalInstance}>
            <SWRConfig
              value={
                {
                  // revalidateIfStale: false,
                  // revalidateOnFocus: false,
                  // revalidateOnReconnect: false,
                }
              }>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <ThemeProvider theme={DHLTheme}>
                  <RecoilRoot>
                    <CommonProgress />
                    <CommonMessage />
                    <CommonConfirm />
                    <BrowserRouter>
                      <CommonLayout>
                        <Routers />
                      </CommonLayout>
                    </BrowserRouter>
                  </RecoilRoot>
                </ThemeProvider>
              </LocalizationProvider>
            </SWRConfig>
          </MsalProvider>
        </MobileView>
      </header>
    </div>
  );
}

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(orange[500]),
  backgroundColor: orange[500],
  '&:hover': {
    backgroundColor: orange[700],
  },
}));
export default App;
