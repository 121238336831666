import LOGO from '@assets/images/myHR_Logo.png';
import 경조금 from '@assets/images/경조금_gray.png';
import 근태신청 from '@assets/images/근태신청_gray.png';
import 근태관리 from '@assets/images/근태신청_red.png';
import 근태취소 from '@assets/images/근태신청취소_gray.png';
import 급여명세서 from '@assets/images/급여명세서_gray.png';
import 개인급여관리 from '@assets/images/급여명세서_red.png';
import 대출금 from '@assets/images/대출_gray.png';
import 복리후생 from '@assets/images/복리후생_red.png';
import 신청결재 from '@assets/images/신청결재_red.png';
import 제증명신청 from '@assets/images/제증명신청_gray.png';
import 질환위로금 from '@assets/images/질환위로금_gray.png';
import 학자금 from '@assets/images/학자금_gray.png';
import { COLOR } from '@constants/Color';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EnumRouteUrl } from 'src/routers/Routers';
import { useAuthorization } from 'src/util/auth';
import styled, { css, keyframes } from 'styled-components';
import Masking from '../atoms/Masking';

interface IGNBProps {
  open: boolean;
  onClose?: () => void;
}

interface IMenuItem {
  label: string;
  menuId: string;
  subMenu?: IMenuItem[];
  icon?: ReactNode;
  url?: EnumRouteUrl;
}

const GNB: FC<IGNBProps> = ({ open, onClose }) => {
  const [activeMenuId, setActiveMenuId] = useState<string | null>(null);
  const handleActiveMenu = (index: string) => {
    setActiveMenuId((prevActiveId) => (prevActiveId === index ? null : index));
  };

  const MENU_LIST: IMenuItem[] = [
    {
      label: '근태관리',
      menuId: 'vacation',
      icon: <ImageIcon src={근태관리} />,
      subMenu: [
        {
          label: '근태신청',
          menuId: 'vacationRequest',
          url: EnumRouteUrl.VACATION,
          icon: <ImageIcon src={근태신청} />,
        },
        {
          label: '근태취소신청',
          menuId: 'vacationRequestCancel',
          url: EnumRouteUrl.VACATION_CANCEL,
          icon: <ImageIcon src={근태취소} />,
        },
      ],
    },
    {
      label: '복리후생',
      menuId: 'life',
      icon: <ImageIcon src={복리후생} />,
      subMenu: [
        {
          label: '학자금 신청',
          menuId: 'studentFunds',
          icon: <ImageIcon src={학자금} />,
          url: EnumRouteUrl.STUDENT_FUNDS,
        },
        {
          label: '경조금 신청',
          menuId: 'familyEvent',
          icon: <ImageIcon src={경조금} />,
          url: EnumRouteUrl.FAMILY_EVENT,
        },
        {
          label: '질환위로금 신청',
          menuId: 'disease',
          icon: <ImageIcon src={질환위로금} />,
          url: EnumRouteUrl.DISEASE,
        },
        { label: '대출 신청', menuId: 'loan', icon: <ImageIcon src={대출금} />, url: EnumRouteUrl.LOAN },
      ],
    },
    {
      label: '신청결재',
      menuId: 'check',
      icon: <ImageIcon src={신청결재} />,
      subMenu: [
        {
          label: '제증명신청',
          icon: <ImageIcon src={제증명신청} />,
          menuId: 'proof',
          url: EnumRouteUrl.PROOF,
        },
      ],
    },
    {
      label: '개인급여관리',
      menuId: 'payslip',
      icon: <ImageIcon src={개인급여관리} />,
      subMenu: [
        {
          label: '급여명세서',
          icon: <ImageIcon src={급여명세서} />,
          menuId: 'payslip',
          url: EnumRouteUrl.PAYSLIP,
        },
      ],
    },
  ];
  const [visible, isVisible] = useState(false);
  useEffect(() => {
    open && isVisible(open);
  }, [open]);

  useEffect(() => {
    if (visible) {
      document.body.classList.add('ReactModal__Body--open');
    } else {
      document.body.classList.remove('ReactModal__Body--open');
      setActiveMenuId(null);
    }
  }, [visible]);

  const navigate = useNavigate();
  const { isLoggedin, handleLogin } = useAuthorization();
  const handleRouter = (url?: EnumRouteUrl, event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isLoggedin) {
      handleLogin();
    } else if (url) {
      navigate(url);
      onClose && onClose();
    }
    event && event.stopPropagation();
  };
  return visible ? (
    <>
      <Masking open={open} onClick={onClose}></Masking>
      <GNBContainer open={open} onAnimationEnd={(e) => isVisible(open)}>
        <ItemContainer onClick={() => handleRouter(EnumRouteUrl.HOME)}>
          <MyHrLogoImage src={LOGO} alt='myhr' />
        </ItemContainer>
        <BorderSmooth open={open} />
        {MENU_LIST?.map((item) => (
          <MenuContainer key={item.menuId}>
            <ItemContainer
              onClick={() => (item.subMenu ? handleActiveMenu(item.menuId) : handleRouter(item?.url))}
              key={item.menuId}>
              {item.icon}
              <ItemTitleFont>{item.label}</ItemTitleFont>
              {item.subMenu?.length &&
                (activeMenuId === item.menuId ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />)}
            </ItemContainer>
            {(item.subMenu?.length ?? 0) > 0 && (
              <SubMenuContainer active={activeMenuId === item.menuId}>
                {item.subMenu?.map((subItem) => (
                  <SubItemContainer
                    onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleRouter(subItem.url, event)}
                    key={`sub_${subItem.menuId}`}>
                    {subItem.icon}
                    <SubItemTitleFont>{subItem.label}</SubItemTitleFont>
                  </SubItemContainer>
                ))}
              </SubMenuContainer>
            )}
          </MenuContainer>
        ))}
        <ItemContainer>
          <></>
        </ItemContainer>
      </GNBContainer>
    </>
  ) : null;
};

const MyHrLogoImage = styled.img`
  width: 80px;
`;

const slideIn = keyframes`
    0%{
        transform: translateX(-100%);
    }
    100%{
        transform: translateX(0);
    }
`;
const slideOut = keyframes`
    0%{
        transform: translateX(0);
    }
    100%{
        transform: translateX(-100%); 
    }
`;
const GNBContainer = styled.div<IGNBProps>`
  display: flex;
  flex-direction: column;
  background: ${COLOR.YELLOW};
  height: 100vh;
  position: fixed;
  width: 80vw;
  padding-right: 6px;
  z-index: 4;
  ${({ open }) => css`
    animation: ${open ? slideIn : slideOut} 0.3s ease-in-out forwards;
  `}
`;

const BorderSmooth = styled.div<IGNBProps>`
  position: absolute;
  display: none;
  ${({ open }) =>
    open &&
    css`
      display: block;
      width: 30px;
      height: 30px;
      top: 60px;
      left: 80vw;
      border-top-left-radius: 50%;
      box-shadow: -3px -9px 0 0 ${COLOR.YELLOW};
    `}
`;

const MenuContainer = styled.div`
  margin-bottom: 2px;
`;

const ItemContainer = styled.div<{ children: any }>`
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  margin-top: 1px;
  width: 100%;
  background: white;
  display: flex;
  padding: 16px 15px;
  color: ${COLOR.RED};
  align-items: center;
  :active {
    background-color: ${COLOR.ACTIVE_BACKGROUND};
  }
  &:first-child {
    border-top-right-radius: 0;
    margin-top: 0;
    padding: 12px;
  }
  &:last-child {
    justify-content: flex-start;
    border-bottom-right-radius: 0;
    flex: 1;
    :active {
      background-color: white;
    }
  }
`;

const ItemTitleFont = styled.span`
  font-size: 16px;
  margin-left: 5px;
  flex: 1;
`;
const SubMenuContainer = styled.div<ICommonInput>`
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  max-height: 1.5px;
  ${({ active }) =>
    active &&
    css`
      max-height: 200px;
    `}
`;

const SubItemContainer = styled.div`
  margin-right: 25px;
  padding: 10px 10px 10px 35px;
  color: ${COLOR.GRAY};
  border-bottom: 1px solid ${COLOR.BG_GRAY};
  display: flex;
  align-items: center;
  :active {
    background-color: ${COLOR.ACTIVE_BACKGROUND};
  }
  &:last-child {
    border-bottom: 0px;
  }
`;

const SubItemTitleFont = styled.span`
  font-size: 13px;
  margin-left: 5px;
`;

const ImageIcon = styled.img`
  width: 18px;
  height: 18px;
`;
export default GNB;
