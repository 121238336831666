import { ICommonRequestInfoInterface } from '@apis/common';
import {
  getFamilyDuplicationYn,
  postDisease,
  postDiseaseTarget,
  useDiseaseBankCodeList,
  useDiseaseDetail,
  useDiseaseInfo,
  useDiseaseList,
  useDiseaseTargetDetail,
  useYearDuplicationYn,
} from '@apis/disease';
import { IFamilyEventPostParam } from '@apis/familyEvent';
import CommonForm from '@components/common/atoms/CommonForm';
import { currentCommonMessageAtom } from '@components/common/atoms/CommonMessage';
import { useUserInfo } from '@components/common/molecules/CommonUserInfo';
import CommonRequestModal from '@components/common/organisms/CommonRequestModal';
import { GlobalFormat } from '@constants/Format';
import moment, { Moment } from 'moment';
import Form, { FormInstance } from 'rc-field-form';
import { FC, useEffect, useMemo, useState } from 'react';
import { atom, useSetRecoilState } from 'recoil';
import { formatDate } from 'src/util/commonUtil';
import styled from 'styled-components';
import AddDiseaseRequestTemplate from './AddDiseaseRequestTemplate';
import BankGroup from './BankGroup';
import DiseaseAmountInfo from './DiseaseAmountInfo';
import DiseaseInfo from './DiseaseInfo';
import SearchBankType from './SearchBankType';

interface IDiseaseModalProps {
  onClose: () => void;
  open: boolean;
  applSeq?: string;
  authPg?: AUTH_TYPE;
}

interface IFormParamInterface {
  list: IFormListItemInterface[];
  bankType: string;
  bankCd: string;
  accNo: string;
  accNm: string;
}

interface IFormListItemInterface {
  conMon: string;
  payMon: string;
  dates: [string, string];
  medicalNm: string;
  documents: string[];
  famCd: string;
  famNm: string;
}

export interface IFamilyEventFormParam extends Omit<IFamilyEventPostParam, 'famBirYmd' | 'occDate'> {
  famBirYmd: Moment;
  occDate: Moment;
}

export const diseaseFormAtom = atom<FormInstance | null>({
  key: 'diseaseFormAtom',
  default: null,
});

const DiseaseRequestModal: FC<IDiseaseModalProps> = ({ authPg, open, onClose, applSeq }) => {
  const setMessage = useSetRecoilState(currentCommonMessageAtom);
  const { mutate } = useDiseaseList();
  const [commonInfo, setCommonInfo] = useState<ICommonRequestInfoInterface>();
  const { sabun } = useUserInfo();

  const { data: detailData } = useDiseaseDetail(open ? applSeq : undefined);
  const { data: detailTargetList } = useDiseaseTargetDetail(open ? applSeq : undefined);
  const [form] = Form.useForm();
  const setForm = useSetRecoilState(diseaseFormAtom);
  const [conMon, setConMon] = useState(0);
  const [payMon, setPayMon] = useState(0);
  const { data } = useDiseaseInfo();
  const diseaseInfo = useMemo(() => data?.[0], [data]);

  const { data: yearDupYn, mutate: reloadYearDupYn } = useYearDuplicationYn({
    yearLimitAmt: diseaseInfo?.limitAmt,
    searchApplSeq: applSeq || commonInfo?.applSeq,
  });

  useEffect(() => {
    setForm(form);
  }, [form, setForm]);

  useEffect(() => {
    if (applSeq && detailData) {
      form.setFieldsValue({
        ...detailData,
      });
      setPayMon(Number(detailData.payMon));
      setConMon(Number(detailData.conMon));

      detailTargetList?.forEach((item, index) => {
        for (const [key, value] of Object.entries(item)) {
          form.setFields([
            {
              name: ['list', index, key],
              value,
            },
          ]);
        }

        form.setFields([
          {
            name: ['list', index, 'documents'],
            value: [
              ...(item.checkYn === 'Y' ? ['checkYn'] : []),
              ...(item.reportYn === 'Y' ? ['reportYn'] : []),
              ...(item.reportMonYn === 'Y' ? ['reportMonYn'] : []),
            ],
          },
        ]);
        form.setFields([
          {
            name: ['list', index, 'dates'],
            value: [item?.conSdate, item?.conEdate],
          },
        ]);
      });
    }
  }, [detailData, applSeq, form, detailTargetList]);

  const initialValues = {
    occDate: moment(),
    stdyy: formatDate(moment(), GlobalFormat.YEAR),
    bankType: '99',
  };

  const { data: bankTypeForAccountDataList } = useDiseaseBankCodeList();

  //값이 변할떄마다 화면변경시 필요한 state변수 과도한 rerender를 막기위해 각자 따로선언
  const [bankType, setBankType] = useState<string>('');

  const checkValidation = async (params: IFormParamInterface) => {
    params?.list?.forEach(async (item) => {
      const [conSdate, conEdate] = item.dates ?? [];
      const familyDupYn = await getFamilyDuplicationYn({
        yearLimitAmt: diseaseInfo?.limitAmt,
        conSdate,
        conEdate,
        famNm: item.famNm,
        searchApplSabun: sabun,
      });
      if (familyDupYn.data === 'Y') {
        setMessage({ message: `중복지원 불가합니다. 사내 가족 중 한 명만 신청할 수 있습니다.`, open: true, type: 'error' });
        return false;
      }
    });
    if (yearDupYn === 'Y') {
      setMessage({ message: `연간신청횟수는 ${diseaseInfo?.yearLimitCnt} 번 입니다.`, open: true, type: 'error' });
      return false;
    }
    if (conMon < 1) {
      setMessage({ message: `신규&추가입력을 통해 신청금액을 입력해주세요.`, open: true, type: 'error' });
      return false;
    }
    return true;
  };

  const handleSubmit = async (params: IFormParamInterface) => {
    try {
      const isValid = await checkValidation(params);
      if (!isValid) {
        return;
      }

      params?.list?.map(async (item, index) => {
        await postTargetRegist(item, index);
      });

      await postDisease({
        ...commonInfo,
        ...params,
        sabun,
        conMon,
        payMon,
        applSeq: applSeq || commonInfo?.applSeq,
        applYmd: moment().format(GlobalFormat.DATE_API),
      });
      reloadYearDupYn();
      mutate();
      onClose();
      setMessage({
        open: true,
        message: '신청이 완료되었습니다.',
        type: 'success',
      });
    } catch (e: any) {
      setMessage({
        open: true,
        message: e?.response?.data?.message,
        type: 'error',
      });
    }
  };

  const postTargetRegist = async (params: IFormListItemInterface, index: number) => {
    const [conSdate, conEdate] = params.dates ?? [];
    const docYnObject: ICommonValueObject = {};
    const checkDocumentList = ['reportYn', 'reportMonYn', 'checkYn'];
    checkDocumentList.forEach((docYn) => {
      docYnObject[docYn] = params.documents?.includes(docYn) ? 'Y' : 'N';
    });

    const param = {
      ...params,
      ...docYnObject,
      conSdate,
      conEdate,
      applSeq: commonInfo?.applSeq,
      seq: index,
      sabun,
    };
    await postDiseaseTarget(param);
  };

  return (
    <CommonRequestModal
      open={open}
      onClose={onClose}
      title={'질환위로금 신청'}
      applCd={'105'}
      applSeq={applSeq}
      authPg={authPg}
      onSuccessRequestCommonInfo={setCommonInfo}
      onSubmit={async () => {
        await form.validateFields();
        const isValid = await checkValidation(form.getFieldsValue());
        if (!isValid) {
          return;
        }
        form.submit();
      }}
      onTemporary={async () => {
        await form.validateFields();
        const isValid = await checkValidation(form.getFieldsValue());
        if (!isValid) {
          return;
        }
        form.submit();
      }}
      onCancelDone={() => {
        mutate();
      }}>
      <CommonForm
        onFinishFailed={(errr) => {}}
        readOnly={authPg === '상세'}
        form={form}
        onFinish={(params) => {
          handleSubmit(params);
        }}
        initialValues={initialValues}
        onValuesChange={({ bankType, list }, allData) => {
          bankType && setBankType(bankType);

          if (bankType) {
            const accountDataString = bankType
              ? bankTypeForAccountDataList?.find((item) => item.code?.startsWith(bankType))
              : null;
            const accountDataList = accountDataString?.code?.split('|') || [];
            const [, bankCd = '', accNo = '', accNm = ''] = accountDataList;
            form.setFieldsValue({ bankCd, accNm, accNo });
          }

          if (allData?.list) {
            let totPayMon = 0;
            let totConMon = 0;
            allData?.list?.forEach((item: IFormListItemInterface) => {
              totPayMon += parseInt(item?.payMon ?? '0');
              totConMon += parseInt(item?.conMon ?? '0');
            });
            setPayMon(totPayMon);
            setConMon(totConMon);
          }
        }}>
        <ModalContainer>
          <DiseaseInfo />
          <AddDiseaseRequestTemplate />
          <DiseaseAmountInfo conMon={conMon} payMon={payMon} />
          <SearchBankType />
          <BankGroup bankType={bankType} />
        </ModalContainer>
      </CommonForm>
    </CommonRequestModal>
  );
};

const ModalContainer = styled.div`
  overflow-y: scroll;
  position: relative;
  padding: 15px 0;
  display: grid;
  gap: 16px;
`;

export default DiseaseRequestModal;
