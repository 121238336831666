import { COLOR } from '@constants/Color';
import CancelIcon from '@mui/icons-material/Cancel';
import React, { FC } from 'react';
import styled from 'styled-components';
import { useGlobalConfirm } from './CommonConfirm';
interface ICommonDeleteIconProps {
  deleteHandle: () => void;
}

const CommonDeleteIcon: FC<ICommonDeleteIconProps> = ({ deleteHandle }) => {
  const { open: confirmOpen, close: confirmClose } = useGlobalConfirm();
  const confirmDelete = () => {
    confirmOpen({
      title: '삭제',
      contents: '삭제하시겠습니까?',
      onOkPress: async () => {
        deleteHandle();
        confirmClose();
      },
    });
  };
  return (
    <HandleIcon
      onClick={(e) => {
        e.stopPropagation();
        confirmDelete();
      }}>
      <CancelIcon />
    </HandleIcon>
  );
};
const HandleIcon = styled.span`
  font-size: 16px;
  padding: 5px 5px 5px 5px;
  margin-right: -5px;
  border-radius: 50%;
  color: ${COLOR.RED};
  :active {
    background-color: ${COLOR.ACTIVE_RED};
  }
`;

export default CommonDeleteIcon;
