import { useDiseaseBankCodeList } from '@apis/disease';
import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonSelect from '@components/common/molecules/CommonSelect';
import React, { FC } from 'react';
interface ISearchBankTypeProps {}

const SearchBankType: FC<ISearchBankTypeProps> = () => {
  const { data: bankTypeList } = useDiseaseBankCodeList();

  return (
    <CommonFormField
      name={'bankType'}
      rules={[
        {
          required: true,
        },
      ]}>
      <CommonSelect
        title='계좌구분'
        options={bankTypeList?.map((item) => ({
          label: item.codeNm,
          value: item.code.split('|')[0],
        }))}
      />
    </CommonFormField>
  );
};

export default SearchBankType;
