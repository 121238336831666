import { COLOR } from '@constants/Color';
import { Backdrop, CircularProgress, Portal } from '@mui/material';
import React from 'react';
import { atom, useRecoilState } from 'recoil';

export const showGlobalProgressAtom = atom<boolean>({
  key: 'showGlobalProgressAtom',
  default: false,
});

export const useGlobalProgress = () => {
  const [showGlobalProgress, isShowGlobalProgress] = useRecoilState(showGlobalProgressAtom);
  return {
    visible: showGlobalProgress,
    show: () => isShowGlobalProgress(true),
    hide: () => isShowGlobalProgress(false),
  };
};

const CommonProgress = () => {
  const { visible } = useGlobalProgress();
  return (
    <Portal>
      <Backdrop
        sx={{ color: COLOR.RED, width: '100vw', height: '100vh', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={visible}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </Portal>
  );
};

export default CommonProgress;
