import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonSelect from '@components/common/molecules/CommonSelect';
import { useOccCodeList } from 'src/util/commonCode';

const OccCodeSelect = () => {
  const { data: occCdList } = useOccCodeList();
  return (
    <CommonFormField
      name={'occCd'}
      rules={[
        {
          required: true,
        },
      ]}>
      <CommonSelect
        title='경조구분'
        options={occCdList?.map((item) => ({
          label: item.codeNm,
          value: item.code,
        }))}
      />
    </CommonFormField>
  );
};

export default OccCodeSelect;
