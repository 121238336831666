import { COLOR } from '@constants/Color';
import CloseIcon from '@mui/icons-material/Close';
import { Backdrop, Modal } from '@mui/material';
import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import DOMPurify from 'dompurify';

interface ICausionModalProps {
  open: boolean;
  onClose: () => void;
  contents?: string;
}
const CausionModal: FC<ICausionModalProps> = ({ open, onClose, contents }) => {
  const addBrContents = useMemo(() => (contents ? contents?.replaceAll('\n', '<br/>') : ''), [contents]);
  const cleanHtml = DOMPurify.sanitize(addBrContents);
  return (
    <Modal
      BackdropComponent={Backdrop}
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <CautionContainer>
        <CautionHeader>
          <CautionTitleFont>신청시 유의사항</CautionTitleFont>
          <CloseIcon onClick={onClose} />
        </CautionHeader>
        <CautionDescriptionFont dangerouslySetInnerHTML={{ __html: cleanHtml }} />
      </CautionContainer>
    </Modal>
  );
};

const CautionContainer = styled.div`
  margin: 5vh 10px;
  flex: 1;
  max-height: calc(100vh - 10vh);
  display: flex;
  padding: 0 20px 20px 20px;
  background-color: white;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  align-self: center;
  justify-self: center;
  border-radius: 10px;
  outline: none;
`;

const CautionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  background: inherit;
  display: flex;
  width: 100%;
  top: 0;
  border-bottom: 1px ${COLOR.RED} solid;
  padding: 10px 0 8px;
  margin-bottom: 8px;
`;

const CautionTitleFont = styled.div`
  font-size: 22px;
  color: ${COLOR.RED_DARKEN};
`;
const CautionDescriptionFont = styled.div`
  font-size: 12px;
  color: ${COLOR.BLACK};
  /* white-space: pre-wrap; */
`;

export default CausionModal;
