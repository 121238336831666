import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonInput from '@components/common/atoms/CommonInput';
import CommonSelect from '@components/common/molecules/CommonSelect';
import { GlobalFormat } from '@constants/Format';
import { Moment } from 'moment';
import React, { FC } from 'react';
import { useFamilyNameList } from 'src/util/commonCode';
import { formatDate } from 'src/util/commonUtil';
interface ITargetNameProps {
  famCd?: string;
  occDate?: Moment;
  familyYn?: string;
}
const TargetName: FC<ITargetNameProps> = ({ famCd, occDate, familyYn }) => {
  const searchApplYmd = formatDate(occDate, GlobalFormat.DATE_API);
  const { data: familyNameList } = useFamilyNameList({ famCd, searchApplYmd });
  return (
    <CommonFormField
      rules={[
        {
          required: true,
        },
      ]}
      name={'famNm'}>
      {familyYn === 'Y' ? (
        <CommonSelect
          title='대상자명'
          options={familyNameList?.map((item) => ({
            label: item.codeNm,
            value: item.code,
          }))}
        />
      ) : (
        <CommonInput label='대상자명' variant='outlined' />
      )}
    </CommonFormField>
  );
};

export default TargetName;
