import { InteractionRequiredAuthError } from '@azure/msal-browser';
import axios from 'axios';
import { msalInstance } from 'src/App';
import { loginRequest } from 'src/authConfig';
//import { removeStorageItem, setStorageItem, STORAGE_KEY } from 'src/util/storage';

let isTokenRefreshing = false;
let currentAccessToken = null;

export const RefreshTokenAndReattempRequests = async (error: any) => {
  try {
    const { response: errorResponse } = error;

    // 토큰 갱신이 진행되는 동안 요청되는 Request들을 대기 리스트에 저장
    const queuedRequest = stackRequestQueue(errorResponse);

    if (!isTokenRefreshing) {
      isTokenRefreshing = true;

      const account = msalInstance.getAllAccounts()[0];

      const accessTokenRequest = {
        ...loginRequest,
        account,
      };

      // Use the same msalInstance instance provided to MsalProvider

      const accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest).catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          msalInstance
            .acquireTokenRedirect(accessTokenRequest)
            .catch((error) => {
              // Acquire token interactive failure
              console.error(error);
            })
            .finally(() => {
              // 토큰 갱신 종료
              isTokenRefreshing = false;
            });
        }
      });

      isTokenRefreshing = false;
      let accessToken = accessTokenResponse?.accessToken;
      if (accessToken) {
        //setStorageItem(STORAGE_KEY.ACCESS_TOKEN, accessToken);
        currentAccessToken = accessToken;
        consumeRequestQueues(accessToken);
      }
    }

    return queuedRequest;
  } catch (err) {
    currentAccessToken = null;
    //removeStorageItem(STORAGE_KEY.ACCESS_TOKEN);
    return Promise.reject(err);
  }
};

type TRequestQueue = (accessToken: string) => void;
let requestQueues: TRequestQueue[] = [];

export const stackRequestQueue = (errorResponse: any) =>
  new Promise((resolve) => {
    requestQueues.push((accessToken) => {
      errorResponse.config.headers.Authorization = `Bearer ${accessToken}`;
      resolve(axios(errorResponse.config));
    });
  });

export const consumeRequestQueues = (accessToken: string) => {
  for (const queue of requestQueues) {
    queue(accessToken);
  }

  requestQueues = [];
};
