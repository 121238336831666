import { useVacationState } from '@apis/vacation';
import { COLOR } from '@constants/Color';
import { FC } from 'react';
import styled from 'styled-components';

const VacationStatus: FC = () => {
  const { data: vacationStatus } = useVacationState();
  return (
    <Container>
      <BigTitleFont>연차휴가현황</BigTitleFont>
      <Row>
        <TitleFont>발생일수:</TitleFont>
        <ContentFont>{`${vacationStatus?.creCnt ?? 0}일`}</ContentFont>
        <TitleFont>사용일수:</TitleFont>
        <ContentFont>{`${vacationStatus?.usedCnt ?? 0}일`}</ContentFont>
        <TitleFont>잔여일수:</TitleFont>
        <ContentFont>{`${vacationStatus?.restCnt ?? 0}일`}</ContentFont>
      </Row>
    </Container>
  );
};

const Container = styled.div`
  padding: 4px 8px 0;
  border: 2px solid #b2b2b2;
  border-radius: 4px;
  margin: 12px 0;
`;

const BigTitleFont = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${COLOR.RED_DARKEN};
  padding-bottom: 4px;
`;

const Row = styled.div`
  margin: 4px 0;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const TitleFont = styled.div`
  color: ${COLOR.GRAY};
  font-size: 12px;
  flex: 25;
`;
const ContentFont = styled.div`
  font-size: 12px;
  color: ${COLOR.LIGHT_GRAY};
  flex: 20;
  margin-left: 10px;
  color: #de0f1b;
`;

export default VacationStatus;
