export enum COLOR {
  RED_DARKEN = '#a2040d',
  RED = '#d40511',
  RED_LIGHTEN = '#fa2b37',
  YELLOW = '#fc0',
  YELLOW_LIGHTEN = '#ffd633',
  YELLOW_DARKEN = '#e6b800',
  BG_WHITE = '#fff',
  BG_GRAY = '#ddd',
  BG_LIGHTGRAY = '#ededed',
  BG_LIGHTWT = '#ffffff',
  ACTIVE_YELLOW = '#ffeb99',
  ACTIVE_RED = '#fda8ad',
  LIGHT_GRAY = '#888',
  GRAY = '#555',
  BLACK = '#333',
  ACTIVE_BACKGROUND = 'rgba(255,255,255, 0.5)',
}
