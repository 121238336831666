import { ICommonRequestInfoInterface } from '@apis/common';
import { useDiseaseBankCodeList } from '@apis/disease';
import { IFamilyEventPostParam } from '@apis/familyEvent';
import { getLoanRemainAmountCheck, postLoan, useLoanCount, useLoanDetail, useLoanList, useLoanStandard } from '@apis/loan';
import CommonForm from '@components/common/atoms/CommonForm';
import { currentCommonMessageAtom } from '@components/common/atoms/CommonMessage';
import { useUserInfo } from '@components/common/molecules/CommonUserInfo';
import CommonRequestModal from '@components/common/organisms/CommonRequestModal';
import { GlobalFormat } from '@constants/Format';
import BankGroup from '@pages/disease/templates/BankGroup';
import SearchBankType from '@pages/disease/templates/SearchBankType';
import moment, { Moment } from 'moment';
import Form, { FormInstance } from 'rc-field-form';
import { FC, useEffect, useMemo, useState } from 'react';
import { atom, useSetRecoilState } from 'recoil';
import { useCommonCodeList } from 'src/util/commonCode';
import { formatDate } from 'src/util/commonUtil';
import styled from 'styled-components';
import FamilyDisease from './FamilyDisease';
import HouseBuiyng from './HouseBuiyng';
import LifeStable from './LifeStable';
import LoanStandardInfo from './LoanStandardInfo';
import LoanType from './LoanType';
import Terms from './Terms';

interface ILoanModalProps {
  onClose: () => void;
  open: boolean;
  applSeq?: string;
  authPg?: AUTH_TYPE;
}

interface IFormParamInterface {
  bankType: string;
  bankCd: string;
  accNo: string;
  accNm: string;
  signDate: string;
  terms: string[];
}

export interface ILoanRegistSubProps {
  loanKindCd: string;
}

export interface IFamilyEventFormParam extends Omit<IFamilyEventPostParam, 'famBirYmd' | 'occDate'> {
  famBirYmd: Moment;
  occDate: Moment;
}

export const loanFormAtom = atom<FormInstance | null>({
  key: 'loanFormAtom',
  default: null,
});

export enum LOAN_TYPE {
  주택구입관련 = '07',
  생활안정자금 = '08',
  가족질환대출 = '16',
}

const LoanRequestModal: FC<ILoanModalProps> = ({ authPg, open, onClose, applSeq }) => {
  const setMessage = useSetRecoilState(currentCommonMessageAtom);
  const { mutate } = useLoanList();
  const [commonInfo, setCommonInfo] = useState<ICommonRequestInfoInterface>();
  const { sabun } = useUserInfo();

  const { data: detailData } = useLoanDetail(open ? applSeq : undefined);
  const [form] = Form.useForm();
  const setForm = useSetRecoilState(loanFormAtom);

  useEffect(() => {
    setForm(form);
  }, [form, setForm]);

  useEffect(() => {
    if (applSeq && detailData) {
      form.setFieldsValue({
        ...detailData,
        terms: [
          ...(detailData.retAgreeYn === 'Y' ? ['retAgreeYn'] : []),
          ...(detailData.payAgreeYn === 'Y' ? ['payAgreeYn'] : []),
        ],
      });
      setLoanKindCd(detailData.loanKindCd);
      setLoanAmt(detailData.loanAmt);
    }
  }, [detailData, applSeq, form]);

  const initialValues = {
    bankType: '99',
  };

  const { data: bankTypeForAccountDataList } = useDiseaseBankCodeList();
  const 대출상환방법코드 = 'B60270';
  const { data: repayCodeList } = useCommonCodeList(대출상환방법코드);

  //값이 변할떄마다 화면변경시 필요한 state변수 과도한 rerender를 막기위해 각자 따로선언
  const [bankType, setBankType] = useState<string>('');
  const [loanKindCd, setLoanKindCd] = useState<string>('');
  const [loanAmt, setLoanAmt] = useState<number>(0);
  const { data: loanInfo } = useLoanStandard(loanKindCd);
  const { data: loanCountInfo, mutate: reloadLoanCount } = useLoanCount(loanKindCd);

  const extYear = useMemo(() => {
    const res = repayCodeList?.find((item) => item.code === loanInfo?.repayTypeCd);
    return res?.note1;
  }, [repayCodeList, loanInfo]);

  const checkAmount = async () => {
    const terms = form.getFieldValue('terms');
    if (terms?.length !== 2) {
      setMessage({
        open: true,
        message: '급여공제 및 퇴직연금우선공제 동의에 체크해주세요.',
        type: 'error',
      });
      return false;
    }

    //1. 재신청 기한 체크
    const { reReqMonth = 0, limitAmt = 0 } = loanInfo || {};
    const { loanCnt = 0, loanYmd } = loanCountInfo || {};

    //대출한도금액 체크
    if (Number(form.getFieldValue('loanAmt')) > Number(limitAmt)) {
      setMessage({
        open: true,
        message: `대출한도금액 초과입니다.`,
        type: 'error',
      });
      return false;
    }

    //개월수 체크
    if (loanKindCd === LOAN_TYPE.생활안정자금) {
      if (loanCnt > 0) {
        if (loanCnt && loanYmd) {
          const reReqDay = reReqMonth * 30;

          const diff = moment().diff(moment(loanYmd), 'days');

          if (reReqDay > 0 && diff <= reReqDay) {
            setMessage({
              open: true,
              message: `재신청기한은 완료된 대출 상환일로 부터  ${reReqMonth} 개월 후입니다.`,
              type: 'error',
            });
            return false;
          }
        } else {
          setMessage({
            open: true,
            message: '상환이 완료되지 않은 대출이 있습니다.',
            type: 'error',
          });
          return false;
        }
      }
    } else {
      if (loanCnt > 0 && loanYmd) {
        setMessage({
          open: true,
          message: '상환이 완료되지 않은 대출이 있습니다.',
          type: 'error',
        });
        return false;
      }
    }

    if (loanKindCd === LOAN_TYPE.가족질환대출 || loanKindCd === LOAN_TYPE.주택구입관련) {
      const res = await getLoanRemainAmountCheck({ loanAmt, sabun });
      const { remainChk, residualAmt } = res.data ?? {};
      if (remainChk === 'N') {
        setMessage({
          open: true,
          message: `기신청대출건(주택대출, 가족질환)으로 인해\n대출신청금액은 ${residualAmt} 을(를) 초과 할 수 없습니다.`,
          type: 'error',
        });
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async (params: IFormParamInterface) => {
    try {
      const isValid = await checkAmount();

      if (!isValid) {
        return;
      }

      const termsObject: { [key: string]: YN } = {
        retAgreeYn: 'N',
        payAgreeYn: 'N',
      };
      params.terms?.forEach((item) => {
        termsObject[item] = 'Y';
      });
      await postLoan({
        ...commonInfo,
        ...params,
        ...loanInfo,
        ...termsObject,
        sabun,
        extYear,
        signDate: formatDate(params.signDate, GlobalFormat.DATE_API),
        applSeq: applSeq || commonInfo?.applSeq,
        applYmd: moment().format(GlobalFormat.DATE_API),
      });
      reloadLoanCount();
      mutate();
      onClose();
      setMessage({
        open: true,
        message: '신청이 완료되었습니다.',
        type: 'success',
      });
    } catch (e: any) {
      setMessage({
        open: true,
        message: e?.response?.data?.message,
        type: 'error',
      });
    }
  };

  const renderRegistArea = () => {
    switch (loanKindCd) {
      case LOAN_TYPE.주택구입관련:
        return <HouseBuiyng loanKindCd={loanKindCd} />;
      case LOAN_TYPE.생활안정자금:
        return <LifeStable loanKindCd={loanKindCd} />;
      case LOAN_TYPE.가족질환대출:
        return <FamilyDisease loanKindCd={loanKindCd} />;
    }
  };

  return (
    <CommonRequestModal
      open={open}
      onClose={onClose}
      title={'대출신청'}
      applCd={'101'}
      applSeq={applSeq}
      authPg={authPg}
      onSuccessRequestCommonInfo={setCommonInfo}
      onSubmit={async () => {
        await form.validateFields();
        const isValid = await checkAmount();
        if (!isValid) {
          return;
        }
        form.submit();
      }}
      onTemporary={async () => {
        await form.validateFields();
        const isValid = await checkAmount();
        if (!isValid) {
          return;
        }
        form.submit();
      }}
      onCancelDone={() => {
        mutate();
      }}>
      <CommonForm
        onFinishFailed={(errr) => {}}
        readOnly={authPg === '상세'}
        form={form}
        onFinish={(params) => {
          handleSubmit(params);
        }}
        initialValues={initialValues}
        onValuesChange={({ bankType, loanKindCd }, allData) => {
          bankType && setBankType(bankType);

          if (bankType) {
            const accountDataString = bankType
              ? bankTypeForAccountDataList?.find((item) => item.code?.startsWith(bankType))
              : null;
            const accountDataList = accountDataString?.code?.split('|') || [];
            const [, bankCd = '', accNo = '', accNm = ''] = accountDataList;
            form.setFieldsValue({ bankCd, accNm, accNo });
          }

          if (loanKindCd) {
            setLoanKindCd(loanKindCd);
            form.resetFields(['loanAmt', 'loanUseCd', 'loanUseNm', 'signDate', 'famNm', 'famCd']);
          }
          if (loanAmt) {
            setLoanAmt(loanAmt);
          }
        }}>
        <ModalContainer>
          <LoanType />
          {loanKindCd && <LoanStandardInfo loanKindCd={loanKindCd} />}
          {renderRegistArea()}
          <SearchBankType />
          <BankGroup bankType={bankType} />
          <Terms />
        </ModalContainer>
      </CommonForm>
    </CommonRequestModal>
  );
};

const ModalContainer = styled.div`
  overflow-y: scroll;
  position: relative;
  padding: 15px 0;
  display: grid;
  gap: 16px;
`;

export default LoanRequestModal;
