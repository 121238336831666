export enum ApplicationStatus {
  임시저장 = '11',
  결재처리중 = '21',
  결재반려 = '23',
  수신처리중 = '31',
  수신반려 = '33',
  처리완료 = '99',
  취소완료 = 'ZZ',
}
export const ApplicationStatusObject = {
  '11': '임시저장',
  '21': '결재처리중',
  '23': '결재반려',
  '31': '수신처리중',
  '33': '수신반려',
  '99': '처리완료',
  ZZ: '취소완료',
} as ICommonValueObject;
