import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonInput from '@components/common/atoms/CommonInput';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { CommonDiseaseItemInterface } from './AddDiseaseItem';

interface IClinicTemplatesProps extends CommonDiseaseItemInterface {}

const ClinicTemplates: FC<IClinicTemplatesProps> = ({ field }) => {
  return (
    <>
      <CommonFormField name={[field.name, 'medicalNm']}>
        <CommonInput label={'진료과목'} />
      </CommonFormField>
      <CommonFormField name={[field.name, 'documents']}>
        <CheckboxGroup />
      </CommonFormField>
    </>
  );
};

interface ICheckboxGroupProps {
  value?: string[];
  onChange?: (values: string[]) => void;
}

const CheckboxGroup: FC<ICheckboxGroupProps> = ({ value, onChange }) => {
  const [selectedValues, setSelectedValues] = useState<string[] | undefined>(value);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const parameterKey = event.target.value;
    if (checked) {
      const checkedArray = [...(value?.filter((item) => item !== parameterKey) ?? []), parameterKey];
      setSelectedValues(checkedArray);
      onChange?.([...(value?.filter((item) => item !== parameterKey) ?? []), parameterKey]);
    } else {
      const checkedArray = [...(value?.filter((item) => item !== parameterKey) ?? [])];
      setSelectedValues(checkedArray);
      onChange?.(checkedArray);
    }
  };
  useEffect(() => {
    value !== undefined && setSelectedValues(value);
  }, [value]);

  const selectedValuesEmptyArray = useMemo(() => {
    return selectedValues ? selectedValues : [];
  }, [selectedValues]);
  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch value={'reportYn'} checked={selectedValuesEmptyArray?.includes('reportYn')} onChange={handleChange} />}
        label='진단서'
      />
      <FormControlLabel
        control={
          <Switch value={'reportMonYn'} checked={selectedValuesEmptyArray?.includes('reportMonYn')} onChange={handleChange} />
        }
        label='진단서 발급비용'
      />
      <FormControlLabel
        control={<Switch value={'checkYn'} checked={selectedValuesEmptyArray?.includes('checkYn')} onChange={handleChange} />}
        label='CT/MRI촬영'
      />
    </FormGroup>
  );
};

export default ClinicTemplates;
