import { useDtlCodeList } from '@apis/vacation';
import { IRegisteredVacationRequestParam } from '@apis/vacationCancle';
import CommonButton from '@components/common/atoms/CommonButton';
import CommonInputDateRange from '@components/common/atoms/CommonInputDateRange';
import HorizontalBlank from '@components/common/atoms/HorizontalBlank';
import CommonSelect from '@components/common/molecules/CommonSelect';
import { GlobalFormat } from '@constants/Format';
import { DateRange } from '@mui/lab/DateRangePicker/RangeTypes';
import moment from 'moment';
import { FC, useState } from 'react';
import styled from 'styled-components';
interface IRegisteredVacationSearchFormProps {
  setParam: React.Dispatch<React.SetStateAction<IRegisteredVacationRequestParam | undefined>>;
  gntGubunCd?: string;
  searchApplCd?: string;
}
const RegisteredVacationSearchForm: FC<IRegisteredVacationSearchFormProps> = ({ setParam, gntGubunCd, searchApplCd }) => {
  const { data: dtlCodeList } = useDtlCodeList(gntGubunCd);
  const [gntCd, setGntCd] = useState<string>();
  const [sYmd, setSYmd] = useState<string>(moment().add(-1, 'M').format(GlobalFormat.DATE_API));
  const [eYmd, setEYmd] = useState<string>(moment().format(GlobalFormat.DATE_API));
  const handleChangeDate = (dateValues: string[], dates: DateRange<Date> | null) => {
    const [from, to] = dateValues;
    setSYmd(from);
    setEYmd(to);
  };
  return (
    <>
      <CommonSelect
        title={'선택'}
        options={dtlCodeList?.map((item) => ({
          label: item.codeNm,
          value: item.code,
          data: item,
        }))}
        onChangeItem={(value, selectedItem) => setGntCd(selectedItem?.value)}
      />
      <HorizontalBlank height={10} />
      <CommonInputDateRange
        fromLabel='신청기간시작'
        toLabel='신청기간종료'
        onChangeDate={handleChangeDate}
        value={[sYmd, eYmd]}
      />

      <ButtonContainer>
        <CommonButton
          onClick={() => {
            setParam({ searchApplCd, gntCd, sYmd, eYmd });
          }}>
          조회
        </CommonButton>
      </ButtonContainer>
    </>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 12px;
`;
export default RegisteredVacationSearchForm;
