import { atom, useRecoilState } from 'recoil';

export const headerState = atom({
  key: 'headerState',
  default: '',
});

export const isHomeAtom = atom({
  key: 'isHomeHeader',
  default: false,
});

interface IHeaderStoreInterface {
  title?: string;
  isHome?: boolean;
}

export const useHeaderStore = () => {
  const [headerTitle, setHeaderTitle] = useRecoilState(headerState);
  const [homeHeader, isHomeHeader] = useRecoilState(isHomeAtom);

  const setConfig = (options: IHeaderStoreInterface) => {
    const { title, isHome } = options || {};

    options && title && setHeaderTitle(title);
    options && isHomeHeader(!!isHome);
  };
  return { title: headerTitle, isHome: homeHeader, setConfig };
};
