import { IApprovalRequestParam, useApprovalList } from '@apis/approval';
import Empty from '@components/common/atoms/Empty';
import { FC } from 'react';
import ApprovalItem from './ApprovalItem';

const ApprovalList: FC<IApprovalRequestParam> = (param) => {
  const { data: list } = useApprovalList(param);

  if (list?.length === 0) {
    return <Empty />;
  }

  return (
    <>
      {list?.map((item, index) => (
        <ApprovalItem data={item} index={index} key={item.applSeq} />
      ))}
    </>
  );
};

export default ApprovalList;
