import { GlobalFormat } from '@constants/Format';
import moment, { Moment } from 'moment';
import { ReactText, useState } from 'react';

export const useHandleMultipleIndex: () => [ReactText[], (index: ReactText) => void, () => void] = () => {
  const [activeIndexes, setActiveIndexes] = useState<ReactText[]>([]);
  const handleSliderItem = (index: ReactText) => {
    if (activeIndexes.includes(index)) {
      setActiveIndexes((prev) => prev.filter((item) => item !== index));
    } else {
      setActiveIndexes((prev) => [...prev, index]);
    }
  };

  const removeAll = () => {
    setActiveIndexes([]);
  };

  return [activeIndexes, handleSliderItem, removeAll];
};

export const formatDate = (data?: string | Date | Moment | null, format?: GlobalFormat) => {
  if (!data) {
    return '';
  }
  const momentObj = moment(data);
  return momentObj.format(format);
};

export const getExtensionOfFilename = (filename: string) => {
  const _fileLen = filename.length;
  const _lastDot = filename.lastIndexOf('.');
  const _fileExt = filename.substring(_lastDot + 1, _fileLen).toLowerCase();
  return _fileExt;
};
