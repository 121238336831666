import { useLoanStandard } from '@apis/loan';
import CommonCodeName from '@components/common/atoms/CommonCodeName';
import { Skeleton } from '@mui/material';
import React, { FC } from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import { ILoanRegistSubProps } from './LoanRequestModal';

const LoanStandardInfo: FC<ILoanRegistSubProps> = ({ loanKindCd }) => {
  const { data: loanInfo } = useLoanStandard(loanKindCd);
  return (
    <Container>
      <Item>
        <TitleFont>상환방법 : </TitleFont>
        <ContentFont>
          {loanInfo ? (
            loanInfo?.repayTypeCd && <CommonCodeName code={loanInfo.repayTypeCd} grpCd='B60270' />
          ) : (
            <Skeleton animation='wave' />
          )}
        </ContentFont>
      </Item>
      <Item>
        <TitleFont>이자율 : </TitleFont>
        <ContentFont>{loanInfo ? loanInfo?.intRate : <Skeleton animation='wave' />}</ContentFont>
      </Item>
      <Item>
        <TitleFont>대출한도금액 : </TitleFont>
        <ContentFont>
          {loanInfo ? (
            <NumberFormat value={loanInfo?.limitAmt ?? 0} displayType={'text'} thousandSeparator={true} suffix={' 원'} />
          ) : (
            <Skeleton animation='wave' />
          )}
        </ContentFont>
      </Item>
    </Container>
  );
};

const TitleFont = styled.span`
  flex: 3;
`;

const ContentFont = styled.span`
  flex: 7;
`;

const Container = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
`;
export default LoanStandardInfo;
