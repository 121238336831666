import CommonContainer from '@components/common/atoms/CommonContainer';
import { useHeaderStore } from '@stores/headerStore';
import { useEffect } from 'react';
import VacationList from './templates/VacationList';
import VacationSearchForm from './templates/VacationSearchForm';
import VacationStatus from './templates/VacationStatus';

const VacationPage = () => {
  const { setConfig } = useHeaderStore();
  useEffect(() => {
    setConfig({ title: '근태신청' });
  }, [setConfig]);
  return (
    <CommonContainer style={{ padding: 15 }}>
      <VacationSearchForm />
      <VacationStatus />
      <VacationList />
    </CommonContainer>
  );
};

export default VacationPage;
