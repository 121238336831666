import { IProofListInterface } from '@apis/proofRequest';
import ApplStatusCdTemplate from '@components/common/atoms/ApplStatusCdTemplate';
import { ApplicationStatus } from '@constants/ApplicationStatus';
import { COLOR } from '@constants/Color';
import { GlobalFormat } from '@constants/Format';
import LaunchIcon from '@mui/icons-material/Launch';
import { Divider, Stack } from '@mui/material';
import { FC, ReactText, useEffect, useState } from 'react';
import { selectCodeName, useApplCd } from 'src/util/commonCode';
import { formatDate } from 'src/util/commonUtil';
import styled from 'styled-components';
import ProofRequestModal from './ProofRequestModal';

interface IProofItemProps {
  data: IProofListInterface;
  index: ReactText;
}

const ApplCodeName = ({ applCd }: { applCd: string }) => {
  const { data } = useApplCd();
  const [codeName, setCodeName] = useState<string>();
  useEffect(() => {
    const result = selectCodeName(applCd, data);
    setCodeName(result);
  }, [data, applCd]);
  return <>{codeName}</>;
};

const ProofItem: FC<IProofItemProps> = ({ data, index }) => {
  const [requestModalShow, isRequestModalShow] = useState(false);
  return (
    <>
      <ItemContainer>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <HeadDiv>
            <TitleFont>
              <ApplCodeName applCd={data.applCd} />
            </TitleFont>
            <ApplStatusCdTemplate status={data.applStatusCd} />
          </HeadDiv>
        </Stack>
        <HandleIcon
          onClick={(e) => {
            isRequestModalShow(true);
          }}>
          <span style={{ fontWeight: 600 }}>상세보기</span>
          <div style={{ float: 'right' }}>
            <LaunchIcon />
          </div>
        </HandleIcon>
        <Divider style={{ margin: '0px ​0px 10px 0p' }} />
        <Row>
          <DetailTitleFont>신청일자</DetailTitleFont>
          <ContentFont>{formatDate(data.applYmd, GlobalFormat.DATE_DASH)}</ContentFont>
        </Row>
        <Row>
          <DetailTitleFont>용도</DetailTitleFont>
          <ContentFont>{data.purpose}</ContentFont>
        </Row>
      </ItemContainer>
      {data.applSeq && data.applCd && requestModalShow && (
        <ProofRequestModal
          key={data.applSeq}
          onClose={() => {
            isRequestModalShow(false);
          }}
          open={requestModalShow}
          searchApplCd={data.applCd}
          searchApplSeq={data.applSeq}
          detailYn={'Y'}
          authPg={data.applStatusCd === ApplicationStatus.임시저장 ? '신청' : '상세'}
        />
      )}
    </>
  );
};

const ItemContainer = styled.div`
  padding: 13px;
  background-color: ${COLOR.BG_LIGHTGRAY};
  margin-bottom: 13px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 30%);
`;
const Row = styled.div`
  margin: 4px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const HeadDiv = styled.div`
  display: inline-flex;
  flex-direction: row;
`;

const TitleFont = styled.div`
  font-size: 16px;
  color: ${COLOR.BLACK};
  margin-bottom: 10px;
  width: 120px;
`;

const DetailTitleFont = styled.div`
  font-size: 12px;
  color: #a0a0a0;
  flex: 20;
  text-align: right;
  padding: 1px;
`;

const ContentFont = styled.div`
  font-size: 12px;
  color: ${COLOR.GRAY};
  flex: 80;
  padding-left: 10px;
  padding-top: 1px;
`;

const HandleIcon = styled.div`
  color: ${COLOR.RED};
  float: right;
  margin-top: -32px;
  :active {
    background-color: ${COLOR.ACTIVE_BACKGROUND};
  }
`;

export default ProofItem;
