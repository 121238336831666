import { ICommonRequestInfoInterface } from '@apis/common';
import {
  IVacationPostParam,
  postVacation,
  useDupleVacationCnt,
  useRemainTimeCnt,
  useVacationApplyDate,
  useVacationDetail,
  useVacationList,
} from '@apis/vacation';
import CommonForm from '@components/common/atoms/CommonForm';
import { currentCommonMessageAtom } from '@components/common/atoms/CommonMessage';
import { useUserInfo } from '@components/common/molecules/CommonUserInfo';
import CommonRequestModal from '@components/common/organisms/CommonRequestModal';
import moment from 'moment';
import Form from 'rc-field-form';
import React, { FC, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import DtlCodeSelect from './DtlCodeSelect';
import MiscarriagePeriodSelect from './MiscarriagePeriodSelect';
import MultipleBirthsSelect from './MultipleBirthsSelect';
import RequestDate from './RequestDate';
import RequestRange from './RequestRange';
import RequestReason from './RequestReason';
import RequestTime from './RequestTime';
import SplitUseSelect from './SplitUseSelect';

interface IVacationRequestModalProps {
  onClose: () => void;
  open: boolean;
  searchApplSeq?: string;
  searchApplCd?: string;
  searchGntGubunCd?: string;
  searchGntCd?: string;
  title?: string;
  authPg: AUTH_TYPE;
}
// 0: {searchApplCd: "22",            codeNm: "연차휴가",            gntGubunCd: "1"}
// 1:{searchApplCd: "32",             codeNm: "경조휴가",            gntGubunCd: "9"}
// 2: {searchApplCd: "38",            codeNm: "태아검진",            gntGubunCd: "10"}
// 3: {searchApplCd: "26",            codeNm: "보상휴가",            gntGubunCd: "11"}
// 4: {searchApplCd: "40",            codeNm: "난임휴가",            gntGubunCd: "12"}
// 5: {searchApplCd: "24",            codeNm: "출산휴가",            gntGubunCd: "13"}
// 6:{searchApplCd: "600",            codeNm: "가족돌봄휴가",        gntGubunCd: "60"}
// 7: {searchApplCd: "61",            codeNm: "배우자출산휴가",      gntGubunCd: "14"}
// 8:{searchApplCd: "69",             codeNm: "보건휴가",            gntGubunCd: "2"}
// 9: {searchApplCd: "62",            codeNm: "교육",                gntGubunCd: "16"}
// 10: {searchApplCd: "71",           codeNm: "지각/결근",           gntGubunCd: "6"}
// 11: {searchApplCd: "36",           codeNm: "기타",                gntGubunCd: "17"}
// 12: {searchApplCd: "34",           codeNm: "출장파견",            gntGubunCd: "19"}
// 13: {searchApplCd: "65",           codeNm: "공가(훈련)",          gntGubunCd: "20"}
// 14:{searchApplCd: "67",            codeNm: "공가(기타)",          gntGubunCd: "24"}
// 15:{searchApplCd: "602",           codeNm: "공가(건강검진)",       gntGubunCd: "61"}
// 16:{searchApplCd: "30",            codeNm: "공가(업무상 질병/상해)", gntGubunCd: "22"}
// 17:{searchApplCd: "28",            codeNm: "병가",                gntGubunCd: "23"}




const VacationRequestModal: FC<IVacationRequestModalProps> = ({
  open,
  onClose,
  searchApplSeq,
  searchApplCd,
  searchGntGubunCd,
  searchGntCd,
  title,
  authPg,
}) => {
  const [gntCd, setGntCd] = useState<string>(searchApplCd || '');
  const [sYmd, setSYmd] = useState<string>();
  const [eYmd, setEYmd] = useState<string>();
  const [applYmd, setApplYmd] = useState<string>();
  const [reqSHm, setReqSHm] = useState<string>();
  const [reqEHm, setReqEHm] = useState<string>();
  const [restTime, setRestTime] = useState<number>(0);
  const [form] = Form.useForm();
  const initialValues = {};
  const setMessage = useSetRecoilState(currentCommonMessageAtom);
  const [commonInfo, setCommonInfo] = useState<ICommonRequestInfoInterface>();
  const { data: dupleCnt } = useDupleVacationCnt({ gntCd, sYmd, eYmd });
  const { data: applyDate } = useVacationApplyDate({ gntCd, sYmd, eYmd, applYmd, reqSHm, reqEHm, restTime });
  const { data: remainTimeCnt } = useRemainTimeCnt();
  const { mutate } = useVacationList();
  const { data: detailData } = useVacationDetail(searchApplSeq);
  const gntGubunCd = searchGntGubunCd;
  const generateParam = (data: any) => {
    if (data.requestRange) {
      setSYmd(data.requestRange[0]);
      setEYmd(data.requestRange[1]);
    }
    if (data.applYmd) {
      setSYmd(data.applYmd.format('YYYYMMDD'));
      setApplYmd(data.applYmd.format('YYYYMMDD'));
    }

    const params = {
      //...detailData,
      ...data,
      searchApplSabun: detailData?.sabun,
      applYmd: applYmd || detailData?.applYmd,
      sYmd: sYmd || detailData?.sYmd,
      eYmd: eYmd || detailData?.eYmd,
      closeDay: data.closeDay,
      holDay: data.holDay,
      requestHour: applyDate?.restTime,
      searchApplSeq: commonInfo?.applSeq,
    };

    return params;
  };
  const { sabun: searchApplSabun } = useUserInfo();

  useEffect(() => {
    if (detailData) {
      form.setFieldsValue({
        ...detailData,
        applYmd: detailData.sYmd,
        requestDate: detailData.sYmd,
        requestRange: [detailData.sYmd, detailData.eYmd],
      });
    }
  }, [detailData, searchApplCd, form]);

  useEffect(() => {
    if (dupleCnt && dupleCnt.cnt > 0) {
      setMessage({
        open: true,
        message: '해당 신청기간에 기 신청건이 존재합니다.',
        type: 'warning',
      });
      form.setFieldsValue({ requestRange: [null, null], requestDate: null });
    }
  }, [dupleCnt, form, setMessage, sYmd, eYmd]);

  const checkValidation = async () => {
    if (gntGubunCd === '11') {
      // 보상휴가
      if (!remainTimeCnt) {
        setMessage({
          open: true,
          message: '신청할 수 있는 잔여보상시간이 없습니다.',
          type: 'warning',
        });
        return false;
      } else {
        if (Number(applyDate?.restTime) > remainTimeCnt.remainTime) {
          setMessage({
            open: true,
            message: '잔여보상시간보다 초과해서 신청할 수 없습니다.',
            type: 'warning',
          });
          return false;
        }
      }
    }
    return true;
  };

  const handleSubmit = async (params: IVacationPostParam) => {
    try {
      await postVacation({ ...params, searchApplSabun });
      mutate();
      onClose();
      setMessage({
        open: true,
        message: '신청이 완료되었습니다.',
        type: 'success',
      });
    } catch (e: any) {
      setMessage({
        open: true,
        message: e?.response?.data?.message,
        type: 'error',
      });
    }
  };

  return (
    <CommonRequestModal
      open={open}
      onClose={onClose}
      title={title || ''}
      authPg={authPg}
      applSeq={searchApplSeq}
      applCd={searchApplCd}
      onSubmit={async () => {
        await form.validateFields();
        const isValid = await checkValidation();
        if (!isValid) {
          return;
        }
        if (dupleCnt && dupleCnt.cnt > 0) {
          setMessage({
            open: true,
            message: '해당 신청기간에 기 신청건이 존재합니다.',
            type: 'warning',
          });
          form.setFieldsValue({ requestRange: [null, null], requestDate: null });
        }
        form.submit();
      }}
      onTemporary={async () => {
        await form.validateFields();
        const isValid = await checkValidation();
        if (!isValid) {
          return;
        }
        form.submit();
      }}
      onSuccessRequestCommonInfo={setCommonInfo}
      onCancelDone={() => {
        mutate();
      }}>
      <CommonForm
        readOnly={authPg === '상세'}
        form={form}
        onFinish={async (data) => {
          const isValid = await checkValidation();
          if (!isValid) {
            return;
          }

          const params = generateParam(data);
          handleSubmit(params);
        }}
        onValuesChange={(changedValues: any, values: any) => {
          if (changedValues.gntCd) {
            form.setFieldsValue({ requestRange: [null, null], requestDate: null });
          }

          setGntCd(values.gntCd);
          const [sYmd, eYmd] = changedValues.requestRange ?? [];
          if (sYmd && eYmd) {
            setSYmd(sYmd);
            setEYmd(eYmd);
          }
          if (changedValues.requestDate) {
            setSYmd(changedValues.requestDate.format('YYYYMMDD') ?? '');
            setEYmd(changedValues.requestDate.format('YYYYMMDD') ?? '');
          }

          if (values.reqSHm && changedValues.reqEHm) {
            const reqS = values.reqSHm.replace(':', '');
            const reqE = changedValues.reqEHm.replace(':', '');
            setReqSHm(reqS);
            setReqEHm(reqE);
            if (reqE.length > 3 && reqS > reqE) {
              setMessage({
                open: true,
                message: '시간이 유효하지 않습니다. 시간을 확인해주세요.',
                type: 'warning',
              });
            }
          }
          if (changedValues.applYmd) {
            setApplYmd(changedValues.applYmd.format('YYYYMMDD') ?? '');
            setSYmd(changedValues.applYmd.format('YYYYMMDD') ?? '');
            setEYmd(changedValues.applYmd.format('YYYYMMDD') ?? '');
          }

          if (values.reqSHm && values.reqEHm && changedValues.restTime) {
            const reqS = values.reqSHm.replace(':', '');
            const reqE = values.reqEHm.replace(':', '');
            setReqSHm(reqS);
            setReqEHm(reqE);
            setRestTime(changedValues.restTime);
          }

          if (values.gntCd === '39') {
            // 난임휴가
          }

          if (gntGubunCd === '14') {
            // 배우자출산휴가
            if (values.requestDate && changedValues.requestRange) {
              const requestDate = moment(values.requestDate);
              const startDate = moment(changedValues.requestRange[0]);
              const diff = startDate.diff(requestDate, 'days');
              if (diff > 90) {
                setMessage({
                  open: true,
                  message: '출산(예정)일로 부터 90일 이내만 신청 가능합니다.',
                  type: 'warning',
                });
                form.setFieldsValue({ requestRange: [null, null] });
              }
            }
          }

          if (gntGubunCd === '61') {
            // 공가(건강검진)
            if (changedValues.requestRange) {
              const startDate = moment(changedValues.requestRange[0]);
              const endDate = moment(changedValues.requestRange[1]);
              const diff = endDate.diff(startDate, 'days');
              if (diff > 0) {
                setMessage({
                  open: true,
                  message: '건강검진은 당일만 신청 가능합니다',
                  type: 'warning',
                });
                form.setFieldsValue({ requestRange: [null, null] });
              }
            }
          }

        }}
        initialValues={initialValues}>
        <ModalContainer>
          {searchApplSeq && searchGntCd ? (
            <DtlCodeSelect gntGubunCd={gntGubunCd} searchGntCd={searchGntCd} form={form} />
          ) : (
            <DtlCodeSelect gntGubunCd={gntGubunCd} form={form} />
          )}
          {gntGubunCd === '13' && gntCd === '37' && <MiscarriagePeriodSelect />}
          {['12', '13', '14'].includes(gntGubunCd || '') && <RequestDate gntCd={gntCd} restTime={applyDate?.restTime} />}
          {(gntGubunCd === '10' || gntGubunCd === '11' || (gntGubunCd === '17' && gntCd === '93')) && (
            <RequestTime gntCd={gntCd} searchApplCd={searchApplCd} restTime={applyDate?.restTime} />
          )}
          {(['1', '2', '9', '12', '13', '60', '14', '16', '19', '22', '23', '20', '24','61'].includes(gntGubunCd || '') ||
            (gntGubunCd === '17' && ['50', '92', '95', '96'].includes(gntCd || ''))) && (
            <RequestRange gntCd={gntCd} form={form} detailData={detailData} />
          )})
          {/* 연차, 보건, 경조, 난임, 출산, 가족돌봄, 배우자 출산, 교육, 출장, 공가(업무상 질병/상해, 병가, 공가(훈련), 공가(기타), 공가(건강검진) 시 일자 컴포넌트 표기 */}
          {((gntGubunCd === '13' && gntCd === '35') || gntGubunCd === '14') && (
            <>
              <MultipleBirthsSelect />
              <SplitUseSelect />
            </>
          )}

          <RequestReason />
        </ModalContainer>
      </CommonForm>
    </CommonRequestModal>
  );
};

const ModalContainer = styled.div`
  padding: 15px 0;
  display: grid;
  gap: 16px;
  overflow-y: scroll;
  position: relative;
`;

export default VacationRequestModal;
