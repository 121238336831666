import { useStudentFamilyName } from '@apis/studentFunds';
import CommonButton from '@components/common/atoms/CommonButton';
import { currentCommonMessageAtom } from '@components/common/atoms/CommonMessage';
import HorizontalBlank from '@components/common/atoms/HorizontalBlank';
import VerticalBlank from '@components/common/atoms/VerticalBlank';
import CommonSelect from '@components/common/molecules/CommonSelect';
import { GlobalFormat } from '@constants/Format';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { atom, useRecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import StudentFundsRequestModal from './StudentFundsRequestModal';

export const currentStudentSearchYear = atom<string>({
  key: 'studentSearchYearAtom',
  default: moment().format(GlobalFormat.YEAR),
});

const StudentEventSearchForm: FC = () => {
  const [year, setYear] = useRecoilState(currentStudentSearchYear);
  const [value, setValue] = useState(year);
  const setMessage = useSetRecoilState(currentCommonMessageAtom);
  const { data: targetNameCodeList } = useStudentFamilyName('00070');
  const [requestModalShow, isRequestModalShow] = useState(false);
  const options = Array(4)
    .fill(null)
    .map((_, i) => {
      const curYear = moment().format(GlobalFormat.YEAR);
      const result = Number(curYear) - i;
      return {
        label: result.toString(),
        value: result.toString(),
      };
    });
  useEffect(() => {
    setValue(year);
  }, [year]);

  const insertClick = () => {
    if (targetNameCodeList?.length === 0) {
      setMessage({
        open: true,
        message: `현재 가족으로 등록된 자녀가 없습니다.\n채용팀에 문의하여 가족 등록 먼저 해주세요.\n가족으로 등록된 자녀만 학자금 신청이 가능합니다.`,
        type: 'error',
      });
    } else {
      isRequestModalShow(true);
    }
  };
  return (
    <>
      <HorizontalBlank height={6} />
      <CommonSelect title={'기준년도'} options={options} value={value} onChangeItem={(value) => setValue(value)} />
      <ButtonContainer>
        <CommonButton
          onClick={() => {
            if (year.length < 4) {
              setMessage({
                open: true,
                type: 'error',
                message: '4자리 이상 입력해주세요.',
              });
              return;
            }
            setYear(value);
          }}>
          조회
        </CommonButton>
        <VerticalBlank width={15} />
        <CommonButton onClick={insertClick}>신청</CommonButton>
        {requestModalShow && (
          <StudentFundsRequestModal open={requestModalShow} onClose={() => isRequestModalShow(false)} authPg={'신청'} />
        )}
      </ButtonContainer>
    </>
  );
};
const ButtonContainer = styled.div`
  display: flex;
  margin-top: 15px;
`;
export default StudentEventSearchForm;
