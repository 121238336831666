import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonInput from '@components/common/atoms/CommonInput';
import CommonSelect from '@components/common/molecules/CommonSelect';
import React, { FC } from 'react';
import { useBankCodeList } from 'src/util/commonCode';
interface IBankGroupProps {
  bankType?: string;
}
const BankGroup: FC<IBankGroupProps> = ({ bankType }) => {
  const { data: bankCodeList } = useBankCodeList();
  const readOnlyCheck = ['04', '05', '06', '020'].includes(bankType || '');

  return (
    <>
      <CommonFormField
        name='payBankCd'
        rules={[
          {
            required: true,
          },
        ]}>
        <CommonSelect
          readOnly={readOnlyCheck}
          title='은행명'
          options={bankCodeList?.map((item) => ({
            label: item.codeNm,
            value: item.code,
          }))}
        />
      </CommonFormField>
      <CommonFormField
        name='payAccNo'
        rules={[
          {
            required: true,
          },
        ]}>
        <CommonInput label='계좌번호' readOnly={readOnlyCheck} type={'number'} />
      </CommonFormField>
      <CommonFormField
        name='payAccNm'
        rules={[
          {
            required: true,
          },
        ]}>
        <CommonInput label='예금주' readOnly={readOnlyCheck} />
      </CommonFormField>
    </>
  );
};

export default BankGroup;
