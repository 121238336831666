import CommonRangeDateFormField from '@components/common/molecules/CommonRangeDateFormField';
import { COLOR } from '@constants/Color';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import { ListField } from 'rc-field-form/es/List';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import ClinicTemplates from './ClinicTemplates';
import DiseaseAmount from './DiseaseAmount';
import FamilyCodeSelect from './FamilyCodeSelect';
import RequestMemo from './RequestMemo';
import TargetName from './TargetName';

interface AddDiseaseItemProps {
  remove: (index: number | number[]) => void;
  field: ListField;
}

export interface CommonDiseaseItemInterface {
  field: ListField;
}

const AddDiseaseItem: FC<AddDiseaseItemProps> = ({ remove, field }) => {
  const [famCd, setFamCd] = useState<string>('');
  return (
    <ItemContainer>
      <RemoveIcon color={'primary'} onClick={() => remove(field.name)} />
      <FamilyCodeSelect field={field} onChange={(value) => setFamCd(value)} />
      <TargetName field={field} famCd={famCd} />
      <DiseaseAmount field={field} />
      <CommonRangeDateFormField required fromLabel='치료기간 시작일' toLabel='치료기간 종료일' name={[field.name, 'dates']} />
      <ClinicTemplates field={field} />
      <RequestMemo field={field} />
    </ItemContainer>
  );
};
const ItemContainer = styled.div`
  width: 100%;
  position: relative;
  padding: 15px;
  display: grid;
  gap: 16px;
  border-radius: 10px;
  margin-bottom: 12px;
  border: 1px solid ${COLOR.BG_GRAY};
`;

const RemoveIcon = styled(RemoveCircleRoundedIcon)`
  position: absolute;
  right: 0;
  top: -5px;
`;

export default AddDiseaseItem;
