import CommonButton from '@components/common/atoms/CommonButton';
import { currentCommonMessageAtom } from '@components/common/atoms/CommonMessage';
import HorizontalBlank from '@components/common/atoms/HorizontalBlank';
import VerticalBlank from '@components/common/atoms/VerticalBlank';
import CommonSelect from '@components/common/molecules/CommonSelect';
import { GlobalFormat } from '@constants/Format';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { atom, useRecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import FamilyEventRequestModal from './FamilyEventRequestModal';

export const currentFamilyEventSearchYear = atom<string>({
  key: 'familyEventSearchYearAtom',
  default: moment().format(GlobalFormat.YEAR),
});

const FamilyEventSearchForm: FC = () => {
  const [year, setYear] = useRecoilState(currentFamilyEventSearchYear);
  const [value, setValue] = useState(year);
  const setMessage = useSetRecoilState(currentCommonMessageAtom);
  const [requestModalShow, isRequestModalShow] = useState(false);
  const options = Array(4)
    .fill(null)
    .map((_, i) => {
      const curYear = moment().format(GlobalFormat.YEAR);
      const result = Number(curYear) - i;
      return {
        label: result.toString(),
        value: result.toString(),
      };
    });
  useEffect(() => {
    setValue(year);
  }, [year]);
  return (
    <>
      <HorizontalBlank height={6} />
      <CommonSelect title={'기준년도'} options={options} value={value} onChangeItem={(value) => setValue(value)} />
      <ButtonContainer>
        <CommonButton
          onClick={() => {
            if (value.length < 4) {
              setMessage({
                open: true,
                type: 'error',
                message: '4자리 이상 입력해주세요.',
              });
              return;
            }
            setYear(value);
          }}>
          조회
        </CommonButton>
        <VerticalBlank width={15} />
        <CommonButton onClick={() => isRequestModalShow(true)}>신청</CommonButton>
        {requestModalShow && (
          <FamilyEventRequestModal open={requestModalShow} onClose={() => isRequestModalShow(false)} authPg={'신청'} />
        )}
      </ButtonContainer>
    </>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 15px;
`;
export default FamilyEventSearchForm;
