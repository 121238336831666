import { IUploadedFileListItemInterface, postFileUpload, useFileList } from '@apis/fileUpload';
import { LinearProgress } from '@mui/material';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import styled, { css } from 'styled-components';
import { currentCommonMessageAtom } from '../atoms/CommonMessage';
import FileUploadInfo from '../atoms/FileUploadInfo';
import { useUserInfo } from '../molecules/CommonUserInfo';
import FileThumbnail from '../molecules/FileThumbnail';

interface IFileUploadProps {
  iserror?: boolean;
  fileSeq?: string;
  applStatusCd?: string;
  onUploaded?: (files: IUploadedFileListItemInterface[], fileSeq?: string) => void;
}

interface IFileListInterface {
  lastModified: number;
  size: number;
  lastModifiedDate: Date;
  name: string;
  type: string;
  webkitRelativePath: string;
  // blob: any;
}

const FileUpload: FC<IFileUploadProps> = ({ iserror, fileSeq, applStatusCd, onUploaded }) => {
  const [fileSequance, setFileSequance] = useState(fileSeq);
  const [uploadingProgress, setUploadingProgress] = useState(0);
  const { sabun } = useUserInfo();
  const { data: uploadedFileList, mutate } = useFileList(fileSequance);

  const fileRef = useRef<HTMLInputElement>(null);
  const setMessage = useSetRecoilState(currentCommonMessageAtom);

  const getFileExtention = (fileName: string) => {
    return fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
  };

  const isExtensionValid = (extention: string) => {
    return ['gif', 'png', 'jpg', 'jpeg', 'pdf'].includes(extention);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files: any = event?.target?.files;
    const fileList: IFileListInterface[] = Object.values(files);
    const allFileList = [...(uploadedFileList ?? []), ...fileList];

    fileList.find((item) => item.name);
    if (allFileList.length > 5) {
      setMessage({
        open: true,
        message: '첨부파일은 최대 5개까지 등록 가능합니다.',
        type: 'warning',
      });
    } else if (
      !fileList.find((item) => {
        const extentions = getFileExtention(item.name);
        return isExtensionValid(extentions);
      })
    ) {
      setMessage({
        open: true,
        message: '이미지 파일과 PDF 파일만 등록 가능합니다.',
        type: 'warning',
      });
    } else if (
      fileList.some((item) => item.size > 20 * 1024 * 1024) // 파일 크기가 20MB를 초과하는지 확인
    ) {
      setMessage({
        open: true,
        message: '파일 크기는 최대 20MB까지 가능합니다.',
        type: 'warning',
      });
    } else {
      uploadFile(fileList);
    }
  };

  const uploadFile = async (fileList: any[]) => {
    try {
      const formData = new FormData();
      fileList.forEach((item) => {
        formData.append('file', item);
      });
      formData.append('sabun', sabun ?? '');
      formData.append('fileSeq', fileSequance ?? '');
      const { data: newFileSeq } = await postFileUpload(formData, setUploadingProgress);
      fileSequance === newFileSeq ? mutate() : setFileSequance(newFileSeq);
    } catch (error: any) {
      console.log('fileUploadError,', error, error?.response);
    }
  };

  useEffect(() => {
    setFileSequance(fileSeq);
  }, [fileSeq]);

  useEffect(() => {
    onUploaded && onUploaded(uploadedFileList ?? [], fileSequance);
  }, [uploadedFileList, onUploaded, fileSequance]);

  const isLoading = useMemo(() => uploadingProgress > 0 && uploadingProgress < 100, [uploadingProgress]);
  return (
    <>
      <input type='file' style={{ display: 'none' }} onChange={handleChange} ref={fileRef} multiple />
      <FileUploadContainer isLoading={isLoading} errors={!!iserror}>
        {isLoading && (
          <ProgressMask>
            <LinearProgress variant='determinate' value={uploadingProgress} />
          </ProgressMask>
        )}
        {uploadedFileList && uploadedFileList.length ? (
          [
            ...uploadedFileList.map((item) => (
              <FileThumbnail
                applStatusCd={applStatusCd}
                key={`${item.fileSeq}_${item.seqNo}`}
                imageInfo={item}
                reFetch={mutate}
              />
            )),
            uploadedFileList.length < 5 ? (
              <ThumbnailContainer key={'uploadInfo'}>
                <FileUploadInfo applStatusCd={applStatusCd} onClick={() => fileRef.current?.click()} />
              </ThumbnailContainer>
            ) : null,
          ]
        ) : (
          <EmptyThumbnailContainer>
            <FileUploadInfo applStatusCd={applStatusCd} onClick={() => fileRef.current?.click()} />
          </EmptyThumbnailContainer>
        )}
      </FileUploadContainer>
    </>
  );
};

const FileUploadContainer = styled.div<ICommonInput & { isLoading: boolean }>`
  width: 100%;
  height: auto;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.23);
  display: flex;
  align-items: center;
  padding: 6px;
  position: relative;
  overflow-x: auto;
  ${({ isLoading }) =>
    isLoading &&
    css`
      overflow-x: hidden;
    `}
`;

const ProgressMask = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

const EmptyThumbnailContainer = styled.div`
  font-size: 16px;
  width: 100%;
`;

const ThumbnailContainer = styled.div`
  font-size: 10px;
  margin: 5px;
  position: relative;
`;

export default FileUpload;
