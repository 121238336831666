import React, { FC } from 'react';
import LoanAmt from './LoanAmt';
import { ILoanRegistSubProps } from './LoanRequestModal';
import LoanUseTemplates from './LoanUseTemplates';

const LifeStable: FC<ILoanRegistSubProps> = ({ loanKindCd }) => {
  return (
    <>
      <LoanAmt />
      <LoanUseTemplates loanKindCd={loanKindCd} />
    </>
  );
};

export default LifeStable;
