import { useUserInfo } from '@components/common/molecules/CommonUserInfo';
import { currentStudentSearchYear } from '@pages/student-funds/templates/StudentSearchForm';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { axiosInstance, useRequest } from 'src/axios/fetcher';

export interface IProofRequestParam {
  applCd?: string;
  applSeq?: string;
  applStatusCd?: string;
  sabun?: string;
  searchAddrSelect?: string;
}

export interface IProofListInterface {
  applSeq: string;
  sabun: string;
  applYmd: string;
  applCd: string;
  applStatusCd: string;
  applInSabun: string;
  applSabun: string;
  reqSabun: string;
  regNo: string;
  purpose: string;
  prtCnt: string;
  submitOffice: string;
  prtYn: string;
  prtRsc: string;
  address: string;
  etc: string;
  reqYy: string;
  addr: string;
  engAddr: string;
  empYmd: string;
  currYmd: string;
  year: string;
  month: string;
  manageCd: string;
  resNoYn: string;
  sYmd: string;
  eYmd: string;
}

export interface IProofPostParam {
  applSeq: string;
  sabun?: string;
  applYmd: string;
  applCd: string;
  reqSabun?: string;
  reqYy: string;
  sYmd: string;
  eYmd: string;
  pmTime: string;
  nightPmTime: string;
  address: string;
  purpose: string;
  etc: string;
  submitOffice: string;
  resNoYn: string;
  prtCnt: string;
}

export const useProofList = (params?: IProofRequestParam) => {
  const { sabun } = useUserInfo();
  const year = useRecoilValue(currentStudentSearchYear);
  const resultSwr = useRequest<IProofListInterface[]>(
    sabun && year
      ? {
          url: `/dhl/kr/myhr/certificate/getCertificateList`,
          params: { sabun, ...params },
        }
      : null,
  );

  const { mutate } = resultSwr;
  useEffect(() => {
    mutate();
  }, [year, mutate]);

  return resultSwr;
};

export const useProofDetail = ({ applCd, applSeq }: IProofRequestParam) => {
  const { sabun } = useUserInfo();
  return useRequest<[IProofListInterface]>({
    url: `/dhl/kr/myhr/certificate/getCertiAppDetList`,
    params: { sabun, applCd, applSeq },
  });
};

export const postProof = async (params: IProofPostParam) => {
  return await axiosInstance.post('/dhl/kr/myhr/certificate/saveCertificate', params);
};

export const useAddress = ({ searchAddrSelect }: IProofRequestParam) => {
  const { sabun: searchApplSabun } = useUserInfo();
  return useRequest<[IProofListInterface]>({
    url: `/dhl/kr/myhr/certificate/getAddress`,
    params: { searchApplSabun: searchApplSabun, searchAddrSelect: searchAddrSelect },
  });
};
