import { IRegisteredVacationRequestParam, useRegisteredVacationCancleList } from '@apis/vacationCancle';
import { FC } from 'react';
import RegisteredVacationCancelItem from './RegisteredVacationCancelItem';
import RegisteredVacationEmpty from './RegisteredVacationEmpty';

const RegisteredVacationCancelList: FC<{ param?: IRegisteredVacationRequestParam }> = ({ param }) => {
  const { data: list } = useRegisteredVacationCancleList(param);

  if (list?.length === 0) {
    return <RegisteredVacationEmpty />;
  }

  return (
    <>
      {list?.map((item, index) => (
        <RegisteredVacationCancelItem {...item} key={`vacation_key_${item.applSeq}`} />
      ))}
    </>
  );
};

export default RegisteredVacationCancelList;
