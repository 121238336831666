import { useLoanTypeDetail } from '@apis/loan';
import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonSelect from '@components/common/molecules/CommonSelect';
import { GlobalFormat } from '@constants/Format';
import moment from 'moment';
import React from 'react';
import { formatDate } from 'src/util/commonUtil';

const LoanType = () => {
  const { data: loanTypeList } = useLoanTypeDetail(formatDate(moment(), GlobalFormat.DATE_API));
  return (
    <CommonFormField
      name={'loanKindCd'}
      rules={[
        {
          required: true,
        },
      ]}>
      <CommonSelect
        title='대출구분'
        options={loanTypeList?.map((item) => ({
          label: item.codeNm,
          value: item.code,
        }))}
      />
    </CommonFormField>
  );
};

export default LoanType;
