import { COLOR } from '@constants/Color';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import CommonHeader from '../molecules/CommonHeader';

interface CommonLayoutInterface {
  children: ReactNode | ReactNode[];
}
const CommonLayout: FC<CommonLayoutInterface> = ({ children }) => {
  return (
    <Container>
      <CommonHeader />
      {children}
    </Container>
  );
};

const Container = styled.div`
  background-color: ${COLOR.BG_WHITE};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
  width: 100vw;
`;

export default CommonLayout;
