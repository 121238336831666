import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonInput from '@components/common/atoms/CommonInput';
import CommonInputHidden from '@components/common/atoms/CommonInputHidden';
import CommonSelect from '@components/common/molecules/CommonSelect';
import React, { FC, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useCommonCodeList } from 'src/util/commonCode';
import { ILoanRegistSubProps, loanFormAtom, LOAN_TYPE } from './LoanRequestModal';

const LoanUseTemplates: FC<ILoanRegistSubProps> = ({ loanKindCd }) => {
  const 자금용도코드 = 'B60250';
  const { data: codeList } = useCommonCodeList(자금용도코드);
  const [loanUseNm, setLoanKindCd] = useState<string>();

  const form = useRecoilValue(loanFormAtom);

  useEffect(() => {
    form?.setFieldsValue({
      loanUseNm,
    });
  }, [loanUseNm, form]);
  return loanKindCd === LOAN_TYPE.주택구입관련 ? (
    <>
      <CommonFormField
        name={'loanUseCd'}
        rules={[
          {
            required: true,
          },
        ]}>
        <CommonSelect
          title='자금용도'
          options={codeList?.map((item) => ({
            label: item.codeNm,
            value: item.code,
          }))}
          onChangeItem={(_, selectedItem) => setLoanKindCd(selectedItem?.label?.toString())}
        />
      </CommonFormField>
      <CommonFormField name='loanUseNm'>
        <CommonInputHidden />
      </CommonFormField>
    </>
  ) : loanKindCd === LOAN_TYPE.생활안정자금 ? (
    <CommonFormField
      name={'loanUseNm'}
      rules={[
        {
          required: true,
        },
      ]}>
      <CommonInput label='자금용도' />
    </CommonFormField>
  ) : null;
};

export default LoanUseTemplates;
