import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonSelect from '@components/common/molecules/CommonSelect';
import { FC } from 'react';
import { useFamilyCodeList } from 'src/util/commonCode';

const FamilyCodeSelect: FC = () => {
  const { data: familyCodeList } = useFamilyCodeList({ cmd: 'getSchAppDetFamCdList' });
  return (
    <CommonFormField
      name={'famCd'}
      rules={[
        {
          required: true,
        },
      ]}>
      <CommonSelect
        title='가족관계'
        options={familyCodeList?.map((item) => ({
          label: item.codeNm,
          value: item.code.split('|')[0],
        }))}
      />
    </CommonFormField>
  );
};

export default FamilyCodeSelect;
