import { AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useGlobalConfirm } from '@components/common/atoms/CommonConfirm';
import { useGlobalProgress } from '@components/common/atoms/CommonProgress';
import { useCallback, useEffect, useState } from 'react';
import { loginRequest } from 'src/authConfig';
//import { setStorageItem, STORAGE_KEY } from './storage';

export const useAuthorization = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [tokenData, setTokenData] = useState<AuthenticationResult | null>(null);
  const { show: loadingShow, hide: loadingHide } = useGlobalProgress();
  const { open: confirmOpen, close: confirmClose } = useGlobalConfirm();

  const requestProfileData = useCallback(() => {
    const accessTokenRequest = {
      ...loginRequest,
      account: accounts[0],
    };
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        setTokenData(accessTokenResponse);
        //setStorageItem(STORAGE_KEY.ACCESS_TOKEN, accessTokenResponse.accessToken);
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof InteractionRequiredAuthError) {
          instance
            .acquireTokenRedirect(accessTokenRequest)
            .then((accessTokenResponse: any) => {
              setTokenData(accessTokenResponse);
              //setStorageItem(STORAGE_KEY.ACCESS_TOKEN, accessTokenResponse?.accessToken);
            })
            .catch((error) => {
              // Acquire token interactive failure
              console.error(error);
            });
        }
      });
  }, [instance, accounts]);

  const confirmLogin = useCallback(() => {
    confirmClose();
    loadingShow();
    instance.loginRedirect(loginRequest).finally(() => {
      loadingHide();
    });
  }, [instance, loadingShow, loadingHide, confirmClose]);

  const handleLogin = useCallback(() => {
    confirmOpen({
      title: '로그인',
      contents: `해당 서비스는 로그인이 필요한 기능입니다. 
로그인 화면으로 이동하시겠습니까?`,
      onOkPress: confirmLogin,
    });
  }, [confirmOpen, confirmLogin]);

  const handleLogout = useCallback(() => {
    const logoutRequest = {
      account: instance.getAccountByHomeId(tokenData?.account?.homeAccountId ?? ''),
    };

    instance.logoutRedirect(logoutRequest);
  }, [instance, tokenData]);

  useEffect(() => {
    isAuthenticated && !tokenData && requestProfileData();
  }, [isAuthenticated, tokenData, requestProfileData]);

  //console.log('tokenData', accounts, isAuthenticated);
  return {
    tokenData,
    isLoggedin: isAuthenticated,
    handleLogin,
    handleLogout,
  };
};
