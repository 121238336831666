import { COLOR } from '@constants/Color';
import { GlobalFormat } from '@constants/Format';
import { DateRange, MobileDateRangePicker } from '@mui/lab';
import { TextField } from '@mui/material';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { formatDate } from 'src/util/commonUtil';
import styled from 'styled-components';
import { IFieldChildrenProps } from './CommonForm';
import CommonVibe from './CommonVibe';
interface ICommonInputDateRangeProps extends IFieldChildrenProps {
  fromLabel: ReactNode;
  toLabel: ReactNode;
  // fromName: string;
  // toName: string;
  type?: String;
  readonly?: boolean;
  onChange?: (dateValues: string[], date: DateRange<Date> | null) => void;
  onChangeDate?: (dateValues: string[], date: DateRange<Date> | null) => void;
}

const CommonInputDateRange: FC<ICommonInputDateRangeProps> = ({
  iserror,
  meta,
  value,
  fromLabel,
  toLabel,
  type,
  readonly,
  onChange,
  onChangeDate,
}) => {
  const [dates, setDates] = useState<DateRange<Date>>(value ?? [null, null]);
  const inputFormat = useMemo(() => (type === 'month' ? GlobalFormat.DATE_DASH_MONTH : GlobalFormat.DATE_DASH), [type]);
  const handleChange = (range: DateRange<Date | null>, textInput?: string) => {
    setDates(range);
    const strings = range.map((item) => formatDate(item, GlobalFormat.DATE_API));
    onChange && onChange(strings, range);
    onChangeDate && onChangeDate(strings, range);
  };
  const validBorder = { boxShadow: `0px 0px 0px 2px ${COLOR.RED}`, width: 'calc(100% - 4px)', margin: '0 2px' };

  const [isFromValid, isToFalid] = useMemo(() => {
    if (meta?.errors.length === 0) {
      return [true, true];
    }
    const errors: any = meta?.errors?.[0];
    return [errors?.from, errors?.to];
  }, [meta?.errors]);
  const readonlyStyle = useMemo(
    () => (readonly ? { background: '#ccc', boxShadow: 'inset 0 0px 20px 0px white' } : {}),
    [readonly],
  );
  useEffect(() => {
    setDates(value ?? [null, null]);
  }, [value]);
  return (
    <CommonVibe iserror={!!iserror}>
      <MobileDateRangePicker
        disableMaskedInput={true}
        inputFormat={inputFormat}
        startText={fromLabel}
        endText={toLabel}
        calendars={1}
        value={dates}
        onChange={(range, textInput) => handleChange(range, textInput)}
        renderInput={(startProps, endProps) => {
          return (
            <>
              <TextField
                {...startProps}
                style={{ flex: 1 }}
                InputProps={{ style: isFromValid ? {} : validBorder, ...startProps.InputProps, ...readonlyStyle }}
                InputLabelProps={{ style: { background: 'white', padding: '0 4px', fontSize: 16 } }}
              />
              <Seperator>~</Seperator>
              <TextField
                {...endProps}
                style={{ flex: 1, ...readonlyStyle }}
                InputProps={{ style: isToFalid ? {} : validBorder, ...endProps.InputProps, ...readonlyStyle }}
                InputLabelProps={{ style: { background: 'white', padding: '0 4px', fontSize: 16 } }}
              />
            </>
          );
        }}
      />
    </CommonVibe>
  );
};

const Seperator = styled.div`
  padding: 10px;
`;

export default CommonInputDateRange;
