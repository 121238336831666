import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonSelect, { ISelectOptionItemInterface } from '@components/common/molecules/CommonSelect';
import React, { FC } from 'react';
interface IMultipleBirthsSelectProps {
  onChangeItem?: (value: string, item: ISelectOptionItemInterface | null) => void;
}
const MultipleBirthsSelect: FC<IMultipleBirthsSelectProps> = ({ onChangeItem }) => {
  return (
    <CommonFormField name={'multiBirthYn'}>
      <CommonSelect
        title='다태아여부'
        options={[
          { label: 'Y', value: 'Y' },
          { label: 'N', value: 'N' },
        ]}
      />
    </CommonFormField>
  );
};

export default MultipleBirthsSelect;
