import { ICommonRequestInfoInterface } from '@apis/common';
import { IProofPostParam, postProof, useProofDetail, useProofList } from '@apis/proofRequest';
import CommonForm from '@components/common/atoms/CommonForm';
import { currentCommonMessageAtom } from '@components/common/atoms/CommonMessage';
import { useUserInfo } from '@components/common/molecules/CommonUserInfo';
import CommonRequestModal from '@components/common/organisms/CommonRequestModal';
import { GlobalFormat } from '@constants/Format';
import moment from 'moment';
import Form from 'rc-field-form';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { formatDate } from 'src/util/commonUtil';
import styled from 'styled-components';
import ProofOfEmpEng from './ProofOfEmpEng';
import ProofOfEmpKor from './ProofOfEmpKor';
import ProofOfGapgTax from './ProofOfGapgTax';
import ProofOfWithTax from './ProofOfWithTax';

interface IProofRequestModalProps {
  onClose: () => void;
  open: boolean;
  searchApplCd?: string;
  searchApplSeq?: string;
  detailYn?: string;
  authPg: AUTH_TYPE;
}

const ProofRequestModal: FC<IProofRequestModalProps> = ({ open, onClose, searchApplCd, searchApplSeq, detailYn, authPg }) => {
  const { sabun } = useUserInfo();
  const [commonInfo, setCommonInfo] = useState<ICommonRequestInfoInterface>();
  const [form] = Form.useForm();
  const { mutate } = useProofList();
  const initialValues = {
    occDate: moment(),
    addressType: '1',
  };
  const setMessage = useSetRecoilState(currentCommonMessageAtom);
  const { data } = useProofDetail({ applCd: searchApplCd, applSeq: searchApplSeq });
  const detailData = useMemo(() => data?.[0], [data]);
  const [sYmd, setSYmd] = useState<string>(detailData?.sYmd || '');
  const [eYmd, setEYmd] = useState<string>(detailData?.eYmd || '');

  const generateParam = (data: any, submitData: any) => {
    //data[0].symd = data[0].empYmd;
    //data[0].eymd = data[0].currYmd;
    const params: IProofPostParam = {
      ...data,
      applYmd: moment().format(GlobalFormat.DATE_API),
      applCd: searchApplCd,
      reqYy: submitData.reqYy,
      applSeq: commonInfo?.applSeq,
      address: submitData.address,
      purpose: submitData.purpose,
      etc: submitData.etc,
      resNoYn: submitData.resNoYn,
      submitOffice: submitData.submitOffice,

      sYmd: submitData.sYmd && submitData.sYmd[0].substr(0, 6),
      eYmd: submitData.sYmd && submitData.sYmd[1].substr(0, 6),
      //prtCnt: 0, //출력횟수
    };
    return params;
  };

  useEffect(() => {
    if (searchApplSeq && detailData) {
      form.setFieldsValue({
        submitOffice: detailData.submitOffice,
        etc: detailData.etc,
        purpose: detailData.purpose,
        sYmd: [moment(detailData.sYmd), moment(detailData.eYmd)],
      });
      form.setFieldsValue({ bankType: detailData.prtYn });
    }
  }, [detailData, form, searchApplSeq]);

  const handleSubmit = async (params: IProofPostParam) => {
    try {
      await postProof({ ...params, sabun, reqSabun: sabun });
      mutate();
      onClose();
      setMessage({
        open: true,
        message: '신청이 완료되었습니다.',
        type: 'success',
      });
    } catch (e: any) {
      setMessage({
        open: true,
        message: e?.response?.data?.message,
        type: 'error',
      });
    }
  };

  const tenure = useMemo(
    () =>
      `${formatDate(detailData?.empYmd, GlobalFormat.DATE_DASH)} ~ ${formatDate(detailData?.currYmd, GlobalFormat.DATE_DASH)} (${
        detailData?.year
      }년 ${detailData?.month}개월)`,
    [detailData],
  );
  useEffect(() => {
    form.setFieldsValue({ empYmd: tenure });
  }, [tenure]);

  const renderTypeModal = () => {
    switch (searchApplCd) {
      case '11':
        return <ProofOfEmpKor form={form} addressType={addressType} />;
      case '12':
        return <ProofOfEmpEng form={form} addressType={addressType} />;
      case '16':
        return <ProofOfWithTax form={form} addressType={addressType} />;
      case '18':
        return <ProofOfGapgTax form={form} addressType={addressType} sYmd={sYmd} eYmd={eYmd} />;
      default:
        return null;
    }
  };
  const [addressType, setAddressType] = useState<string>(initialValues.addressType);

  const checkValidation = async () => {
    const [from, to] = form.getFieldValue('sYmd') || [null, null];
    const symd = moment(from);
    const eymd = moment(to);
    const diff = eymd.diff(symd, 'months');
    if (diff > 11) {
      setMessage({
        open: true,
        message: '신청기간은 12개월을 초과할 수 없습니다.',
        type: 'error',
      });
      return false;
    }
    return true;
  };

  return (
    <CommonRequestModal
      onSubmit={async () => {
        await form.validateFields();
        const isValid = await checkValidation();
        if (!isValid) {
          return;
        }
        form.submit();
      }}
      onTemporary={async () => {
        await form.validateFields();
        const isValid = await checkValidation();
        if (!isValid) {
          return;
        }
        form.submit();
      }}
      open={open}
      onClose={onClose}
      applCd={searchApplCd}
      applSeq={searchApplSeq}
      authPg={authPg}
      onSuccessRequestCommonInfo={setCommonInfo}
      title={'제증명신청'}
      onCancelDone={() => {
        mutate();
      }}>
      <CommonForm
        readOnly={authPg === '상세'}
        form={form}
        onFinish={async (submitData) => {
          const isValid = await checkValidation();
          if (!isValid) {
            return;
          }
          const params = generateParam(data, submitData);
          handleSubmit(params);
        }}
        onValuesChange={(changedValue, { addressType }: any) => {
          setAddressType(addressType);
        }}
        initialValues={initialValues}>
        <ModalContainer>{renderTypeModal()}</ModalContainer>
      </CommonForm>
    </CommonRequestModal>
  );
};

const ModalContainer = styled.div`
  padding: 15px 0;
  display: grid;
  gap: 16px;
  overflow-y: scroll;
  position: relative;
`;

export default ProofRequestModal;
