import { useDtlCodeList } from '@apis/vacation';
import { CommonFormField } from '@components/common/atoms/CommonForm';
import { COLOR } from '@constants/Color';
import { TextField } from '@mui/material';
import { FormInstance } from 'rc-field-form';
import React, { FC, useEffect } from 'react';

const CancelDtlCodeSelect: FC<{ gntGubunCd?: string; searchApplCd?: string; form: FormInstance<any> }> = ({
  gntGubunCd,
  searchApplCd,
  form,
}) => {
  const { data: dtlCodeList } = useDtlCodeList(gntGubunCd, searchApplCd);
  useEffect(() => {
    form.setFieldsValue({ gntCd: dtlCodeList?.[0]?.codeNm || '근태종류' });
  }, [dtlCodeList, form]);
  return (
    <CommonFormField name={'gntCd'} rules={[{ required: true }]}>
      <TextField
        style={{ backgroundColor: COLOR.BG_LIGHTGRAY, flex: 1 }}
        InputProps={{ style: { padding: '0 4px', fontSize: 16, color: 'rgba(0, 0, 0, 0.6)' } }}
      />
    </CommonFormField>
  );
};

export default CancelDtlCodeSelect;
