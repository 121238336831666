import { ApplicationStatus } from '@constants/ApplicationStatus';
import { useEffect, useState } from 'react';
import { selectCodeName, useApplStatusCodeList } from 'src/util/commonCode';
import styled from 'styled-components';

const ApplStatusCodeName = ({ applStatusCd }: { applStatusCd: string }) => {
  const { data } = useApplStatusCodeList();
  const [codeName, setCodeName] = useState<string>();
  useEffect(() => {
    const result = selectCodeName(applStatusCd, data);
    setCodeName(result);
  }, [data, applStatusCd]);
  return <>{codeName}</>;
};

const ApplStatusCdTemplate = ({ status }: { status: string }) => {
  if (status === ApplicationStatus.처리완료) {
    return (
      <StatusFont style={{ background: '#1144ae' }}>
        <ApplStatusCodeName applStatusCd={status} />
      </StatusFont>
    );
  } else if (status === ApplicationStatus.임시저장) {
    return (
      <StatusFont style={{ background: 'gray' }}>
        <ApplStatusCodeName applStatusCd={status} />
      </StatusFont>
    );
  } else if (status === ApplicationStatus.결재처리중 || status === ApplicationStatus.수신처리중) {
    return (
      <StatusFont style={{ background: 'green' }}>
        <ApplStatusCodeName applStatusCd={status} />
      </StatusFont>
    );
  } else if (status === ApplicationStatus.결재반려 || status === ApplicationStatus.수신반려) {
    return (
      <StatusFont style={{ background: '#e40303' }}>
        <ApplStatusCodeName applStatusCd={status} />
      </StatusFont>
    );
  } else {
    return null;
  }
};

const StatusFont = styled.a`
  color: white;
  padding: 4px 8px 4px;
  border-radius: 10px;
  box-shadow: inset 0 -3px 0 rgb(0 0 0 / 30%);
  height: 25px;
  font-size: 12px;
  float: right;
`;

export default ApplStatusCdTemplate;
