import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonSelect from '@components/common/molecules/CommonSelect';
import React, { FC } from 'react';
import { useMiscarriagePeriodList } from 'src/util/commonCode';

const MiscarriagePeriodSelect: FC<{ gntGubunCd?: string }> = ({ gntGubunCd }) => {
  const { data: dtlCodeList } = useMiscarriagePeriodList();
  return (
    <CommonFormField name={'misStiTerm'} rules={[{ required: true }]}>
      <CommonSelect
        title={'유산/사산 휴가 기간'}
        options={dtlCodeList?.map((item) => ({
          label: item.codeNm,
          value: item.code,
        }))}
      />
    </CommonFormField>
  );
};

export default MiscarriagePeriodSelect;
