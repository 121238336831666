import { COLOR } from '@constants/Color';
import { ReadonlyBackground } from '@constants/CommonStyles';
import { TextField, TextFieldProps } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { IMaskInput } from 'react-imask';
import NumberFormat from 'react-number-format';
import { IFieldChildrenProps } from './CommonForm';
import CommonVibe from './CommonVibe';

interface ICommonInputProps {
  readOnly?: boolean;
  fontSize?: number;
  labelFontSize?: number;
  inputComponent?: any;
  pattern?: 'time' | 'money' | 'number';
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const sanitizeInput = (value: string) => {
  // 허용된 문자들로만 구성된 문자열로 필터링
  return value.replace(/[^ㄱ-ㅣ가-힣a-zA-Z0-9]/g, '');
};

const TextTimeMaskCustom = React.forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(props, ref: any) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={'00:00'}
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const NumberCommaCustom = React.forwardRef<NumberFormat<any>, CustomProps>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      suffix={' 원'}
    />
  );
});

const NumberOnlyCustom = React.forwardRef<NumberFormat<any>, CustomProps>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
});

const CommonInput = ({
  iserror,
  meta,
  readOnly,
  fontSize = 16,
  labelFontSize = 16,
  pattern,
  onChange,
  ...props
}: TextFieldProps & IFieldChildrenProps & ICommonInputProps) => {
  const [active, setActive] = useState(false);

  const validBorder = useMemo(() => {
    const isRed = !active && iserror;
    return isRed ? { boxShadow: `0px 0px 0px 2px ${COLOR.RED}`, width: 'calc(100% - 4px)', margin: '0 2px' } : {};
  }, [active, iserror]);

  const readOnlyStyle = useMemo(() => (readOnly ? ReadonlyBackground : {}), [readOnly]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedValue = sanitizeInput(e.target.value);
    if (onChange) {
      onChange({
        ...e,
        target: {
          ...e.target,
          value: sanitizedValue,
        },
      });
    }
  };

  return (
    <CommonVibe iserror={!!iserror}>
      <TextField
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        color={'secondary'}
        {...props}
        onChange={handleChange}
        InputLabelProps={{
          ...props.InputLabelProps,
          shrink: active || !!props.value,
          style: {
            fontSize: labelFontSize,
          },
        }}
        value={props.value ?? ''}
        style={{ width: '100%' }}
        InputProps={{
          readOnly,
          ...props.InputProps,
          inputComponent: (pattern === 'time'
            ? TextTimeMaskCustom
            : pattern === 'money'
            ? NumberCommaCustom
            : pattern === 'number'
            ? NumberOnlyCustom
            : undefined) as any,
          style: {
            fontSize: fontSize,
            height: 50,
            ...validBorder,
            ...readOnlyStyle,
          },
        }}
      />
    </CommonVibe>
  );
};

export default CommonInput;
