import {
  ICommonRequestInfoInterface,
  IPostApprovalResponseInterface,
  postApproval,
  postUpdateCancelStatusCd,
  useApprovalAcceptInfo,
  useCommonDetailInfo,
  useCommonRequestInfo,
} from '@apis/common';
import { IUploadedFileListItemInterface } from '@apis/fileUpload';
import CausionModal from '@components/common/molecules/CausionModal';
import CommonModal from '@components/common/molecules/CommonModal';
import CommonRequestBottomButtons from '@components/common/molecules/CommonRequestBottomButtons';
import { COLOR } from '@constants/Color';
import FmdBadRoundedIcon from '@mui/icons-material/FmdBadRounded';
import { Stack } from '@mui/material';
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { useGlobalConfirm } from '../atoms/CommonConfirm';
import { currentCommonMessageAtom } from '../atoms/CommonMessage';
import CommonApprovalLine from '../molecules/CommonApprovalLine';
import { useUserInfo } from '../molecules/CommonUserInfo';
import FileUpload from './FileUpload';

interface ICommonRequestModalProps {
  onClose: () => void;
  onSubmit?: (requestCommonData?: IPostApprovalResponseInterface) => void;
  onTemporary?: (requestCommonData?: IPostApprovalResponseInterface) => void;
  open: boolean;
  applSeq?: string;
  applCd?: string;
  authPg?: AUTH_TYPE;
  children: ReactNode;
  title: string;
  onSuccessRequestCommonInfo?: (requestCommonData?: ICommonRequestInfoInterface) => void;
  onCancelDone: () => void;
}

const CautionModal: FC<{ isMustShow?: boolean; contents?: string }> = ({ isMustShow, contents }) => {
  const [cautionModalShow, isCautionModalShow] = useState(false);

  useEffect(() => {
    setTimeout(() => isMustShow && isCautionModalShow(true), 500);
  }, [isMustShow, isCautionModalShow]);

  return isMustShow ? (
    <>
      <Stack direction={'row'} onClick={() => isCautionModalShow(true)} marginTop={1}>
        <FmdBadRoundedIcon color={'primary'} />
        <CautionFont>신청 시 유의사항 보기</CautionFont>
      </Stack>
      <CausionModal open={cautionModalShow} onClose={() => isCautionModalShow(false)} contents={contents} />
    </>
  ) : null;
};

const CommonRequestModal: FC<ICommonRequestModalProps> = ({
  open,
  onClose,
  onSubmit,
  onTemporary,
  applCd,
  applSeq,
  authPg,
  children,
  title,
  onCancelDone,
  onSuccessRequestCommonInfo,
}) => {
  const [fileSeq, setFileSeq] = useState<string>();
  const { sabun } = useUserInfo();
  const { data: commonDetailInfo, mutate: commonDetailMutate } = useCommonDetailInfo(
    open && applSeq ? { searchApplCd: applCd, searchApplSeq: applSeq, searchApplSabun: sabun, searchSabun: sabun } : null,
  );
  const { data: approvalList, mutate: approvalMutate } = useApprovalAcceptInfo(applSeq);
  const { data: commonRequestInfo, mutate: commonRequestMutate } = useCommonRequestInfo(
    open ? { searchApplCd: applCd, searchApplSeq: applSeq } : null,
  );

  useEffect(() => {
    approvalMutate();
  }, [approvalMutate]);

  useEffect(() => {
    commonDetailMutate();
  }, [commonDetailMutate]);

  useEffect(() => {
    commonRequestMutate();
  }, [commonRequestMutate]);

  const isMustShow = useMemo(() => commonRequestInfo?.etcNoteYn === 'Y', [commonRequestInfo]);
  const [files, setFiles] = useState<IUploadedFileListItemInterface[]>();
  const setMessage = useSetRecoilState(currentCommonMessageAtom);
  const { open: confirmOpen, close: confirmClose } = useGlobalConfirm();

  const submitHandle = useCallback(
    async (isRegist: boolean) => {
      const callSubmit = isRegist ? onSubmit : onTemporary;
      await callSubmit?.();
      await postApproval({
        searchApplCd: commonRequestInfo?.applCd,
        searchSabun: sabun,
        searchApplStatusCd: isRegist ? '21' : '11',
        searchApplSeq: commonRequestInfo?.applSeq,
        fileSeq,
        pathSeq: commonRequestInfo?.orgLevelCd,
      });

      confirmClose();
    },
    [commonRequestInfo, confirmClose, fileSeq, onSubmit, onTemporary, sabun],
  );

  const registHandle = useCallback(async () => {
    if (commonRequestInfo?.fileYn === 'Y' && commonRequestInfo?.fileEssentialYn === 'Y' && !files?.length) {
      setMessage({
        type: 'error',
        open: true,
        message: '파일 첨부가 필요한 신청입니다.',
      });
      return;
    }
    confirmOpen({
      title: '신청',
      contents: `해당 신청을 진행하시겠습니까?`,
      onOkPress: () => {
        submitHandle(true);
      },
    });
  }, [commonRequestInfo, confirmOpen, files?.length, setMessage, submitHandle]);

  const temporaryHandle = useCallback(async () => {
    confirmOpen({
      title: '임시저장',
      contents: `임시저장을 진행하시겠습니까?`,
      onOkPress: () => {
        submitHandle(false);
      },
    });
  }, [confirmOpen, submitHandle]);

  const approvalPossible = useMemo(() => approvalList?.some((item) => item.agreeSabun === sabun), [approvalList, sabun]);

  useEffect(() => {
    commonRequestInfo && onSuccessRequestCommonInfo && onSuccessRequestCommonInfo(commonRequestInfo);
  }, [commonRequestInfo, onSuccessRequestCommonInfo]);

  const handleCancel = useCallback(async () => {
    try {
      await postUpdateCancelStatusCd(applSeq, sabun);
      setMessage({
        open: true,
        message: '신청이 완료되었습니다.',
        type: 'success',
      });
      confirmClose();
      onCancelDone();
      onClose();
    } catch (e: any) {
      setMessage({
        open: true,
        message: e?.response?.data?.message,
        type: 'error',
      });
    }
  }, [confirmClose, onCancelDone, onClose, setMessage, applSeq, sabun]);

  const onCancelButtonClick = useCallback(() => {
    confirmOpen({
      title: '취소',
      contents: `해당 신청을 취소하시겠습니까?`,
      onOkPress: () => {
        handleCancel();
      },
    });
  }, [confirmOpen, handleCancel]);

  const onFileUploaded = useCallback(
    (files, fileSeq) => {
      setFiles(files);
      setFileSeq(fileSeq);
    },
    [setFiles, setFileSeq],
  );
  return (
    <CommonModal animationHeight={'90%'} open={open} onClose={onClose} title={title}>
      <CommonApprovalLine
        authPg={authPg}
        searchApplSeq={applSeq}
        searchApplCd={applCd}
        searchSabun={sabun}
        orgLevelCd={
          commonRequestInfo?.orgLevelCd == '4' || commonRequestInfo?.orgLevelCd == '2' ? '0' : commonRequestInfo?.orgLevelCd
        }
        recevYn={commonRequestInfo?.recevYn}
      />
      {isMustShow && <CautionModal isMustShow={isMustShow} contents={commonRequestInfo?.etcNote} />}
      {open && children}
      {/* //fileEssentialYn */}
      {commonRequestInfo?.fileYn === 'Y' && (
        <FileUpload
          fileSeq={commonDetailInfo?.applMasterInfo?.fileSeq}
          applStatusCd={commonDetailInfo?.applMasterInfo.applStatusCd}
          onUploaded={onFileUploaded}
        />
      )}
      <CommonRequestBottomButtons
        authPg={authPg}
        applSeq={applSeq}
        applCd={applCd}
        onCloseButtonClick={onClose}
        onTempButtonClick={onTemporary ? temporaryHandle : null}
        onCancelButtonClick={onCancelButtonClick}
        onRequestButtonClick={onSubmit ? registHandle : null}
        onApprovalButtonClick={approvalPossible ? () => {} : null}
        onRejectButtonClick={approvalPossible ? () => {} : null}
        // cancelButton={commonDetailInfo?.cancelButton?.cancel === 'YES'}
      />
    </CommonModal>
  );
};

const CautionFont = styled.span`
  color: ${COLOR.RED};
`;

export default CommonRequestModal;
