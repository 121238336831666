import { useStudentFundsStandardData } from '@apis/studentFunds';
import { COLOR } from '@constants/Color';
import { Divider, Stack } from '@mui/material';
import React from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

const FundsInfo = ({ schType }: { schType: string }) => {
  const { data: result } = useStudentFundsStandardData(schType);
  return (
    <Container spacing={0.4} direction={'column'} divider={<Divider orientation='horizontal' flexItem />}>
      <ItemContainer>
        <TitleFont>제출서류</TitleFont>
        <ContentFont>{result?.prDoc}</ContentFont>
      </ItemContainer>
      <ItemContainer>
        <TitleFont>지원한도금액</TitleFont>
        <ContentFont>
          {result?.limitAmt ? (
            <NumberFormat value={result?.limitAmt} displayType={'text'} thousandSeparator={true} suffix={' 원'} />
          ) : (
            '무제한'
          )}
        </ContentFont>
      </ItemContainer>
      <ItemContainer>
        <TitleFont>지원비율(국내)</TitleFont>
        <ContentFont>{`${result?.domComRate}%`}</ContentFont>
      </ItemContainer>
      <ItemContainer>
        <TitleFont>지원비율(국외)</TitleFont>
        <ContentFont>{`${result?.intComRate}%`}</ContentFont>
      </ItemContainer>
    </Container>
  );
};

const Container = styled(Stack)`
  border: 1px solid ${COLOR.LIGHT_GRAY};
  border-radius: 12px;
  padding: 12px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const TitleFont = styled.div`
  font-size: 12px;
`;

const ContentFont = styled.div`
  font-size: 12px;
`;
export default FundsInfo;
