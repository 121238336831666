import { COLOR } from '@constants/Color';
import { ReadonlyBackground } from '@constants/CommonStyles';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { FC, ReactNode, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { IFieldChildrenProps } from '../atoms/CommonForm';
import CommonVibe from '../atoms/CommonVibe';
import HorizontalBlank from '../atoms/HorizontalBlank';
import CommonModal from './CommonModal';

export interface ISelectOptionItemInterface {
  label: ReactNode;
  value: string;
  data?: any;
}

export interface ISelectProps extends IFieldChildrenProps {
  options?: ISelectOptionItemInterface[];
  title: string;
  onChangeItem?: (value: string, item: ISelectOptionItemInterface | null) => void;
  onChange?: (value?: string) => void;
  readOnly?: boolean;
}
const CommonSelect: FC<ISelectProps> = ({ value, title, options, meta, iserror, readOnly, onChange, onChangeItem }) => {
  const [active, isActive] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ISelectOptionItemInterface>();
  const removeItem: React.MouseEventHandler<SVGSVGElement> = (event) => {
    if (selectedItem) {
      event.stopPropagation();
      selectedItem && setSelectedItem(undefined);
      onChange && onChange(undefined);
      onChangeItem && onChangeItem('', null);
    }
  };

  useEffect(() => {
    value !== undefined && setSelectedItem(options?.find((item) => item.value === value));
  }, [value, options, onChange]);

  return (
    <CommonVibe iserror={!!iserror}>
      <SelectContainer
        style={readOnly ? ReadonlyBackground : {}}
        errors={!!iserror}
        onClick={() => !readOnly && isActive(true)}
        active={active}>
        <TitleFont active={active} selected={!!selectedItem}>
          {title}
        </TitleFont>
        <SelectValueFont>{selectedItem?.label}</SelectValueFont>
        {selectedItem ? <CloseIconContainer onClick={removeItem} /> : <SearchIconContainer onClick={removeItem} />}
      </SelectContainer>
      <CommonModal backdrop animationHeight={'40%'} title={title} open={active} onClose={() => isActive(false)}>
        <HorizontalBlank height={8} />
        <ScrollContainer>
          {options?.map((item) => (
            <OptionItem
              id={item.value}
              active={selectedItem?.value === item.value}
              key={item.value}
              onClick={() => {
                isActive(false);
                setSelectedItem(item);
                onChange && onChange(item.value);
                onChangeItem && onChangeItem(item.value, item);
              }}>
              {item.label}
            </OptionItem>
          ))}
        </ScrollContainer>
      </CommonModal>
    </CommonVibe>
  );
};

const ScrollContainer = styled.div`
  overflow-y: scroll;
  position: relative;
  max-height: 24vh;
  scroll-padding-top: 0px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  scroll-snap-type: y mandatory;
`;
const SelectContainer = styled.div<ICommonInput>`
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.23);
  display: flex;
  align-items: center;
  padding: 6px 14px;
  position: relative;
  ${({ active }) =>
    active &&
    css`
      border-width: 2px;
      border-color: ${COLOR.YELLOW};
    `};
  ${({ active, errors }) =>
    !active &&
    errors &&
    css`
      box-shadow: 0px 0px 0px 2px ${COLOR.RED};
      width: calc(100% - 4px);
      margin: 0 2px;
    `}
`;

const SelectValueFont = styled.span<ICommonInput>`
  color: inherit;
  transition: all 0.2s ease-in-out;
  font-size: 16px;
  position: absolute;
  top: 12px;
  left: 14px;
  overflow: hidden;
  padding: 0 0px;
  background-color: white;
`;

const TitleFont = styled(SelectValueFont)<{ selected?: boolean } & ICommonInput>`
  color: rgba(0, 0, 0, 0.6);
  ${({ errors }) =>
    errors &&
    css`
      color: ${COLOR.RED};
    `}
  ${({ active, selected }) =>
    (active || selected) &&
    css`
      padding: 0 5px;
      color: ${active ? COLOR.YELLOW : `rgba(0, 0, 0, 0.6)`};
      font-size: 11px;
      top: -10px;
      left: 10px;
    `};
`;

const OptionItem = styled.div<ICommonInput>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  flex: 0 0 40px;
  font-size: 13px;
  scroll-snap-align: start;
  ${({ active }) =>
    active &&
    css`
      background-color: ${COLOR.ACTIVE_RED};
      color: ${COLOR.RED};
    `}
`;

const SelectIcon = `
  color: ${COLOR.RED};
  position: absolute;
  right:12px;
`;

const CloseIconContainer = styled(CloseIcon)`
  ${SelectIcon}
`;
const SearchIconContainer = styled(SearchIcon)`
  ${SelectIcon}
`;
export default CommonSelect;
