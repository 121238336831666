import styled, { keyframes } from 'styled-components';

const showAnimation = keyframes`
from {
  opacity: 0;
}
to{
  opacity: 1;
}
`;
const hideAnimation = keyframes`
from {
  opacity: 1;
}
to{
  opacity: 0;
}
`;

const Masking = styled.div<{ open: boolean; zIndex?: number }>`
  animation: ${({ open }) => (open ? showAnimation : hideAnimation)} 0.3s ease-in-out forwards;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  opacity: 0;
`;

export default Masking;
