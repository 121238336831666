import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonSelect, { ISelectOptionItemInterface } from '@components/common/molecules/CommonSelect';
import React, { FC } from 'react';
interface ISplitUseSelectSelectProps {
  onChangeItem?: (value: string, item: ISelectOptionItemInterface | null) => void;
}
const SplitUseSelect: FC<ISplitUseSelectSelectProps> = ({ onChangeItem }) => {
  return (
    <CommonFormField name={'divUseYn'}>
      <CommonSelect
        title='분할사용'
        options={[
          { label: 'Y', value: 'Y' },
          { label: 'N', value: 'N' },
        ]}
      />
    </CommonFormField>
  );
};

export default SplitUseSelect;
