import { IVacationCancelListResponse } from '@apis/vacationCancle';
import Accordion from '@components/common/molecules/Accordion';
import { COLOR } from '@constants/Color';
import { GlobalFormat } from '@constants/Format';
import { Divider, Paper, Stack } from '@mui/material';
import { FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { formatDate } from 'src/util/commonUtil';
import styled from 'styled-components';
import { currentOnVacationListSelectFunction } from './RegisteredVacationListTemplate';

const SetTitleColor = ({ applStatusCd }: { applStatusCd: string }) => {
  const backgroundColor = useMemo(() => {
    switch (applStatusCd) {
      case '11':
        return 'gray';
      case '21':
      case '31':
        return 'green';
      case '23':
      case '33':
        return '#e40303';
      case '99':
        return '#1144ae';
      default:
        return '';
    }
  }, [applStatusCd]);
  return <StatusFont style={{ backgroundColor }}>처리완료</StatusFont>;
};

const RegisteredVacationCancelItem: FC<IVacationCancelListResponse> = (item) => {
  const onSelect = useRecoilValue(currentOnVacationListSelectFunction);

  return (
    <>
      <ItemContainer>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <HeadDiv>
            <TitleFont>{item.gntNm}</TitleFont>
            <SetTitleColor applStatusCd={item.applStatusCd}></SetTitleColor>
          </HeadDiv>
          <HandleIcon
            onClick={() => {
              onSelect?.(item);
            }}>
            선택
          </HandleIcon>
        </Stack>

        <Divider style={{ margin: '0px ​0px 10px 0p' }} />
        <Row>
          <DetailTitleFont>신청일자</DetailTitleFont>
          <ContentFont>{formatDate(item.applYmd, GlobalFormat.DATE_DASH)}</ContentFont>
        </Row>
        <Row>
          <DetailTitleFont>신청기간</DetailTitleFont>
          <ContentFont>{`${formatDate(item.sYmd, GlobalFormat.DATE_DASH)} ~ ${formatDate(
            item.eYmd,
            GlobalFormat.DATE_DASH,
          )}`}</ContentFont>
        </Row>
        <Row>
          <DetailTitleFont>적용일수</DetailTitleFont>
          <ContentFont>{`${item.closeDay ?? 0}일`}</ContentFont>
        </Row>

        {item?.gntReqReason && (
          <Accordion>
            <Stack direction={'row'}>
              <DetailTitleFont>취소사유</DetailTitleFont>
              <ContentFont>
                <PaperContainer>
                  <ContentPaper elevation={1} style={{ background: COLOR.BG_WHITE }}>
                    <ContentPreFont>{item.gntReqReason}</ContentPreFont>
                  </ContentPaper>
                </PaperContainer>
              </ContentFont>
            </Stack>
          </Accordion>
        )}
      </ItemContainer>
    </>
  );
};

const ItemContainer = styled.div`
  padding: 13px;
  background-color: ${COLOR.BG_LIGHTGRAY};
  margin-bottom: 13px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 30%);
`;
const Row = styled.div`
  margin: 4px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const HeadDiv = styled.div`
  display: inline-flex;
`;

const TitleFont = styled.div`
  font-size: 16px;
  color: ${COLOR.BLACK};
  margin-bottom: 10px;
  padding-right: 10px;
`;

const StatusFont = styled.a`
  color: white;
  padding: 4px 8px 4px;
  border-radius: 10px;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 30%);
  height: 25px;
  font-size: 11px;
`;

const DetailTitleFont = styled.div`
  font-size: 12px;
  color: #a0a0a0;
  flex: 25;
  text-align: right;
  padding: 1px;
`;

const ContentFont = styled.div`
  font-size: 12px;
  color: ${COLOR.GRAY};
  flex: 75;
  padding-left: 10px;
`;

const HandleIcon = styled.div`
  font-weight: 600;
  padding: 6px 18px;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 30%);
  background-color: #fc0;
  font-size: 14px;
  float: right;
  margin-top: -14px;
`;
const PaperContainer = styled.div`
  padding: 4px 4px 4px 0;
`;

const ContentPaper = styled(Paper)`
  padding: 12px;
`;

const ContentPreFont = styled(ContentFont)`
  margin-left: 0px;
  white-space: pre-wrap;
`;

export default RegisteredVacationCancelItem;
