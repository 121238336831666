import MYHR from '@assets/images/myHR_로고.png';
import 경조금신청 from '@assets/images/경조금신청_아이콘.png';
import 근태신청 from '@assets/images/근태신청_red.png';
import 근태신청취소 from '@assets/images/근태신청취소_red.png';
import 급여명세서 from '@assets/images/급여명세서_red.png';
import 대출 from '@assets/images/대출_red.png';
import 제증명신청 from '@assets/images/제증명신청_아이콘.png';
import 질환위로금 from '@assets/images/질환위로금_red.png';
import 학자금 from '@assets/images/학자금_red.png';
import home1 from '@assets/images/홈화면1.jpg';
import home2 from '@assets/images/홈화면2.jpg';
import home3 from '@assets/images/홈화면3.jpg';
import home4 from '@assets/images/홈화면4.jpg';
import { useHeaderStore } from '@stores/headerStore';
import '@styles/swiper.css';
import { useEffect } from 'react';
import { EnumRouteUrl } from 'src/routers/Routers';
import styled from 'styled-components';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import MenuButton from './templates/MenuButton';

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);

const HomePage = () => {
  const { setConfig } = useHeaderStore();
  useEffect(() => {
    setConfig({ title: 'Home', isHome: true });
  }, [setConfig]);
  return (
    <HomeContainer>
      <div>
        <Swiper
          style={{ height: '100%', width: '100vw' }}
          slidesPerView={1}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={true}
          className='mySwiper'>
          <SwiperSlide style={{ display: 'flex', alignItems: 'flex-end' }}>
            <SlideImage src={home1} alt='slider1' />
          </SwiperSlide>
          <SwiperSlide style={{ display: 'flex', alignItems: 'flex-end' }}>
            <SlideImage src={home2} alt='slider2' />
          </SwiperSlide>
          <SwiperSlide style={{ display: 'flex', alignItems: 'flex-end' }}>
            <SlideImage src={home3} alt='slider3' />
          </SwiperSlide>
          <SwiperSlide style={{ display: 'flex', alignItems: 'flex-end' }}>
            <SlideImage src={home4} alt='slider3' />
          </SwiperSlide>
        </Swiper>
      </div>
      <MenuContainer>
        <MenuButton
          delay={0}
          path={EnumRouteUrl.VACATION}
          direction='left'
          icon={<IconImage src={근태신청} />}
          title='근태신청'
        />
        <MenuButton
          delay={0}
          path={EnumRouteUrl.VACATION_CANCEL}
          direction='left'
          icon={<IconImage src={근태신청취소} />}
          title='근태취소신청'
        />
        <MenuButton
          delay={0.15}
          direction='right'
          icon={<IconImage src={제증명신청} />}
          title='제증명신청'
          path={EnumRouteUrl.PROOF}
        />
        <MenuButton
          delay={0.3}
          direction='left'
          icon={<IconImage src={경조금신청} />}
          title='경조금'
          path={EnumRouteUrl.FAMILY_EVENT}
        />

        <MenuButton delay={0.3} direction='left' icon={<CenterImage src={MYHR} />} />

        <MenuButton
          delay={0.45}
          direction='right'
          icon={<IconImage src={질환위로금} />}
          path={EnumRouteUrl.DISEASE}
          title='질환위로금'
        />
        <MenuButton
          delay={0.45}
          direction='right'
          icon={<IconImage src={학자금} />}
          path={EnumRouteUrl.STUDENT_FUNDS}
          title='학자금'
        />
        <MenuButton delay={0.45} direction='right' icon={<IconImage src={대출} />} path={EnumRouteUrl.LOAN} title='대출' />
        <MenuButton
          delay={0.45}
          direction='right'
          icon={<IconImage src={급여명세서} />}
          path={EnumRouteUrl.PAYSLIP}
          title='급여명세서'
        />
      </MenuContainer>
    </HomeContainer>
  );
};

const HomeContainer = styled.div`
  flex: 1;
  background: white;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
`;

const MenuContainer = styled.div`
  height: 100%;
  width: 100vw;
  display: flex;
  background: white;
  justify-content: center;
  flex: 1;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  gap: 2px;
`;

const SlideImage = styled.img`
  object-fit: contain;
  width: 100vw;
`;

const IconImage = styled.img`
  object-fit: contain;
  width: 50px;
  height: 50px;
`;
const CenterImage = styled.img`
  object-fit: contain;
  width: 117px;
  /* height: 200px; */
`;

export default HomePage;
