import fileDownload from 'js-file-download';
import queryString from 'query-string';
import { axiosInstance, useRequest } from 'src/axios/fetcher';

export const postFileUpload = async (formData: FormData, onProgress?: (percentage: number) => void) => {
  return await axiosInstance.post('/comm/control/myhr/common/upload', formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress: (e) => {
      let percentage = (e.loaded * 100) / e.total;
      onProgress?.(percentage);
    },
  });
};

export interface IUploadedFileListItemInterface {
  enterCd: string;
  fileSeq: string;
  rfileNm: string;
  seqNo: string;
  sfileNm: string;
}

export const useFileList = (fileSeq?: string) => {
  return useRequest<IUploadedFileListItemInterface[]>(
    fileSeq
      ? {
          url: `/comm/control/myhr/common/attachmentBySeqNo`,
          params: { fileSeq },
        }
      : null,
  );
};

export const downloadFileList = async ({ fileSeq, seqNo, rfileNm }: { fileSeq: string; seqNo: string; rfileNm: string }) => {
  const queryUrl = queryString.stringifyUrl({
    url: `/comm/control/myhr/common/download`,
    query: { fileSeq, seqNo },
  });
  return axiosInstance
    .get(queryUrl, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, rfileNm);
    });
};

export const downloadBinaryFileList = async ({ fileSeq, seqNo }: { fileSeq: string; seqNo: string }) => {
  const queryUrl = queryString.stringifyUrl({
    url: `/comm/control/myhr/common/downloadBinary`,
    query: { fileSeq, seqNo },
  });
  return await axiosInstance.get(queryUrl);
};

export const deleteFileBySeqNo = async ({ fileSeq, seqNo, sabun }: { fileSeq: string; seqNo: string; sabun?: string }) => {
  if (!(fileSeq && seqNo && sabun)) {
    console.error('fileSeq, seqNo, sabun 값을 모두 입력해주세요');
  }
  const queryUrl = queryString.stringifyUrl({
    url: `/comm/control/myhr/common/attachmentBySeqNo`,
    query: { fileSeq, seqNo, sabun },
  });
  const data = { fileSeq, seqNo, sabun }; // 서버로 보낼 데이터
  return await axiosInstance.post(queryUrl,data);
};
