import { IStudentFundsDetailInterface } from '@apis/studentFunds';
import { COLOR } from '@constants/Color';
import { Paper } from '@mui/material';
import React, { FC } from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
interface IStudentApprovalResultProps {
  detailData?: IStudentFundsDetailInterface;
}
const StudentApprovalResult: FC<IStudentApprovalResultProps> = ({ detailData }) => {
  return (
    <Container>
      <Row style={{ fontSize: 14, marginBottom: 10, fontWeight: 600 }}>결재내용</Row>
      <Row>
        <TitleFont>- 급여반영월:</TitleFont>
        <ContentFont>{detailData?.payyymm}</ContentFont>
        <TitleFont>- 복지기금지급일:</TitleFont>
        <ContentFont2>
          <ContentFont>{detailData?.welPayDate}</ContentFont>
        </ContentFont2>
      </Row>
      <Row>
        <TitleFont>- 총지급금액:</TitleFont>
        <ContentFont2>
          <NumberFormat value={detailData?.paymonWel ?? 0} displayType={'text'} thousandSeparator={true} suffix={' 원'} />
        </ContentFont2>
      </Row>
    </Container>
  );
};

const Container = styled(Paper)`
  background-color: ${COLOR.BG_GRAY} !important;
  padding: 10px;
  margin-bottom: 12px;
`;

const TitleFont = styled.span`
  font-size: 12px;
`;

const Row = styled.div`
  font-size: 12px;
  margin: 4px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
const ContentFont = styled.div`
  font-size: 12px;
  color: ${COLOR.RED};
  flex: 30;
  padding-left: 10px;
  font-weight: bold;
`;

const ContentFont2 = styled(ContentFont)`
  flex: 50;
`;

export default StudentApprovalResult;
