import { IProofRequestParam } from '@apis/proofRequest';
import CommonContainer from '@components/common/atoms/CommonContainer';
import HorizontalBlank from '@components/common/atoms/HorizontalBlank';
import { useHeaderStore } from '@stores/headerStore';
import { useEffect, useState } from 'react';
import { atom } from 'recoil';
import ProofList from './templates/ProofList';
import ProofSearchForm from './templates/ProofSearchForm';

export const currentApprovalListParamAtom = atom<IProofRequestParam | null>({
  key: '@Proof/listParam',
  default: null,
});

const ProofRequestPage = () => {
  const [param, setParam] = useState<IProofRequestParam>();
  const { setConfig } = useHeaderStore();
  useEffect(() => {
    setConfig({ title: '제증명신청' });
  }, [setConfig]);
  return (
    <CommonContainer style={{ padding: 15 }}>
      <ProofSearchForm setParam={setParam} />
      <HorizontalBlank height={20} />
      <ProofList {...param} />
    </CommonContainer>
  );
};

export default ProofRequestPage;
