import { IApprovalRequestParam } from '@apis/approval';
import CommonButton from '@components/common/atoms/CommonButton';
import CommonForm, { CommonFormField } from '@components/common/atoms/CommonForm';
import HorizontalBlank from '@components/common/atoms/HorizontalBlank';
import CommonRangeDateFormField from '@components/common/molecules/CommonRangeDateFormField';
import CommonSelect from '@components/common/molecules/CommonSelect';
import { GlobalFormat } from '@constants/Format';
import moment from 'moment';
import Form from 'rc-field-form';
import { FC, useEffect, useMemo } from 'react';
import { useAppBizCd } from 'src/util/commonCode';
import styled from 'styled-components';
interface IApprovalFormProps {
  setParam: React.Dispatch<React.SetStateAction<IApprovalRequestParam | undefined>>;
}

const ApprovalSearchForm: FC<IApprovalFormProps> = ({ setParam }) => {
  const { data: listAppBizCd } = useAppBizCd();
  const [form] = Form.useForm();

  const initialValues = useMemo(
    () => ({
      fromToDate: [moment().add(-1, 'M').format(GlobalFormat.DATE_API), moment().format(GlobalFormat.DATE_API)],
    }),
    [],
  );

  useEffect(() => {
    const [sdt, edt] = initialValues.fromToDate;
    setParam({ sdt, edt });
  }, [initialValues, setParam]);
  return (
    <CommonForm
      form={form}
      onFinish={(params) => {
        const [sdt, edt] = params.fromToDate;
        setParam({ appBizCd: params.appBizCd, applStatusCd: params.applStatusCd, sdt, edt });
      }}
      initialValues={initialValues}>
      <CommonRangeDateFormField name={'fromToDate'} fromLabel={'기안일자 시작'} toLabel={'기안일자 종료'} required />
      <HorizontalBlank height={15} />
      <CommonFormField name={'appBizCd'}>
        <CommonSelect
          title={'신청서업무구분'}
          options={listAppBizCd?.map((item) => ({
            label: item.codeNm,
            value: item.code,
          }))}
        />
      </CommonFormField>
      <HorizontalBlank height={15} />
      <ButtonContainer>
        <CommonButton
          onClick={() => {
            form.submit();
          }}>
          조회
        </CommonButton>
      </ButtonContainer>
    </CommonForm>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;
export default ApprovalSearchForm;
