import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonSelect from '@components/common/molecules/CommonSelect';
import { GlobalFormat } from '@constants/Format';
import { Moment } from 'moment';
import { FC } from 'react';
import { useFamilyCodeList } from 'src/util/commonCode';
import { formatDate } from 'src/util/commonUtil';

interface IMoneyResultProps {
  occCd?: string;
  occDate?: Moment;
}
const FamilyCodeSelect: FC<IMoneyResultProps> = ({ occCd, occDate }) => {
  const searchApplYmd = formatDate(occDate, GlobalFormat.DATE_API);
  const { data: familyCodeList } = useFamilyCodeList({ occCd, searchApplYmd, cmd: 'getOccAppDetFamCdList' });
  return (
    <CommonFormField
      name={'famCd'}
      rules={[
        {
          required: true,
        },
      ]}>
      <CommonSelect
        title='가족관계'
        options={familyCodeList?.map((item) => ({
          label: item.codeNm,
          value: item.code.split('|')[0],
        }))}
      />
    </CommonFormField>
  );
};

export default FamilyCodeSelect;
