import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonInputDate from '@components/common/atoms/CommonInputDate';
import React, { FC, useEffect, useState } from 'react';

interface IVacationCodeProps {
  gntCd?: string;
  restTime?: number;
}

const RequestDate: FC<IVacationCodeProps> = ({ gntCd, restTime }) => {
  const [label, setLabel] = useState<string>('신청일');
  useEffect(() => {
    if (gntCd === '24' || gntCd === '35' || gntCd === '61') setLabel('출산예정일');
    if (gntCd === '37') setLabel('유산 또는 사산한 날');
  }, [gntCd]);

  return (
    <div>
      <CommonFormField
        name={'requestDate'}
        rules={[
          {
            required: true,
          },
        ]}>
        <CommonInputDate label={label} />
      </CommonFormField>
    </div>
  );
};

export default RequestDate;
