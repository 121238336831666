import { COLOR } from '@constants/Color';
import { Stack } from '@mui/material';
import React, { FC } from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
interface IAmountTemplateProps {
  association: number;
  company: number;
}
const AmountTemplate: FC<IAmountTemplateProps> = ({ association, company }) => {
  return (
    <Container>
      <LargeTitleFont>경조금 내역</LargeTitleFont>
      <AmountTemplateContainer>
        <Stack justifyContent={'center'} alignItems={'center'}>
          <TitleFont>회사</TitleFont>
          <ValueFont>
            <NumberFormat value={association} displayType={'text'} thousandSeparator={true} suffix={' 원'} />
          </ValueFont>
        </Stack>
        <Stack justifyContent={'center'} alignItems={'center'}>
          <TitleFont>사우회</TitleFont>
          <ValueFont>
            <NumberFormat value={company} displayType={'text'} thousandSeparator={true} suffix={' 원'} />
          </ValueFont>
        </Stack>
        <Stack justifyContent={'center'} alignItems={'center'}>
          <TitleFont style={{ fontWeight: 600 }}>합계</TitleFont>
          <ValueFont style={{ fontWeight: 600 }}>
            <NumberFormat value={association + company} displayType={'text'} thousandSeparator={true} suffix={' 원'} />
          </ValueFont>
        </Stack>
      </AmountTemplateContainer>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 8px;
`;

const LargeTitleFont = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: ${COLOR.RED_DARKEN};
  margin-bottom: 6px;
`;

const AmountTemplateContainer = styled.div`
  backdrop-filter: blur(4px);
  background: ${COLOR.BG_GRAY};
  width: 100%;
  padding: 4px;
  bottom: 0;
  display: flex;
  justify-content: space-around;
`;

const TitleFont = styled.span`
  font-size: 14px;
  color: ${COLOR.RED};
`;
const ValueFont = styled.span`
  font-size: 12px;
  color: ${COLOR.RED_DARKEN};
`;

export default AmountTemplate;
