import { deleteLoan, ILoanListInterface, useLoanList } from '@apis/loan';
import ApplStatusCdTemplate from '@components/common/atoms/ApplStatusCdTemplate';
import CommonCodeName from '@components/common/atoms/CommonCodeName';
import CommonDeleteIcon from '@components/common/atoms/CommonDeleteIcon';
import VerticalBlank from '@components/common/atoms/VerticalBlank';
import { ApplicationStatus } from '@constants/ApplicationStatus';
import { COLOR } from '@constants/Color';
import { GlobalFormat } from '@constants/Format';
import LaunchIcon from '@mui/icons-material/Launch';
import { Stack } from '@mui/material';
import { FC, useState } from 'react';
import NumberFormat from 'react-number-format';
import { formatDate } from 'src/util/commonUtil';
import styled from 'styled-components';
import LoanRequestModal from './LoanRequestModal';

const LoanItem: FC<ILoanListInterface> = (data) => {
  const [requestModalShow, isRequestModalShow] = useState(false);
  const { mutate } = useLoanList();
  const deleteHandle = async () => {
    await deleteLoan(data.applSeq);
    mutate();
  };

  const 대출구분코드 = 'B60230';
  return (
    <Container>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <BigTitleFont>
          <CommonCodeName code={data.loanKindCd} grpCd={대출구분코드} />
          <VerticalBlank width={10} />
          <ApplStatusCdTemplate status={data.applStatusCd} />
        </BigTitleFont>
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
          <HandleIcon
            onClick={(e) => {
              e.stopPropagation();
              isRequestModalShow(true);
            }}>
            <DetailShowFont>상세보기</DetailShowFont>
            <div style={{ marginTop: 3 }}>
              <LaunchIcon />
            </div>
          </HandleIcon>
          {data.applStatusCd === ApplicationStatus.임시저장 && <CommonDeleteIcon deleteHandle={deleteHandle} />}
        </Stack>
      </Stack>
      <Row>
        <TitleFont>대출일자</TitleFont>
        <ContentFont>{formatDate(data.loanYmd, GlobalFormat.DATE_DASH)}</ContentFont>
        <TitleFont2>대출금액</TitleFont2>
        <ContentFont2>
          <NumberFormat value={data.loanAmt ?? 0} displayType={'text'} thousandSeparator={true} suffix={' 원'} />
        </ContentFont2>
      </Row>
      <Row>
        <TitleFont>상환금액</TitleFont>
        <ContentFont>
          <NumberFormat value={data.loanAmt ?? 0} displayType={'text'} thousandSeparator={true} suffix={' 원'} />
        </ContentFont>
        <TitleFont2>원금잔액</TitleFont2>
        <ContentFont2>
          <NumberFormat value={data.remainAmt ?? 0} displayType={'text'} thousandSeparator={true} suffix={' 원'} />
        </ContentFont2>
      </Row>
      <Row>
        <TitleFont>이자율</TitleFont>
        <ContentFont>{`${data.intRate}`}</ContentFont>
        <TitleFont2>최종납입일</TitleFont2>
        <ContentFont2>{formatDate(data.lastRepayDate, GlobalFormat.DATE_DASH)}</ContentFont2>
      </Row>
      <Row>
        <TitleFont>신청일자</TitleFont>
        <ContentFont>{formatDate(data.applYmd, GlobalFormat.DATE_DASH)}</ContentFont>
        <TitleFont2>결재완료일</TitleFont2>
        <ContentFont2>{formatDate(data.agreeYmd, GlobalFormat.DATE_DASH)}</ContentFont2>
      </Row>
      <Row>
        <TitleFont>은행</TitleFont>
        <ContentFont>
          <CommonCodeName code={`${data.bankCd}`} grpCd='H30001' />
        </ContentFont>
        <TitleFont2>계좌번호</TitleFont2>
        <ContentFont2>{`${data.accNo}`}</ContentFont2>
      </Row>
      <Row>
        <TitleFont>예금주</TitleFont>
        <ContentFont>{`${data.accNm}`}</ContentFont>
        <TitleFont2>신청사번</TitleFont2>
        <ContentFont2>{data?.sabun}</ContentFont2>
      </Row>
      {data.applSeq && requestModalShow && (
        <LoanRequestModal
          authPg={data.applStatusCd === ApplicationStatus.임시저장 ? '신청' : '상세'}
          open={requestModalShow}
          onClose={() => isRequestModalShow(false)}
          applSeq={data.applSeq}
        />
      )}
    </Container>
  );
};

const BigTitleFont = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 17px;
  font-weight: 700;
  color: ${COLOR.RED_DARKEN};
`;

const Container = styled.div`
  background: ${COLOR.BG_LIGHTWT};
  padding: 15px;
`;

const Row = styled.div`
  margin: 4px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const HandleIcon = styled.span`
  font-size: 14px;
  padding: 5px 5px 5px 5px;
  margin-right: -5px;
  border-radius: 10px;
  color: ${COLOR.RED};
  display: flex;
  align-items: center;
  :active {
    background-color: ${COLOR.ACTIVE_RED};
  }
`;

const TitleFont = styled.span`
  color: ${COLOR.GRAY};
  font-size: 12px;
  flex: 38;
  padding: 1px;
`;

const TitleFont2 = styled(TitleFont)`
  flex: 50;
`;

const ContentFont = styled.div`
  font-size: 12px;
  color: ${COLOR.LIGHT_GRAY};
  flex: 60;
`;

const ContentFont2 = styled(ContentFont)`
  flex: 69;
`;

const DetailShowFont = styled.span`
  font-weight: 600;
`;

export default LoanItem;
