import React, { FC, useState } from 'react';
import DiseaseName from './DiseaseName';
import FamilyCodeSelect from './FamilyCodeSelect';
import LoanAmt from './LoanAmt';
import { ILoanRegistSubProps } from './LoanRequestModal';
import TargetName from './TargetName';

const FamilyDisease: FC<ILoanRegistSubProps> = ({ loanKindCd }) => {
  const [famCd, setFamCd] = useState('');
  return (
    <>
      <LoanAmt />
      <FamilyCodeSelect onChange={setFamCd} />
      {famCd && <TargetName famCd={famCd} />}
      <DiseaseName />
    </>
  );
};

export default FamilyDisease;
