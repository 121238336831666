import { Box } from '@mui/material';
import styled, { keyframes } from 'styled-components';

const SlideUpAnimation = (animationHeight: number | string) => keyframes`
    from {
        max-height: 0;
        /* padding: 0; */
    }
    to {
        /* padding: 0 15px 25px; */
        max-height: ${animationHeight};
    }
`;
const SlideDownAnimation = (animationHeight: number | string) => keyframes`
    from {
        max-height: ${animationHeight};
        /* padding: 0 15px 25px; */
    }
    to {
        /* padding: 0; */
        /* max-height: 0; */
    }
`;

const CommonModalContainer = styled(Box)<{ animationHeight: number | string } & ICommonInput>`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow-y: auto;
  max-height: 0;
  outline: none;
  padding: 0 15px;
  box-shadow: 0px -7.5px 14px 0 rgba(0, 0, 0, 0.5);
  animation: ${({ active, animationHeight }) =>
      active ? SlideUpAnimation(animationHeight) : SlideDownAnimation(animationHeight)}
    0.3s forwards ease-in-out;
`;
export default CommonModalContainer;
