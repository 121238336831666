import { COLOR } from '@constants/Color';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
interface IAccordionProps {
  children: ReactNode | ReactNode[];
  forceOpen?: boolean;
}
const Accordion: FC<IAccordionProps> = ({ children, forceOpen = false }) => {
  const [active, isActive] = useState(forceOpen);
  useEffect(() => {
    isActive(forceOpen);
  }, [forceOpen]);
  return (
    <>
      <AccordionContainer active={active}>{children}</AccordionContainer>
      <ArrowIcon active={active} onClick={() => isActive((prev) => !prev)} />
    </>
  );
};

export default Accordion;

export const ArrowIcon = styled.span<ICommonInput>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12px;
  margin: 5px 0;
  ::before {
    content: '';
    width: 20px;
    height: 4px;
    border-radius: 2px;
    position: absolute;
    background: ${COLOR.GRAY};
    transform-origin: right;
    transition: all 0.3s ease-in-out;
    transform: translateX(-8.2px) rotateZ(12deg);
    ${({ active }) =>
      active &&
      css`
        transform: translateX(-8.2px) rotateZ(0deg);
      `};
  }
  ::after {
    content: '';
    width: 20px;
    height: 4px;
    border-radius: 2px;
    position: absolute;
    background: ${COLOR.GRAY};
    transform-origin: left;
    transition: all 0.3s ease-in-out;
    transform: translateX(8.2px) rotateZ(-12deg);
    ${({ active }) =>
      active &&
      css`
        transform: translateX(8.2px) rotateZ(0deg);
      `};
  }
`;

const SlideUpAnimation = keyframes`
from {
    max-height: 1000px;
}
to {
    max-height: 0px;
}
`;
const SlideDownAnimation = keyframes`
from {
    max-height: 0px;
}
to {
    max-height: 1000px;
}
`;
const AccordionContainer = styled.div<ICommonInput>`
  white-space: pre;
  height: auto;
  color: ${COLOR.LIGHT_GRAY};
  overflow: hidden;
  animation: ${({ active }) => (active ? SlideDownAnimation : SlideUpAnimation)} 0.2s ease-in-out forwards;
`;
