import { useDiseaseInfo } from '@apis/disease';
import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonInput from '@components/common/atoms/CommonInput';
import { FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { CommonDiseaseItemInterface } from './AddDiseaseItem';
import { diseaseFormAtom } from './DiseaseRequestModal';
interface IDiseaseAmountProps extends CommonDiseaseItemInterface {}

const DiseaseAmount: FC<IDiseaseAmountProps> = ({ field }) => {
  const { data: diseaseInfoList } = useDiseaseInfo();
  const diseaseInfo = useMemo(() => diseaseInfoList?.[0], [diseaseInfoList]);

  const form = useRecoilValue(diseaseFormAtom);
  const handleChange = (value: string) => {
    var regex = /[^0-9]/g;
    var amount: number = parseInt(value.replace(regex, ''));
    const limitAmt = parseInt(diseaseInfo?.limitAmt ?? '0');
    const rate = parseInt(diseaseInfo?.rate ?? '0');
    if (amount > 0 && rate) {
      let payMon = Math.round(((amount * rate) / 100) * 0.1) * 10;
      if (payMon > limitAmt) {
        payMon = limitAmt;
      }
      form?.setFields([
        {
          name: ['list', field.name, 'payMon'],
          value: payMon,
        },
      ]);
    }
  };
  return (
    <>
      <CommonFormField name={[field.name, 'conMon']}>
        <CommonInput
          label={'납부한금액(본인부담)'}
          pattern={'money'}
          onInput={({ target }: any) => handleChange(target?.value)}
        />
      </CommonFormField>
      <CommonFormField name={[field.name, 'payMon']}>
        <CommonInput label={'예상지원금액'} pattern={'money'} readOnly />
      </CommonFormField>
    </>
  );
};

export default DiseaseAmount;
