import { COLOR } from '@constants/Color';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import React, { FC } from 'react';
import styled from 'styled-components';
import { useGlobalConfirm } from './CommonConfirm';

interface IFileUploadInfoProps {
  applStatusCd?: string;
  onClick: () => void;
}
const FileUploadInfo: FC<IFileUploadInfoProps> = ({ applStatusCd, onClick }) => {
  const { open: confirmOpen, close: confirmClose } = useGlobalConfirm();
  return (
    <>
      {(applStatusCd == '11' || !applStatusCd) && (
        <FileUploadInfoContainer
          onClick={() => {
            confirmOpen({
              title: '첨부파일',
              contents:
                `개인정보보호법에 따라 주민등록번호의 수집 및 처리에 제한이 있습니다. 본 신청과 관련없는 개인정보(예: 가족의 주민등록번호)는 삭제 후 첨부해 주시기 바랍니다.  \n\n` +
                `JPG, PNG, GIF, PDF, JPEG(JFIF, PJP, PJPEG) 형식의 파일만 업로드가 가능합니다.`,
              okText: '확인',
              onOkPress: () => {
                confirmClose();
                onClick();
              },
              cancelVisible: false,
            });
          }}>
          <FileUploadRoundedIcon color={'primary'} style={{ fontSize: 30 }} />
          <FileUploadText>터치하여 첨부파일을 업로드 해주세요.</FileUploadText>
        </FileUploadInfoContainer>
      )}
    </>
  );
};
const FileUploadInfoContainer = styled.div`
  overflow: hidden;
  width: 100%;
  min-width: 160px;
  height: 120px;
  border-radius: 5px;
  border-width: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
  border-style: dashed;
  border-width: 3px;
  border-color: rgba(0, 0, 0, 0.5);
  padding: 15px;
`;

const FileUploadText = styled.div`
  font-size: 1em;
  color: ${COLOR.GRAY};
  text-align: center;
`;

export default FileUploadInfo;
