import { useDtlCodeList } from '@apis/vacation';
import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonInputHidden from '@components/common/atoms/CommonInputHidden';
import CommonSelect from '@components/common/molecules/CommonSelect';
import { FormInstance } from 'rc-field-form';
import React, { FC, useEffect, useMemo } from 'react';

const DtlCodeSelect: FC<{ gntGubunCd?: string; searchGntCd?: string; form?: FormInstance<any> }> = ({
  gntGubunCd,
  searchGntCd,
  form,
}) => {
  const { data: dtlCodeList } = useDtlCodeList(gntGubunCd, searchGntCd);
  const isVisible = useMemo(() => ['1', '12', '13', '17', '19', '20', '24'].includes(gntGubunCd ?? ''), [gntGubunCd]);
  useEffect(() => {
    if (!isVisible) {
      form?.setFieldsValue({ gntCd: dtlCodeList?.[0]?.code });
    }
  }, [dtlCodeList, isVisible, form]);
  return (
    <CommonFormField name={'gntCd'} rules={[{ required: true }]}>
      {isVisible ? (
        <CommonSelect
          title={'선택'}
          options={dtlCodeList?.map((item) => ({
            label: item.codeNm,
            value: item.code,
            data: item,
          }))}
        />
      ) : (
        <CommonInputHidden />
      )}
    </CommonFormField>
  );
};

export default DtlCodeSelect;
