import { ICommonRequestInfoInterface } from '@apis/common';
import { IVacationPostParam } from '@apis/vacation';
import { postCancelVacation, useVacationCancelDetail, useVacationCancleList } from '@apis/vacationCancle';
import CommonForm from '@components/common/atoms/CommonForm';
import { currentCommonMessageAtom } from '@components/common/atoms/CommonMessage';
import { useUserInfo } from '@components/common/molecules/CommonUserInfo';
import CommonRequestModal from '@components/common/organisms/CommonRequestModal';
import { GlobalFormat } from '@constants/Format';
import moment from 'moment';
import Form from 'rc-field-form';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import CancelDtlCodeSelect from './CancelDtlCodeSelect';
import CancelReason from './CancelReason';
import CancelRequestRange from './CancelRequestRange';
import RegisteredVacationListTemplate from './RegisteredVacationListTemplate';

interface IVacationCancelRequestModalProps {
  onClose: () => void;
  open: boolean;
  searchApplSeq?: string;
  searchApplCd?: string;
  gntGubunCd?: string;
  codeName?: string;
  authPg: AUTH_TYPE;
}
// 0: {searchApplCd: "22",            codeNm: "연차휴가",            gntGubunCd: "1"}
// 1: {searchApplCd: "38",            codeNm: "태아검진",            gntGubunCd: "10"}
// 2: {searchApplCd: "26",            codeNm: "보상휴가",            gntGubunCd: "11"}
// 3: {searchApplCd: "40",            codeNm: "난임휴가",            gntGubunCd: "12"}
// 4: {searchApplCd: "24",            codeNm: "출산휴가",            gntGubunCd: "13"}
// 5: {searchApplCd: "61",            codeNm: "배우자출산휴가",        gntGubunCd: "14"}
// 6: {searchApplCd: "62",            codeNm: "교육",               gntGubunCd: "16"}
// 7: {searchApplCd: "36",            codeNm: "기타",               gntGubunCd: "17"}
// 8: {searchApplCd: "34",            codeNm: "출장파견",            gntGubunCd: "19"}
// 9: {searchApplCd: "65",            codeNm: "공가(훈련)",          gntGubunCd: "20"}
// 10:{searchApplCd: "30",            codeNm: "공가(업무상 질병/상해)", gntGubunCd: "22"}
// 11:{searchApplCd: "28",            codeNm: "병가",               gntGubunCd: "23"}
// 12:{searchApplCd: "67",            codeNm: "공가(기타)",          gntGubunCd: "32"}
// 13:{searchApplCd: "600",           codeNm: "가족돌봄휴가",         gntGubunCd: "60"}
// 14:{searchApplCd: "70",            codeNm: "보건휴가",            gntGubunCd: "70"}
// 15:{searchApplCd: "32",            codeNm: "경조휴가",            gntGubunCd: "9"}
const VacationCancelRequestModal: FC<IVacationCancelRequestModalProps> = ({
  open,
  onClose,
  searchApplSeq,
  searchApplCd,
  gntGubunCd,
  codeName,
  authPg,
}) => {
  const [form] = Form.useForm();
  const initialValues = {};
  const setMessage = useSetRecoilState(currentCommonMessageAtom);
  const { data } = useVacationCancelDetail(searchApplSeq);
  const detailData = useMemo(() => data?.[0], [data]);
  const [bApplSeq, setBApplSeq] = useState<string>();
  const [bGntCd, setBGntCd] = useState<string>();
  const [bSYmd, setBSYmd] = useState<string>(detailData?.bSYmd || '');
  const [bEYmd, setBEYmd] = useState<string>(detailData?.bEYmd || '');
  const [bCloseDay, setBCloseDay] = useState<string>(detailData?.bCloseDay || '');
  const [commonInfo, setCommonInfo] = useState<ICommonRequestInfoInterface>();
  const { mutate } = useVacationCancleList();
  const { sabun } = useUserInfo();

  const generateParam = (data: any) => {
    const params = {
      sabun: sabun,
      gntCd: bGntCd,
      sYmd: moment().format(GlobalFormat.DATE_API),
      applSeq: commonInfo?.applSeq,
      bApplSeq: bApplSeq,
      gntReqReason: data.gntReqReason,
    };
    return params;
  };

  useEffect(() => {
    if (detailData) {
      form.setFieldsValue({ ...detailData });
    }
  }, [detailData, searchApplSeq, form]);

  const handleSubmit = async (params: IVacationPostParam) => {
    try {
      await postCancelVacation({ ...params });
      mutate();
      onClose();
      setMessage({
        open: true,
        message: '신청이 완료되었습니다.',
        type: 'success',
      });
    } catch (e: any) {
      setMessage({
        open: true,
        message: e?.response?.data?.message,
        type: 'error',
      });
    }
  };
  return (
    <>
      <CommonRequestModal
        open={open}
        onClose={onClose}
        title={'취소신청 - ' + codeName || ''}
        authPg={authPg}
        applSeq={searchApplSeq}
        onSuccessRequestCommonInfo={setCommonInfo}
        applCd={searchApplCd}
        onSubmit={async () => {
          await form.validateFields();
          form.submit();
        }}
        onTemporary={async () => {
          await form.validateFields();
          form.submit();
        }}
        onCancelDone={() => {
          mutate();
        }}>
        <CommonForm
          form={form}
          onFinish={(data) => {
            const params = generateParam(data);
            handleSubmit(params);
          }}
          readOnly={authPg === '상세'}
          onValuesChange={(changedValues: any, values: any) => {}}
          initialValues={initialValues}>
          <ModalContainer>
            {!searchApplSeq && (
              <RegisteredVacationListTemplate
                gntGubunCd={gntGubunCd}
                searchApplCd={searchApplCd}
                onSelect={(data) => {
                  form.setFieldsValue({ gntCd: data.gntNm });
                  setBSYmd(data.sYmd);
                  setBEYmd(data.eYmd);
                  setBCloseDay(data.closeDay);
                  setBApplSeq(data.applSeq);
                  setBGntCd(data.gntCd);
                }}
              />
            )}
            <CancelDtlCodeSelect gntGubunCd={gntGubunCd} searchApplCd={searchApplCd} form={form} />
            <CancelRequestRange bSYmd={bSYmd} bEYmd={bEYmd} bCloseDay={bCloseDay} />
            <CancelReason />
          </ModalContainer>
        </CommonForm>
      </CommonRequestModal>
    </>
  );
};

const ModalContainer = styled.div`
  padding: 15px 0;
  display: grid;
  gap: 16px;
  overflow-y: scroll;
  position: relative;
`;

export default VacationCancelRequestModal;
