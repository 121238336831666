import { COLOR } from '@constants/Color';
import { Paper } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';
interface IAdditionalDocumentInfoProps {
  prDoc?: string;
}
const AdditionalDocumentInfo: FC<IAdditionalDocumentInfoProps> = ({ prDoc }) => {
  // const param = useRecoilValue(FamilyEventRequestFormAtom);
  return prDoc ? (
    <div style={{ marginBottom: 12 }}>
      <DocumentLabel>증빙서류</DocumentLabel>
      <DocumentsContainer style={{ background: COLOR.BG_WHITE }}>
        <DocumentFont>{prDoc}</DocumentFont>
      </DocumentsContainer>
    </div>
  ) : null;
};

const DocumentsContainer = styled(Paper)`
  padding: 8px 18px;
`;
const DocumentLabel = styled.div`
  margin-top: 4px;
  color: ${COLOR.GRAY};
  font-size: 14px;
  margin-bottom: 6px;
`;

const DocumentFont = styled.div`
  font-size: 12px;
`;

export default AdditionalDocumentInfo;
