import { IApprovalRequestParam } from '@apis/approval';
import CommonContainer from '@components/common/atoms/CommonContainer';
import HorizontalBlank from '@components/common/atoms/HorizontalBlank';
import { useHeaderStore } from '@stores/headerStore';
import { useEffect, useState } from 'react';
import { atom } from 'recoil';
import ApprovalList from './templates/ApprovalList';
import ApprovalSearchForm from './templates/ApprovalSearchForm';

export const currentApprovalListParamAtom = atom<IApprovalRequestParam | null>({
  key: '@Approval/listParam',
  default: null,
});

const ApprovalPage = () => {
  const [param, setParam] = useState<IApprovalRequestParam>();
  const { setConfig } = useHeaderStore();
  useEffect(() => {
    setConfig({ title: '결재함' });
  }, [setConfig]);

  return (
    <CommonContainer style={{ padding: 15 }}>
      <ApprovalSearchForm setParam={setParam} />
      <HorizontalBlank height={20} />
      <ApprovalList {...param} />
    </CommonContainer>
  );
};

export default ApprovalPage;
