import { deleteDisease, IDiseaseListInterface, useDiseaseList } from '@apis/disease';
import ApplStatusCdTemplate from '@components/common/atoms/ApplStatusCdTemplate';
import CommonDeleteIcon from '@components/common/atoms/CommonDeleteIcon';
import { ApplicationStatus } from '@constants/ApplicationStatus';
import { COLOR } from '@constants/Color';
import { GlobalFormat } from '@constants/Format';
import LaunchIcon from '@mui/icons-material/Launch';
import { Stack } from '@mui/material';
import { FC, useState } from 'react';
import NumberFormat from 'react-number-format';
import { formatDate } from 'src/util/commonUtil';
import styled from 'styled-components';
import DiseaseRequestModal from './DiseaseRequestModal';

const DiseaseItem: FC<IDiseaseListInterface> = (data) => {
  const [requestModalShow, isRequestModalShow] = useState(false);
  const { mutate } = useDiseaseList();
  const deleteHandle = async () => {
    await deleteDisease(data.applSeq);
    mutate();
  };
  return (
    <Container>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <ApplStatusCdTemplate status={data.applStatusCd} />
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
          <HandleIcon
            onClick={(e) => {
              e.stopPropagation();
              isRequestModalShow(true);
            }}>
            <DetailShowFont>상세보기</DetailShowFont>
            <div style={{ marginTop: 3 }}>
              <LaunchIcon />
            </div>
          </HandleIcon>
          {data.applStatusCd === ApplicationStatus.임시저장 && <CommonDeleteIcon deleteHandle={deleteHandle} />}
        </Stack>
      </Stack>
      <Row>
        <TitleFont>신청금액</TitleFont>
        <ContentFont>
          <NumberFormat value={data.conMon ?? 0} displayType={'text'} thousandSeparator={true} suffix={' 원'} />
        </ContentFont>
        <TitleFont>지급금액</TitleFont>
        <ContentFont>
          <NumberFormat value={data.payMon ?? 0} displayType={'text'} thousandSeparator={true} suffix={' 원'} />
        </ContentFont>
      </Row>
      <Row>
        <TitleFont>신청일자</TitleFont>
        <ContentFont>{formatDate(data.applYmd, GlobalFormat.DATE_DASH)}</ContentFont>
        <TitleFont>결재완료일</TitleFont>
        <ContentFont>{formatDate(data.agreeYmd, GlobalFormat.DATE_DASH)}</ContentFont>
      </Row>
      <Row>
        <TitleFont>신청사번</TitleFont>
        <ContentFont>{data?.sabun}</ContentFont>
        <TitleFont></TitleFont>
        <ContentFont></ContentFont>
      </Row>
      {data.applSeq && requestModalShow && (
        <DiseaseRequestModal
          authPg={data.applStatusCd === ApplicationStatus.임시저장 ? '신청' : '상세'}
          open={requestModalShow}
          onClose={() => isRequestModalShow(false)}
          applSeq={data.applSeq}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  background: ${COLOR.BG_LIGHTWT};
  padding: 15px;
`;

const Row = styled.div`
  margin: 4px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const HandleIcon = styled.span`
  font-size: 16px;
  padding: 5px 5px 5px 5px;
  margin-right: -5px;
  border-radius: 10px;
  color: ${COLOR.RED};
  display: flex;
  align-items: center;
  :active {
    background-color: ${COLOR.ACTIVE_RED};
  }
`;

const TitleFont = styled.span`
  color: ${COLOR.GRAY};
  font-size: 12px;
  flex: 47;
  padding: 1px;
`;
const ContentFont = styled.div`
  font-size: 12px;
  color: ${COLOR.LIGHT_GRAY};
  flex: 53;
`;

const DetailShowFont = styled.span`
  font-weight: 600;
`;

export default DiseaseItem;
