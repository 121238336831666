import { IFamilyEventDetailInterface } from '@apis/familyEvent';
import { COLOR } from '@constants/Color';
import { Paper } from '@mui/material';
import React, { FC } from 'react';
import styled from 'styled-components';
interface IFamilyApprovalResultProps {
  detailData?: IFamilyEventDetailInterface;
}
const FamilyApprovalResult: FC<IFamilyApprovalResultProps> = ({ detailData }) => {
  return (
    <Container>
      <Row style={{ fontSize: 14, marginBottom: 10, fontWeight: 600 }}>결재내용</Row>
      <Row>
        <TitleFont>- 급여반영월:</TitleFont>
        <ContentFont>{detailData?.payYmd}</ContentFont>
      </Row>
    </Container>
  );
};

const Container = styled(Paper)`
  background-color: ${COLOR.BG_GRAY} !important;
  padding: 10px;
  margin-bottom: 12px;
`;

const TitleFont = styled.span`
  font-size: 12px;
`;

const Row = styled.div`
  font-size: 12px;
  margin: 4px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
const ContentFont = styled.div`
  font-size: 12px;
  color: ${COLOR.RED};
  flex: 53;
  padding-left: 3px;
  font-weight: bold;
`;

export default FamilyApprovalResult;
