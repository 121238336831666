import React, { FC, ReactNode, useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
interface ICommonVibe {
  iserror?: boolean;
  children: ReactNode;
}
const CommonVibe: FC<ICommonVibe> = ({ iserror, children }) => {
  const [animationStart, isAnimationStart] = useState(false);
  useEffect(() => {
    isAnimationStart(!!iserror);
  }, [iserror]);
  useEffect(() => {
    setTimeout(() => isAnimationStart(false), 1000);
  }, [animationStart]);
  return <VibeContainer animationStart={animationStart}>{children}</VibeContainer>;
};

const vibe = keyframes`
${Array(100)
  .fill(null)
  .map((_, i) => `${i}% {transform: translateY(${i % 2 === 0 ? -2 : 2}px)} `)
  .join('')}
`;

const VibeContainer = styled.div<{ animationStart: boolean }>`
  width: 100%;
  ${({ animationStart }) =>
    animationStart &&
    css`
      animation: ${vibe} 0.2s linear forwards;
    `}
`;
export default CommonVibe;
