import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonTextarea from '@components/common/atoms/CommonTextarea';
import { FC } from 'react';
import { CommonDiseaseItemInterface } from './AddDiseaseItem';

const RequestMemo: FC<CommonDiseaseItemInterface> = ({ field }) => {
  return (
    <>
      <CommonFormField name={[field.name, 'memo']}>
        <CommonTextarea label='요청사항' />
      </CommonFormField>
    </>
  );
};

export default RequestMemo;
