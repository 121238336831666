import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonTextarea from '@components/common/atoms/CommonTextarea';
import { FC } from 'react';

const RequestReason: FC = () => {
  return (
    <>
      <CommonFormField name={'gntReqReson'}>
        <CommonTextarea label='신청사유' />
      </CommonFormField>
    </>
  );
};

export default RequestReason;
