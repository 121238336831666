import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonInput from '@components/common/atoms/CommonInput';
import CommonSelect from '@components/common/molecules/CommonSelect';
import React, { FC } from 'react';
import { useBankCodeList } from 'src/util/commonCode';
interface IFamilyEventBankGroupProps {
  bankType?: string;
}
const FamilyEventBankGroup: FC<IFamilyEventBankGroupProps> = ({ bankType }) => {
  const { data: bankCodeList } = useBankCodeList();
  const HR담당자계좌 = '06',
    직접입력 = '99';

  return bankType !== HR담당자계좌 ? (
    <>
      <CommonFormField
        name='bankCd'
        rules={[
          {
            required: true,
          },
        ]}>
        <CommonSelect
          readOnly={bankType !== 직접입력}
          title='은행명'
          options={bankCodeList?.map((item) => ({
            label: item.codeNm,
            value: item.code,
          }))}
        />
      </CommonFormField>
      <CommonFormField
        name='accNo'
        rules={[
          {
            required: true,
          },
        ]}>
        <CommonInput label='계좌번호' readOnly={bankType !== 직접입력} type={'number'} />
      </CommonFormField>
      <CommonFormField
        name='accNm'
        rules={[
          {
            required: true,
          },
        ]}>
        <CommonInput label='예금주' readOnly={bankType !== 직접입력} />
      </CommonFormField>
    </>
  ) : null;
};

export default FamilyEventBankGroup;
