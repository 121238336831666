import ApprovalPage from '@pages/approval/ApprovalPage';
import DiseasePage from '@pages/disease/DiseasePage';
import FamilyEventPage from '@pages/family-event/FamilyEventPage';
import HomePage from '@pages/home/HomePage';
import LoanPage from '@pages/loan/LoanPage';
import PayslipPage from '@pages/payslip/PayslipPage';
import ProofRequestPage from '@pages/proof-request/ProofRequestPage';
import StudentFundsPage from '@pages/student-funds/StudentFundsPage';
import VacationCancelPage from '@pages/vacation-cancel/VacationCancelPage';
import VacationPage from '@pages/vacation/VacationPage';
import { useRoutes } from 'react-router-dom';

export enum EnumRouteUrl {
  HOME = '/',
  PROOF = '/proof',
  FAMILY_EVENT = '/familyEvent',
  STUDENT_FUNDS = '/studentFunds',
  VACATION = '/vacation',
  VACATION_CANCEL = '/vacationCancel',
  APPROVAL = '/approval',
  DISEASE = '/disease',
  PAYSLIP = '/payslip',
  LOAN = '/loan',
}
const Routers = () => {
  let routes = useRoutes([
    { path: EnumRouteUrl.HOME, element: <HomePage /> },
    { path: EnumRouteUrl.PROOF, element: <ProofRequestPage /> },
    { path: EnumRouteUrl.FAMILY_EVENT, element: <FamilyEventPage /> },
    { path: EnumRouteUrl.VACATION, element: <VacationPage /> },
    { path: EnumRouteUrl.VACATION_CANCEL, element: <VacationCancelPage /> },
    { path: EnumRouteUrl.STUDENT_FUNDS, element: <StudentFundsPage /> },
    { path: EnumRouteUrl.APPROVAL, element: <ApprovalPage /> },
    { path: EnumRouteUrl.DISEASE, element: <DiseasePage /> },
    { path: EnumRouteUrl.PAYSLIP, element: <PayslipPage /> },
    { path: EnumRouteUrl.LOAN, element: <LoanPage /> },
  ]);
  return routes;
};

export default Routers;
