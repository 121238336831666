import { Alert, AlertColor, Portal, Snackbar } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { atom, useRecoilState } from 'recoil';
interface ICommonMessageProps {
  open: boolean;
  message?: ReactNode;
  type: AlertColor;
}
export const currentCommonMessageAtom = atom<ICommonMessageProps | null>({
  key: 'commonMessageAtom',
  default: null,
});

const CommonMessage: FC = () => {
  const [config, setConfig] = useRecoilState(currentCommonMessageAtom);
  return (
    <Portal>
      <Snackbar
        open={config?.open}
        autoHideDuration={4000}
        onClose={() => setConfig(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity={config?.type} sx={{ width: '100%' }} elevation={9}>
          {config?.message}
        </Alert>
      </Snackbar>
    </Portal>
  );
};

// export const useCommonMessage = () => {
//   const setMessageProps = useSetRecoilState(currentCommonMessageAtom);
//   return (props: ICommonMessageProps) => {
//     setMessageProps(props);
//   };
// };

export default CommonMessage;
