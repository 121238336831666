import { useUserInfo } from '@components/common/molecules/CommonUserInfo';
import { GlobalFormat } from '@constants/Format';
import moment from 'moment';
import queryString from 'query-string';
import { axiosInstance, useRequest } from 'src/axios/fetcher';
import { formatDate } from 'src/util/commonUtil';

export interface ICommonRequestInfoInterface {
  applCd: string;
  applNm: string;
  agreeYn: string;
  applTitle: string;
  recevYn: string;
  printYn: string;
  fileYn: string;
  fileEssentialYn: string;
  orgLevelCd: string;
  orgLevelYn: string;
  appPathYn: string;
  etcNoteYn: string;
  etcNote: string;
  etcNoteFileCnt: string;
  applSeq: string;
  sabun: string;
  agreeSabun: string;
  applTypeCd: string;
  applTypeCdNm: string;
  agreeSeq: string;
  agreeName: string;
  agreeEmpAlias: string;
  agreeJikchakNm: string;
  agreeJikchakCd: string;
  agreeJikgubNm: string;
  agreeJikgubCd: string;
  agreeJikweeNm: string;
  agreeJikweeCd: string;
  agreeOrgNm: string;
  agreeOrgCd: string;
  name: string;
  jikchakNm: string;
  jikweeNm: string;
  orgNm: string;
  orgCd: string;
}

interface IEmailToSabunInterface {
  key: string;
  version: string;
  createTime: string;
  updateTime: string;
  createUser: string;
  updateUser: string;
  workingStatus: string;
  sabun: string;
  userId: string;
  englishName: string;
  emailAddress: string;
  userStatus: string;
  svcArea: string;
  facilityCode: string;
  orgNm: string;
  jobNm: string;
  name: string;
}

interface IApprovalInfoInterface {
  insertAgreeUser: IApprovalAgreeUserItem[];
  orgLevelCd: string;
  searchSabun: string;
  searchApplCd: string;
}

interface IApprovalAgreeUserItem {
  jikwee: string;
  agreeSabun: string;
  org: string;
  jikchak: string;
  agreeSeq: number;
  applTypeCd: string;
  gubun: string;
  name: string;
}

interface IApprovalAgreeAcceptUserItem {
  agreeSabun: string;
  agreeName: string;
  agreeSeq: string;
  applTypeCd: string;
  applTypeCdNm: string;
  pathSeq: string;
  agreeStatusCdNm: string;
  agreeStatusCd: string;
  agreeTime: string;
  gubun: string;
  agreeOrgNm: string;
  agreeJikweeNm: string;
  agreeJikchakNm: string;
  title: string;
  applStatusCd: string;
  fileSeq: string;
}

export const useCommonRequestInfo = (
  params: {
    searchApplCd?: string | null;
    searchApplSeq?: string | null;
  } | null,
) => {
  return useRequest<ICommonRequestInfoInterface>(
    params
      ? {
          url: `/comm/control/myhr/common/searchPopUiInfo`,
          params,
        }
      : null,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );
};

export const useEmailToSabun = ({ email, name }: { email?: string; name?: string }) => {
  return useRequest<IEmailToSabunInterface>(
    email
      ? {
          url: `/comm/control/auth/sabun`,
          params: { email, name },
        }
      : null,
  );
};

export const getSearchEname = async ({ email, name }: { email?: string; name?: string }) => {
  const queryUrl = queryString.stringifyUrl({
    url: `/comm/control/auth/sabun`,
    query: { email, name },
  });
  return await axiosInstance.get(queryUrl);
};

export const usePrivacyAgreement = () => {
  const { sabun } = useUserInfo();
  return useRequest<{ privacyAgreementYn: string }>(
    sabun
      ? {
          url: `/comm/control/auth/privacyAgreement`,
          params: { sabun },
        }
      : null,
  );
};

export interface IApprovalInfoRequestInterface {
  searchApplSeq?: string;
  searchApplCd?: string;
  searchSabun?: string;
  orgLevelCd?: string;
  recevYn?: string;
  authPg?: AUTH_TYPE;
}

export const useApprovalInfo = (params: IApprovalInfoRequestInterface | null) => {
  const { searchApplCd, searchSabun, orgLevelCd, recevYn } = params ?? {};
  return useRequest<IApprovalInfoInterface>(
    searchApplCd && searchSabun && orgLevelCd && recevYn
      ? {
          url: `comm/control/myhr/common/searchApprovalInfo`,
          params: { searchApplCd, searchSabun, orgLevelCd, recevYn },
        }
      : null,
  );
};
export const useApprovalAcceptInfo = (searchApplSeq?: string) => {
  return useRequest<IApprovalAgreeAcceptUserItem[]>(
    searchApplSeq
      ? {
          url: `comm/control/myhr/common/vacationDetailAprvedList`,
          params: { searchApplSeq },
        }
      : null,
  );
};

interface CommonDetailInfoRequestInterface {
  searchApplSeq?: string;
  searchSabun?: string;
  searchApplSabun?: string;
  searchApplCd?: string;
}

interface CommonDetailUiInfo {
  applCd: string;
  applNm: string;
  bizCd: string;
  agreeYn: string;
  applSmsYn: string;
  applMailYn: string;
  agreeSmsYn: string;
  agreeMailYn: string;
  prgCd: string;
  prgPath: string;
  agreePrgCd: string;
  agreePrgPath: string;
  visualYn: string;
  useYn: string;
  note1: string;
  note2: string;
  note3: string;
  numNote: string;
  seq: string;
  memo: string;
  title: string;
  applTitle: string;
  agreeTitle: string;
  alarmYn: string;
  recevYn: string;
  comboViewYn: string;
  detailPrgCd: string;
  detailPrgPath: string;
  personPrintYn: string;
  printYn: string;
  fileYn: string;
  fileEssentialYn: string;
  fileUpdateYn: string;
  orgLevelCd: string;
  orgLevelYn: string;
  appPathYn: string;
  prtRsc: string;
  etcNoteYn: string;
  etcNote: string;
  etcNoteFileSeq: string;
  etcNoteFileCnt: number;
}
interface CommonDetailUserInfo {
  sabun: string;
  name: string;
  jikchakNm: string;
  jikweeNm: string;
  orgNm: string;
  orgCd: string;
  applYmd: string;
}

interface CommonDetailCancelButton {
  cancel: string;
}

interface CommonDetailAgreeSabunYn {
  agreeSabun: string;
  applTypeCd: string;
  agreeYn: YN;
}

interface CommonDetailApplMasterInfo {
  applSeq: string;
  title: string;
  applYmd: string;
  applCd: string;
  applSabun: string;
  applInSabun: string;
  applStatusCd: string;
  applStatusCdNm: string;
  memo: string;
  agreeSabun: string;
  agreeYmd: string;
  finishYn: string;
  fileSeq: string;
  docNo: string;
}

interface CommonDetailUserApplInfo {
  name: string;
  empAlias: string;
  jikchakNm: string;
  jikweeNm: string;
  jikgubNm: string;
  sabun: string;
  orgNm: string;
  orgCd: string;
  jikchakCd: string;
  jikweeCd: string;
  applYmd: string;
}

interface CommonDetailInfoResponseInterface {
  userInfo: CommonDetailUserInfo;
  cancelButton: CommonDetailCancelButton;
  uiInfo: CommonDetailUiInfo;
  agreeInfo: any;
  agreeSabunYn: CommonDetailAgreeSabunYn;
  applMasterInfo: CommonDetailApplMasterInfo;
  userApplInfo: CommonDetailUserApplInfo;
}

export const useCommonDetailInfo = (params: CommonDetailInfoRequestInterface | null) => {
  const { searchApplSeq, searchSabun, searchApplSabun, searchApplCd } = params ?? {};
  return useRequest<CommonDetailInfoResponseInterface>(
    searchApplSeq && searchSabun && searchApplSabun && searchApplCd
      ? {
          url: `comm/control/myhr/common/searchApprovalMgrResult`,
          params: { searchApplSeq, searchSabun, searchApplSabun, searchApplCd },
        }
      : null,
  );
};

export const useEmpWorkType = () => {
  const { sabun: searchApplSabun } = useUserInfo();
  const searchApplYmd = formatDate(moment(), GlobalFormat.DATE_API);
  const { data: onlyOneList } = useRequest<[{ workType: string }]>({
    url: `/comm/control/myhr/common/dataList`,
    params: { cmd: 'getEmpWorkType', searchApplSabun, searchApplYmd },
  });
  return onlyOneList?.[0]?.workType;
};

export interface IPostApprovalRequestInterface {
  searchApplCd?: string;
  searchSabun?: string;
  searchApplStatusCd?: string;
  searchApplSeq?: string;
  fileSeq?: string;
  pathSeq?: string;
}

export interface IPostApprovalResponseInterface {
  agreeEmpAlias: string;
  agreeJikchakCd: string;
  agreeJikchakNm: string;
  agreeJikgubCd: string;
  agreeJikgubNm: string;
  agreeJikweeCd: string;
  agreeJikweeNm: string;
  agreeName: string;
  agreeOrgCd: string;
  agreeOrgNm: string;
  agreeSabun: string;
  agreeSeq: string;
  agreeYn: string;
  appPathYn: string;
  applCd: string;
  applNm: string;
  applSeq: string;
  applTitle: string;
  applTypeCd: string;
  applTypeCdNm: string;
  closeDay: string;
  etcNote: string;
  etcNoteFileCnt: string;
  etcNoteYn: string;
  fileEssentialYn: string;
  fileYn: string;
  holidayCnt: string;
  jikchakNm: string;
  jikweeNm: string;
  minusYn: string;
  name: string;
  orgLevelCd: string;
  orgLevelYn: string;
  orgNm: string;
  printYn: string;
  recevYn: string;
  restCnt: string;
  restTime: string;
  sabun: string;
  statusCd: string;
  valChk: string;
}

export const postApproval = ({
  searchApplCd,
  searchSabun,
  searchApplStatusCd,
  searchApplSeq,
  fileSeq,
  pathSeq,
}: IPostApprovalRequestInterface) => {
  if (!(searchApplCd && searchSabun && searchApplStatusCd && searchApplSeq)) {
    console.error('searchApplCd, searchSabun, searchApplStatusCd, searchApplSeq  값을 모두 입력해주세요');
  }
  return axiosInstance.post<IPostApprovalResponseInterface>(`comm/control/myhr/common/approval`, {
    searchApplCd,
    searchSabun,
    searchApplStatusCd,
    searchApplSeq,
    fileSeq,
    pathSeq,
  });
};

export const postUpdateCancelStatusCd = async (searchApplSeq?: string, searchSabun?: string) => {
  const statusCd = '11'; // 임시저장
  return await axiosInstance.post('/comm/control/myhr/common/updateCancelStatusCd', { searchApplSeq, searchSabun, statusCd });
};
