import { DateRange } from '@mui/lab';
import { NamePath } from 'rc-field-form/es/interface';
import React, { FC } from 'react';
import { CommonFormField } from '../atoms/CommonForm';
import CommonInputDateRange from '../atoms/CommonInputDateRange';
interface ICommonRangeDateFormFieldProps {
  name: NamePath;
  fromLabel: string;
  toLabel: string;
  required?: boolean;
  value?: any;
  onChange?: (dateValues: string[], date: DateRange<Date> | null) => void;
  onChangeDate?: (dateValues: string[], date: DateRange<Date> | null) => void;
}
const CommonRangeDateFormField: FC<ICommonRangeDateFormFieldProps> = ({ name, required, ...restProps }) => {
  return (
    <CommonFormField
      name={name}
      rules={[
        {
          validator: (_, value) => {
            if (required && !(value?.[0] && value?.[1])) {
              return Promise.reject({ from: !!value?.[0], to: !!value?.[1] });
            } else {
              return Promise.resolve();
            }
          },
        },
      ]}>
      <CommonInputDateRange {...restProps} />
    </CommonFormField>
  );
};

export default CommonRangeDateFormField;
