import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonInputDate from '@components/common/atoms/CommonInputDate';
import React from 'react';

const SignDate = () => {
  return (
    <CommonFormField
      name={'signDate'}
      rules={[
        {
          required: true,
        },
      ]}>
      <CommonInputDate label='구입/임차계약일자' />
    </CommonFormField>
  );
};

export default SignDate;
