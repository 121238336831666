import { useDiseaseInfo } from '@apis/disease';
import { Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useMemo } from 'react';
import NumberFormat from 'react-number-format';

const DiseaseInfo = () => {
  const { data } = useDiseaseInfo();
  const diseaseInfo = useMemo(() => data?.[0], [data]);
  return (
    <TableContainer component={Paper}>
      <Table aria-label='simple table'>
        <TableHead style={{ background: '#ddddff' }}>
          <TableRow>
            <TableCellStyled padding={'none'} align='center'>
              지급비율
            </TableCellStyled>
            <TableCellStyled padding={'none'} align='center'>
              지급한도금액
            </TableCellStyled>
            <TableCellStyled padding={'none'} align='center'>
              연간신청횟수
            </TableCellStyled>
            <TableCellStyled padding={'none'} align='center'>
              증빙서류
            </TableCellStyled>
          </TableRow>
        </TableHead>
        <TableBody style={{ background: '#ddd' }}>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCellStyled padding={'none'} align='center'>
              {`${diseaseInfo?.rate}%`}
            </TableCellStyled>
            <TableCellStyled padding={'none'} align='center'>
              {<NumberFormat value={diseaseInfo?.limitAmt ?? 0} displayType={'text'} thousandSeparator={true} suffix={' 원'} />}
            </TableCellStyled>
            <TableCellStyled padding={'none'} align='center'>
              {diseaseInfo?.yearLimitCnt}
            </TableCellStyled>
            <TableCellStyled padding={'none'} align='center'>
              {diseaseInfo?.prDoc}
            </TableCellStyled>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TableCellStyled = styled(TableCell)`
  height: 40px;
`;
export default DiseaseInfo;
