import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonInput from '@components/common/atoms/CommonInput';
import CommonInputDate from '@components/common/atoms/CommonInputDate';
import { COLOR } from '@constants/Color';
import { Stack } from '@mui/material';
import React, { FC } from 'react';
import styled from 'styled-components';
import RestTimeSelect from './RestTimeSelect';

interface IVacationCodeProps {
  gntCd?: string;
  searchApplCd?: string;
  restTime?: number;
}

const RequestTime: FC<IVacationCodeProps> = ({ gntCd, searchApplCd, restTime }) => {
  return (
    <div>
      <CommonFormField
        name={'applYmd'}
        rules={[
          {
            required: true,
          },
        ]}>
        <CommonInputDate label='신청일' />
      </CommonFormField>
      <Stack direction={'row'} marginTop={2} marginBottom={2}>
        <Item>
          <CommonFormField
            name='reqSHm'
            rules={[
              {
                required: true,
              },
            ]}>
            <CommonInput label='시작시간(MM:SS)' pattern={'time'} />
          </CommonFormField>
        </Item>
        <Seperator>~</Seperator>
        <Item>
          <CommonFormField
            name='reqEHm'
            rules={[
              {
                required: true,
              },
            ]}>
            <CommonInput label='종료시간(MM:SS)' pattern={'time'} />
          </CommonFormField>
        </Item>
      </Stack>
      {searchApplCd === '26' ? (
        <>
          <Stack direction={'row'} marginTop={2} marginBottom={2}>
            <Item>
              <div style={{ marginRight: 13 }}>
                <RestTimeSelect />
              </div>
            </Item>
            <Item>
              <ItemDiv>{`적용시간 : ${restTime ?? 0}`} </ItemDiv>
            </Item>
          </Stack>
        </>
      ) : (
        <>
          <Stack direction={'row'} margin={1}>
            <Item>{`적용시간 : ${restTime ?? 0}`} </Item>
          </Stack>
        </>
      )}
    </div>
  );
};

const Item = styled.div`
  flex: 1;
  color: ${COLOR.GRAY};
`;
const ItemDiv = styled.div`
  padding-left: 26px;
  padding-top: 12px;
  font-size: 16px;
`;
const Seperator = styled.div`
  padding: 10px;
`;
export default RequestTime;
