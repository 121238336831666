import { IGntCodeItem } from '@apis/vacation';
import CommonButton from '@components/common/atoms/CommonButton';
import CommonInput from '@components/common/atoms/CommonInput';
import { currentCommonMessageAtom } from '@components/common/atoms/CommonMessage';
import HorizontalBlank from '@components/common/atoms/HorizontalBlank';
import VerticalBlank from '@components/common/atoms/VerticalBlank';
import { GlobalFormat } from '@constants/Format';
import moment from 'moment';
import { FC, useState } from 'react';
import { atom, useRecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import CancelGntCodeSelect from './CancelGntCodeSelect';
import VacationCancelRequestModal from './VacationCancelRequestModal';

export const currentVacationCancelSearchYear = atom<string>({
  key: 'vacationCancelSearchYearAtom',
  default: moment().format(GlobalFormat.YEAR),
});

const VacationCancelSearchForm: FC = () => {
  const [year, setYear] = useRecoilState(currentVacationCancelSearchYear);
  const [value, setValue] = useState(year);
  const [gntSelectedItem, setGntSelectedItem] = useState<IGntCodeItem>();
  const setMessage = useSetRecoilState(currentCommonMessageAtom);
  const [requestModalShow, isRequestModalShow] = useState(false);
  const insertClick = () => {
    if (!gntSelectedItem?.code) {
      setMessage({
        open: true,
        message: '신청구분을 선택하여 주십시오.',
        type: 'warning',
      });
    } else {
      isRequestModalShow(true);
    }
  };
  return (
    <>
      <HorizontalBlank height={6} />
      <CommonInput
        label={'기준년도'}
        value={value}
        onInput={({ target }: any) => setValue(target?.value)}
        inputProps={{ maxLength: 4 }}
      />
      <HorizontalBlank height={15} />
      <CancelGntCodeSelect onChangeItem={(value, selectedItem) => setGntSelectedItem(selectedItem?.data)} />
      <ButtonContainer>
        <CommonButton
          onClick={() => {
            if (value.length < 4) {
              setMessage({
                open: true,
                type: 'error',
                message: '4자리 이상 입력해주세요.',
              });
              return;
            }
            setYear(value);
          }}>
          조회
        </CommonButton>
        <VerticalBlank width={15} />
        <CommonButton onClick={insertClick}>신청</CommonButton>
        {requestModalShow && (
          <VacationCancelRequestModal
            open={requestModalShow}
            onClose={() => isRequestModalShow(false)}
            searchApplSeq={undefined}
            searchApplCd={gntSelectedItem?.code}
            gntGubunCd={gntSelectedItem?.gntGubunCd}
            codeName={gntSelectedItem?.codeNm}
            authPg={'신청'}
          />
        )}
      </ButtonContainer>
    </>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 12px;
`;
export default VacationCancelSearchForm;
