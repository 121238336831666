import { getDiseaseFamilyName } from '@apis/loan';
import { CommonFormField } from '@components/common/atoms/CommonForm';
import { currentCommonMessageAtom } from '@components/common/atoms/CommonMessage';
import CommonSelect from '@components/common/molecules/CommonSelect';
import { useUserInfo } from '@components/common/molecules/CommonUserInfo';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { ICommonCodeItem } from 'src/util/commonCode';
interface ITargetNameProps {
  famCd?: string;
}
const TargetName: FC<ITargetNameProps> = ({ famCd }) => {
  const { sabun } = useUserInfo();
  const setMessage = useSetRecoilState(currentCommonMessageAtom);
  const [familyNameList, setFamilyNameList] = useState<ICommonCodeItem[]>([]);

  const fetchFamilyNameList = useCallback(async () => {
    const res = await getDiseaseFamilyName({ sabun, famCd });
    if (Array.isArray(res?.data) && res?.data.length > 0) {
      setFamilyNameList(res.data);
    } else {
      setMessage({
        open: true,
        message: '해당 관계로 등록된 가족이 없습니다.',
        type: 'error',
      });
    }
  }, [setFamilyNameList, setMessage, famCd, sabun]);

  useEffect(() => {
    famCd && sabun && fetchFamilyNameList();
  }, [sabun, famCd, fetchFamilyNameList]);

  return (
    <CommonFormField
      rules={[
        {
          required: true,
        },
      ]}
      name={'famNm'}>
      <CommonSelect
        title='대상자명'
        options={familyNameList?.map((item) => ({
          label: item.codeNm,
          value: item.code,
        }))}
      />
    </CommonFormField>
  );
};

export default TargetName;
