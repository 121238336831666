import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonSelect from '@components/common/molecules/CommonSelect';
import { FC } from 'react';
import { useStudentFundGubunList } from 'src/util/commonCode';

const StudentFundSelect: FC = () => {
  const { data: schTypeCodeList } = useStudentFundGubunList();
  return (
    <>
      <CommonFormField
        name={'schType'}
        rules={[
          {
            required: true,
          },
        ]}>
        <CommonSelect
          title='학자금구분'
          options={schTypeCodeList?.map((item) => ({
            label: item.codeNm,
            value: item.code,
          }))}
        />
      </CommonFormField>
    </>
  );
};

export default StudentFundSelect;
