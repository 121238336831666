import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonInput from '@components/common/atoms/CommonInput';
import React from 'react';

const DiseaseName = () => {
  return (
    <CommonFormField
      name={'diseaseNm'}
      rules={[
        {
          required: true,
        },
      ]}>
      <CommonInput label='질병 및 질병명' />
    </CommonFormField>
  );
};

export default DiseaseName;
