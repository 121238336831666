import CommonButton from '@components/common/atoms/CommonButton';
import { currentCommonMessageAtom } from '@components/common/atoms/CommonMessage';
import HorizontalBlank from '@components/common/atoms/HorizontalBlank';
import CommonSelect from '@components/common/molecules/CommonSelect';
import { GlobalFormat } from '@constants/Format';
import moment from 'moment';
import { FC, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';
interface IPayslipSearchProps {
  setYear: React.Dispatch<React.SetStateAction<string>>;
  year: string;
}
const PayslipSearchForm: FC<IPayslipSearchProps> = ({ setYear, year }) => {
  const [value, setValue] = useState(year);
  const setMessage = useSetRecoilState(currentCommonMessageAtom);
  const options = Array(4)
    .fill(null)
    .map((_, i) => {
      const curYear = moment().format(GlobalFormat.YEAR);
      const result = Number(curYear) - i;
      return {
        label: result.toString(),
        value: result.toString(),
      };
    });

  return (
    <>
      <HorizontalBlank height={6} />
      <CommonSelect title={'기준년도'} options={options} value={value} onChangeItem={(value) => setValue(value)} />

      <ButtonContainer>
        <CommonButton
          onClick={() => {
            if (value.length < 4) {
              setMessage({
                open: true,
                type: 'error',
                message: '기준년도를 선택해주세요.',
              });
              return;
            }
            setYear(value);
          }}>
          조회
        </CommonButton>
      </ButtonContainer>
    </>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 12px;
`;
export default PayslipSearchForm;
