import { COLOR } from '@constants/Color';
import { Button } from '@mui/material';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface IButtonProps {
  children: ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  theme?: 'red' | 'yellow' | 'gray';
}

const CommonButton: FC<IButtonProps> = ({ children, onClick, theme = 'red' }) => {
  return (
    <ButtonContainer
      style={{
        background:
          theme === 'red'
            ? `linear-gradient(90deg,${COLOR.RED}, ${COLOR.RED_LIGHTEN} )`
            : theme === 'yellow'
            ? `linear-gradient(90deg,${COLOR.YELLOW}, ${COLOR.YELLOW_LIGHTEN} )`
            : `linear-gradient(90deg,#bbb, #ccc )`,
        color: theme === 'red' ? 'white' : COLOR.BLACK,
        fontSize: '14px',
      }}
      color={'inherit'}
      onClick={onClick}>
      {children}
    </ButtonContainer>
  );
};

const ButtonContainer = styled(Button)`
  height: 45px;
  flex: 1;
  width: 100%;
`;

export default CommonButton;
