import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonInputDate from '@components/common/atoms/CommonInputDate';
import { currentCommonMessageAtom } from '@components/common/atoms/CommonMessage';
import moment from 'moment';
import React, { FC } from 'react';
import { useSetRecoilState } from 'recoil';
interface IOccDateProps {
  occCd: string;
}

const OccDate: FC<IOccDateProps> = ({ occCd }) => {
  const setMessage = useSetRecoilState(currentCommonMessageAtom);
  return (
    <CommonFormField
      name={'occDate'}
      rules={[
        {
          required: true,
        },
        {
          validator: (_, value) => {
            const today = moment();
            const diff = today.diff(value, 'days');
            if (occCd === '00004' && diff > 31) {
              setMessage({
                open: true,
                message: '출산보조금은 경조일로부터 1개월 이내만 신청 가능합니다.',
                type: 'error',
              });
              return Promise.reject('출산보조금은 경조일로부터 1개월 이내만 신청 가능합니다.');
            } else {
              return Promise.resolve();
            }
          },
        },
      ]}>
      <CommonInputDate label='경조일' />
    </CommonFormField>
  );
};

export default OccDate;
