import { IRegisteredVacationRequestParam } from '@apis/vacationCancle';
import HorizontalBlank from '@components/common/atoms/HorizontalBlank';
import CommonPopModal from '@components/common/molecules/CommonPopModal';
import { COLOR } from '@constants/Color';
import SearchIcon from '@mui/icons-material/Search';
import { Button } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import RegisteredVacationCancelList from './RegisteredVacationCancelList';
import RegisteredVacationSearchForm from './RegisteredVacationSearchForm';

interface IRegisteredVacationListTemplateProps {
  gntGubunCd?: string;
  searchApplCd?: string;
  onSelect: (data?: any) => void;
}

export const currentOnVacationListSelectFunction = atom<((data: any) => void) | null>({
  key: 'currentOnVacationListSelectFunction',
  default: null,
});

const ListTemplate: FC<{ gntGubunCd?: string; searchApplCd?: string }> = ({ gntGubunCd, searchApplCd }) => {
  const [param, setParam] = useState<IRegisteredVacationRequestParam>();
  useRecoilValue(currentOnVacationListSelectFunction);
  return (
    <>
      <RegisteredVacationSearchForm setParam={setParam} gntGubunCd={gntGubunCd} searchApplCd={searchApplCd} />
      <HorizontalBlank height={10} />
      <RegisteredVacationCancelList param={param} />
    </>
  );
};

const RegisteredVacationListTemplate: FC<IRegisteredVacationListTemplateProps> = ({ gntGubunCd, searchApplCd, onSelect }) => {
  const setOnSelect = useSetRecoilState(currentOnVacationListSelectFunction);
  const [visible, isVisible] = useState(false);
  useEffect(() => {
    setOnSelect(() => (data: any) => {
      onSelect(data);
      isVisible(false);
    });
  }, [onSelect, setOnSelect, isVisible]);

  return (
    <>
      <VacationListButton
        onClick={(e) => {
          isVisible(true);
        }}
        style={{
          background: `linear-gradient(0deg, rgb(253 253 225), rgb(253 255 250))`,
          color: COLOR.RED,
          fontSize: '16px',
          border: '1px solid #727272',
          fontWeight: '600',
        }}>
        <SearchIcon />
        휴가내역검색
      </VacationListButton>
      <CommonPopModal open={visible} onClose={() => isVisible(false)} title={'휴가내역조회'}>
        <ListTemplate gntGubunCd={gntGubunCd} searchApplCd={searchApplCd} />
      </CommonPopModal>
    </>
  );
};
const VacationListButton = styled(Button)`
  height: 45px;
  flex: 1;
  width: 100%;
`;

export default RegisteredVacationListTemplate;
