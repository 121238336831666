import { useGntCodeList } from '@apis/vacation';
import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonSelect, { ISelectOptionItemInterface } from '@components/common/molecules/CommonSelect';
import React, { FC } from 'react';
interface IGntCodeSelectProps {
  onChangeItem?: (value: string, item: ISelectOptionItemInterface | null) => void;
}
const GntCodeSelect: FC<IGntCodeSelectProps> = ({ onChangeItem }) => {
  const { data: gntCodeList } = useGntCodeList();
  return (
    <CommonFormField>
      <CommonSelect
        title={'신청구분'}
        onChangeItem={onChangeItem}
        options={gntCodeList?.map((item) => ({
          label: item.codeNm,
          value: item.code,
          data: item,
        }))}
      />
    </CommonFormField>
  );
};

export default GntCodeSelect;
