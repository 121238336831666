import { useEmailToSabun, usePrivacyAgreement } from '@apis/common';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { COLOR } from '@constants/Color';
import InfoIcon from '@mui/icons-material/Info';
import { Dialog, DialogTitle } from '@mui/material';
import { FC, useMemo } from 'react';
import { useAuthorization } from 'src/util/auth';
import styled, { css, keyframes } from 'styled-components';
import CommonButton from '../atoms/CommonButton';
import HorizontalBlank from '../atoms/HorizontalBlank';

export const useUserInfo = () => {
  const { tokenData } = useAuthorization();
  //console.log('tokenData', tokenData);
  const { data: userSearchData } = useEmailToSabun({ email: tokenData?.account?.username, name: tokenData?.account?.name });
  const sabun = useMemo(() => userSearchData?.sabun, [userSearchData]);
  return { ...userSearchData, sabun };
};

interface ICommonUserInfoProps {
  open: boolean;
}

const CommonUserInfo: FC<ICommonUserInfoProps> = ({ open }) => {
  const { tokenData, handleLogout } = useAuthorization();
  //console.log('name', tokenData?.account?.name);
  const { data: userSearchDataList, error } = useEmailToSabun({
    email: tokenData?.account?.username,
    name: tokenData?.account?.name,
  });

  const privacyAgreement = usePrivacyAgreement(); //산입사원의 경우 개인정보동의가 필요함.
  const userSearchData = useMemo(() => userSearchDataList, [userSearchDataList]);
  const sabun = useMemo(() => userSearchData?.sabun, [userSearchData]);

  return (
    <>
      <AuthenticatedTemplate>
        <AnimationContainer open={open}>
          <UserInfoContainer>
            <PaddingContents>
              <CardHeaderFont>{tokenData?.account?.name}</CardHeaderFont>
              <CardRow>
                <CardTitleFont>사번</CardTitleFont>
                <CardTitleDescription>{sabun}</CardTitleDescription>
              </CardRow>
              <CardRow>
                <CardTitleFont>부서</CardTitleFont>
                <CardTitleDescription>{userSearchData?.orgNm}</CardTitleDescription>
              </CardRow>
              <CardRow>
                <CardTitleFont>Job title</CardTitleFont>
                <CardTitleDescription>{userSearchData?.jobNm}</CardTitleDescription>
              </CardRow>
            </PaddingContents>
            <CommonButton onClick={handleLogout}>Sign Out</CommonButton>
          </UserInfoContainer>
          <HorizontalBlank height={10} />
        </AnimationContainer>
      </AuthenticatedTemplate>
      <Dialog open={sabun == 'X'} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          <InfoIcon sx={{ fontSize: 20, color: 'red' }} />
          <div style={{ fontSize: 13 }}>
            사번 정보를 불러올 수 없습니다.
            <br />
            HR담당자에게 문의하시기 바랍니다.
          </div>
        </DialogTitle>
      </Dialog>

      <Dialog open={sabun == 'NAME_X'} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          <InfoIcon sx={{ fontSize: 20, color: 'red' }} />
          <div style={{ fontSize: 13 }}>
            영문명이 일치하지 않습니다.
            <br />
            관련 문의사항은 인재채용팀 그룹메일(selcareers@dhl.com)로 접수해주시기 바랍니다.
          </div>
        </DialogTitle>
      </Dialog>

      <Dialog
        open={privacyAgreement.data?.privacyAgreementYn === 'N'}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          <InfoIcon sx={{ fontSize: 20, color: 'red' }} />
          <div style={{ fontSize: 13 }}>
            ※ myHR 첫 로그인 시 개인정보 수집 및 이용에 대한 동의가 필요합니다. PC 에서 myHR 로그인 후 동의서를 확인하여 주시기
            바랍니다. ※
            <br />
            
            <br />
            myHR 로그인 방법 : KRWIS 접속({' '}
            <a href='https://dpdhl.sharepoint.com/sites/EXPKRWIS' target='_blank'>
            https://dpdhl.sharepoint.com/sites/EXPKRWIS
            </a>{' '}
            ) → myHR 접속
            <br />
            문의사항 : 성과보상팀 김태이 부장(☎ 02-710-8125)
          </div>
        </DialogTitle>
      </Dialog>
    </>
  );
};

const slideIn = keyframes`
    0%{
        height: 0;
    }
    100%{
        height: 220px; 
    }
`;
const slideOut = keyframes`
    0%{
        height: 220px; 
    }
    100%{
        height: 0; 
    }
`;

const AnimationContainer = styled.div<ICommonUserInfoProps>`
  position: fixed;
  height: 0;
  width: 100vw;
  top: 60px;
  padding: 0 10px;
  max-height: 0;
  margin-bottom: 10px;
  z-index: 3;
  overflow: hidden;
  ${({ open }) => css`
    max-height: ${open ? '220px' : '0'};
    animation: ${open ? slideIn : slideOut} 0.2s ease-in-out forwards;
  `};
`;
const UserInfoContainer = styled.div`
  background-color: ${COLOR.BG_GRAY};
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  width: 100%;
  box-shadow: 2px 10px 10px rgba(0, 0, 0, 0.5);
`;

const PaddingContents = styled.div`
  padding: 16px;
  margin-bottom: 10px;
`;
const CardHeaderFont = styled.div`
  font-weight: 500;
  font-size: 17px;
  line-height: 1.334;
  margin-bottom: 8px;
`;

const CardRow = styled.div`
  position: relative;
  margin: 8px 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const CardTitleFont = styled.span`
  font-size: 13px;
  font-weight: 700;
`;

const CardTitleDescription = styled.span`
  font-size: 13px;
  font-weight: 400;
`;

export default CommonUserInfo;
