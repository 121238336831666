import { COLOR } from '@constants/Color';
import SearchIcon from '@mui/icons-material/Search';
import React, { FC } from 'react';
import styled from 'styled-components';
interface IEmptyProps {
  msg?: string;
}
const Empty: FC<IEmptyProps> = ({ msg }) => {
  return (
    <>
      <EmptyContainer>
        <IconDiv>
          <SearchIconContainer />
        </IconDiv>
        {msg || '신청 내역이 없습니다.'}
      </EmptyContainer>
    </>
  );
};

const EmptyContainer = styled.div`
  display: table-cell;
  padding-top: 43%;
  text-align: center;
  font-size: 20px;
`;

const IconDiv = styled.div`
  height: 50px;
`;

const SearchIconContainer = styled(SearchIcon)`
  color: ${COLOR.RED};
  transform: scale(3.5);
`;
export default Empty;
