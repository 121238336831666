import { useDiseaseInfo } from '@apis/disease';
import { Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { FC, useMemo } from 'react';
import NumberFormat from 'react-number-format';

interface IDiseaseAmountInfoProps {
  conMon?: number;
  payMon?: number;
}

const DiseaseAmountInfo: FC<IDiseaseAmountInfoProps> = ({ conMon, payMon }) => {
  const { data: diseaseInfoList } = useDiseaseInfo();
  const diseaseInfo = useMemo(() => diseaseInfoList?.[0], [diseaseInfoList]);
  const limitAmt = parseInt(diseaseInfo?.limitAmt ?? '0');

  return (
    <TableContainer component={Paper}>
      <Table aria-label='simple table'>
        <TableHead style={{ background: '#ddddff' }}>
          <TableRow>
            <TableCellStyled padding={'none'} align='center'>
              총 신청금액
            </TableCellStyled>
            <TableCellStyled padding={'none'} align='center'>
              총 지원금액
            </TableCellStyled>
          </TableRow>
        </TableHead>
        <TableBody style={{ background: '#ddd' }}>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCellStyled padding={'none'} align='center'>
              <NumberFormat value={conMon} displayType={'text'} thousandSeparator={true} suffix={' 원'} />
            </TableCellStyled>
            <TableCellStyled padding={'none'} align='center'>
              <NumberFormat
                value={payMon && payMon > limitAmt ? limitAmt : payMon}
                displayType={'text'}
                thousandSeparator={true}
                suffix={' 원'}
              />
            </TableCellStyled>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TableCellStyled = styled(TableCell)`
  height: 40px;
`;
export default DiseaseAmountInfo;
