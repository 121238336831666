import { useStudentFundsBankTypeList } from '@apis/studentFunds';
import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonSelect from '@components/common/molecules/CommonSelect';
import { FC } from 'react';

const StudentFundBankTypeSelect: FC = () => {
  const { data: schTypeCodeList } = useStudentFundsBankTypeList();

  return (
    <>
      <CommonFormField
        name={'bankType'}
        rules={[
          {
            required: true,
          },
        ]}>
        <CommonSelect
          title='계좌구분'
          options={schTypeCodeList?.map((item) => ({
            label: item.codeNm,
            value: item.code.split('|')[0],
          }))}
        />
      </CommonFormField>
    </>
  );
};

export default StudentFundBankTypeSelect;
