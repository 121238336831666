import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonSelect, { ISelectOptionItemInterface } from '@components/common/molecules/CommonSelect';
import React, { FC } from 'react';
interface IRestTimeSelectProps {
  onChangeItem?: (value: string, item: ISelectOptionItemInterface | null) => void;
}
const RestTimeSelect: FC<IRestTimeSelectProps> = ({ onChangeItem }) => {
  const options = Array(61)
    .fill(null)
    .map((_, i) => {
      const result = String(i).padStart(2, '0');
      return {
        label: result,
        value: result,
      };
    });
  return (
    <CommonFormField name={'restTime'}>
      <CommonSelect title='휴게시간' options={options} />
    </CommonFormField>
  );
};

export default RestTimeSelect;
