import React, { FC } from 'react';
import LoanAmt from './LoanAmt';
import { ILoanRegistSubProps } from './LoanRequestModal';
import LoanUseTemplates from './LoanUseTemplates';
import SignDate from './SignDate';

const HouseBuiyng: FC<ILoanRegistSubProps> = ({ loanKindCd }) => {
  return (
    <>
      <LoanAmt />
      <LoanUseTemplates loanKindCd={loanKindCd} />
      <SignDate />
    </>
  );
};

export default HouseBuiyng;
