import { IPayslipListInterface } from '@apis/payslip';
import 급여명세서 from '@assets/images/급여명세서_아이콘.png';
import { COLOR } from '@constants/Color';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import PayslipRequestModal from './PayslipRequestModal';

const PayslipItem: FC<IPayslipListInterface> = (data, index) => {
  const [requestModalShow, isRequestModalShow] = useState(false);
  const [month, setMonth] = useState<string>();
  const [paramPayActionCd, setParamPayActionCd] = useState<string>('');

  useEffect(() => {
    const payActionCd = data.payActionCd;
    const payMonth = payActionCd.substr(4, 1) === '0' ? payActionCd.substr(5, 1) : payActionCd.substr(4, 2);
    setMonth(payMonth);
  }, [data.payActionCd]);

  return (
    <>
      <Container>
        <MonthDiv
          onClick={(e) => {
            isRequestModalShow(true);
            setParamPayActionCd(data.payActionCd);
          }}>
          <IconImage src={급여명세서} />
          <Month>{month}월</Month>
        </MonthDiv>
      </Container>
      {
        <PayslipRequestModal
          key={month}
          open={requestModalShow}
          onClose={() => isRequestModalShow(false)}
          payActionCd={paramPayActionCd}
        />
      }
    </>
  );
};

const Container = styled.div`
  background: ${COLOR.BG_LIGHTWT};
`;

const MonthDiv = styled.div`
  float: left;
  padding: 3% 4% 0% 4%;
`;

const IconImage = styled.img`
  object-fit: contain;
  width: 80px;
  height: 80px;
`;

const Month = styled.div`
  color: gray;
  font-weight: 700;
  font-size: 20px;
  padding-left: 19px;
`;

export default PayslipItem;
