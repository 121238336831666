import { IApprovalInfoRequestInterface, useApprovalAcceptInfo, useApprovalInfo } from '@apis/common';
import { GlobalFormat } from '@constants/Format';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Stack } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { formatDate } from 'src/util/commonUtil';
import styled, { css } from 'styled-components';

const CommonApprovalLine: FC<IApprovalInfoRequestInterface> = (params) => {
  const { searchApplSeq } = params;
  const { data: beforeAccept } = useApprovalInfo(params);
  const { data: afterAccept } = useApprovalAcceptInfo(searchApplSeq);

  const insertAgreeUser = useMemo(() => {
    const { insertAgreeUser } = beforeAccept || {};
    return insertAgreeUser;
  }, [beforeAccept]);
  const { authPg } = params;
  const renderHeaderTitle = (applTypeCd: String) => {
    switch (applTypeCd) {
      case '10':
        return '결재';

      case '30':
        return '기안';

      case '40':
        return '담당';

      default:
        return '';
    }
  };

  return (
    <AuthorArea>
      <Stack divider={<ArrowForwardIosIcon />} direction={'row'} alignItems={'center'}>
        {authPg === '신청'
          ? insertAgreeUser?.map((item) => (
              <AuthorContainer key={item.agreeSeq}>
                <RowHeader background={item.gubun === '3' ? '#3ab7c6' : '#b3cc33'}>
                  {renderHeaderTitle(item.applTypeCd)}
                </RowHeader>
                <RowDetail>{item.org}</RowDetail>
                <RowDetail>{item.jikwee}</RowDetail>
                <RowDetail>{item.name}</RowDetail>
                {/* <RowDetail>2022.01.24</RowDetail>
              <RowDetail color={'red'}>결제</RowDetail> */}
              </AuthorContainer>
            ))
          : afterAccept?.map((item) => (
              <AuthorContainer key={item.agreeSeq}>
                <RowHeader background={item.gubun === '3' ? '#3ab7c6' : '#b3cc33'}>
                  {renderHeaderTitle(item.applTypeCd)}
                </RowHeader>
                <RowDetail>{item.agreeOrgNm}</RowDetail>
                <RowDetail>{item.agreeJikweeNm}</RowDetail>
                <RowDetail>{item.agreeName}</RowDetail>
                <RowDetail>{formatDate(item.agreeTime, GlobalFormat.DATE_DASH)}</RowDetail>
                <RowDetail color={'red'}>{item.agreeStatusCdNm}</RowDetail>
              </AuthorContainer>
            ))}
      </Stack>
    </AuthorArea>
  );
};

const AuthorArea = styled.div`
  align-items: center;
  margin-top: 13px;
  padding-bottom: 15px;
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
`;

const AuthorContainer = styled.div`
  scroll-snap-align: start;
  background-color: #dae1e6;
  border: 1px solid #dae1e6;
  border-radius: 1px;
  font-size: 12px;
  width: 100px;
  flex-direction: column;
  margin-left: auto;
`;

const RowDetail = styled.div<{ color?: string }>`
  height: 23px;
  text-align: center;
  background-color: #f7f9fc;
  padding-top: 3px;
  overflow: overlay;
  ${({ color }) =>
    css`
      color: ${color};
    `}
`;

const RowHeader = styled.div<{ background: string }>`
  height: 23px;
  text-align: center;
  background-color: ${({ background }) => background};
  padding-top: 3px;
  color: white;
`;
export default CommonApprovalLine;
