import CommonContainer from '@components/common/atoms/CommonContainer';
import { useHeaderStore } from '@stores/headerStore';
import { useEffect } from 'react';
import FamilyEventList from './templates/FamilyEventList';
import FamilyEventSearchForm from './templates/FamilyEventSearchForm';

const FamilyEventPage = () => {
  const { setConfig } = useHeaderStore();
  useEffect(() => {
    setConfig({ title: '경조금' });
  }, [setConfig]);
  return (
    <>
      <CommonContainer style={{ padding: 15 }}>
        <FamilyEventSearchForm />
      </CommonContainer>
      <FamilyEventList />
    </>
  );
};

export default FamilyEventPage;
