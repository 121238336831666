import { useLoanList } from '@apis/loan';
import Empty from '@components/common/atoms/Empty';
import { FC } from 'react';
import styled from 'styled-components';
import LoanItem from './LoanItem';

const LoanList: FC = () => {
  const { data: list } = useLoanList();

  if (list?.length === 0) {
    return <Empty />;
  }

  return (
    <>
      <ListContainer>
        {list?.map((item, index) => (
          <LoanItem {...item} key={index} />
        ))}
      </ListContainer>
    </>
  );
};

const ListContainer = styled.div`
  display: grid;
  gap: 5px;
  padding-top: 5px;
  margin-bottom: 45px;
  background: #d1d1d1;
`;

export default LoanList;
