import { useCommonDetailInfo } from '@apis/common';
import { Stack } from '@mui/material';
import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import CommonButton from '../atoms/CommonButton';
import { useUserInfo } from './CommonUserInfo';
interface ICommonRequestBottomButtonsProps {
  onRequestButtonClick?: (() => void) | null;
  onTempButtonClick?: (() => void) | null;
  onCancelButtonClick?: (() => void) | null;
  onCloseButtonClick?: () => void;
  onApprovalButtonClick: (() => void) | null;
  onRejectButtonClick: (() => void) | null;
  authPg?: AUTH_TYPE;
  applSeq?: string;
  applCd?: string;
}
const CommonRequestBottomButtons: FC<ICommonRequestBottomButtonsProps> = ({
  onRequestButtonClick,
  onTempButtonClick,
  onCloseButtonClick,
  onCancelButtonClick,
  onApprovalButtonClick,
  onRejectButtonClick,
  authPg,
  applSeq,
  applCd,
}) => {
  const { sabun } = useUserInfo();

  const { data: commonDetailInfo } = useCommonDetailInfo(
    applSeq ? { searchApplCd: applCd, searchApplSeq: applSeq, searchApplSabun: sabun, searchSabun: sabun } : null,
  );

  const { applStatusCd, applInSabun } = commonDetailInfo?.applMasterInfo ?? {};
  const 임시저장 = applStatusCd === '11' || !applStatusCd;
  const 본인신청건 = applInSabun === sabun;

  const cancelYES = useMemo(() => commonDetailInfo?.cancelButton?.cancel === 'YES', [commonDetailInfo?.cancelButton?.cancel]);
  return (
    <ButtonContainer direction={'row'} spacing={1.5}>
      {authPg === '신청' && 임시저장 && onRequestButtonClick && <CommonButton onClick={onRequestButtonClick}>신청</CommonButton>}
      {authPg === '신청' && 임시저장 && onTempButtonClick && (
        <CommonButton theme='yellow' onClick={onTempButtonClick}>
          임시저장
        </CommonButton>
      )}
      {authPg === '상세' && cancelYES && 본인신청건 && (
        <CommonButton onClick={() => onCancelButtonClick?.()}>결재취소</CommonButton>
      )}
      {authPg === '결재' && <CommonButton onClick={() => onApprovalButtonClick?.()}>결재</CommonButton>}
      {authPg === '결재' && <CommonButton onClick={() => onRejectButtonClick?.()}>반려</CommonButton>}
      <CommonButton theme='gray' onClick={onCloseButtonClick}>
        {onRequestButtonClick || onTempButtonClick ? '취소' : '닫기'}
      </CommonButton>
    </ButtonContainer>
  );
};

const ButtonContainer = styled(Stack)`
  position: sticky;
  bottom: 0;
  flex: 1;
  width: 100%;
  background: white;
  z-index: 1;
  padding: 10px 0;
`;
export default CommonRequestBottomButtons;
