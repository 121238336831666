import { useUserInfo } from '@components/common/molecules/CommonUserInfo';
import { currentVacationSearchYear } from '@pages/vacation/templates/VacationSearchForm';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { axiosInstance, useRequest } from 'src/axios/fetcher';

export interface IVacationDateRequestParam {
  gntCd?: string;
  sYmd?: string;
  eYmd?: string;
  applYmd?: string;
  reqSHm?: string;
  reqEHm?: string;
  restTime?: number;
}

export interface IVacationStatResponseItem {
  year: number;
  sabun: string;
  gntCd: string;
  gntNm: string;
  creCnt: string;
  useCnt: string;
  usedCnt: string;
  restCnt: string;
  frdCnt: string;
  comCnt: string;
  useSYmd: string;
  useEYmd: string;
  baseYmd: string;
  applYmd: string;
  applStatusCd: string;
  applSeq: string;
  applSabun: string;
  applInSabun: string;
  holDay: string;
  closeDay: string;
  updateYn: string;
  gntReqReson: string;
  fileSeq: string;
  note2: string;
  sYmd: string;
  eYmd: string;
}
export interface IVacationListItemResponseItem {
  year: number;
  sabun: string;
  gntCd: string;
  gntNm: string;
  creCnt: string;
  useCnt: string;
  usedCnt: string;
  restCnt: string;
  frdCnt: string;
  comCnt: string;
  useSYmd: string;
  useEYmd: string;
  baseYmd: string;
  applYmd: string;
  applStatusCd: string;
  applStatusCdNm: string;
  applSeq: string;
  applSabun: string;
  applInSabun: string;
  sYmd: string;
  eYmd: string;
  holDay: string;
  closeDay: string;
  updateYn: string;
  gntReqReson: string;
  fileSeq: string;
  note2: string;
  reqSHm: string;
  reqEHm: string;
  restTime: string;
  requestHour: string;
  changeYmd: string;
  payType: string;
  businessPlaceCd: string;
  orgCd: string;
  workteamCd: string;
  gntResonCd: string;
  changeToYmd: string;
  sabbathGb: string;
  reqSGb: string;
  reqEGb: string;
  jikchakNm: string;
  orgNm: string;
  memo: string;
  occCd: string;
  famCd: string;
  subSabun: string;
  pregYmd: string;
  pregWeek: string;
  multiBirthYn: string;
  divUseYn: string;
  misStiTerm: string;
  reqSHmPre: string;
  reqEHmPre: string;
  restTimePre: string;
  requestHourPre: string;
  misStiYmd: string;
  gntGubunCd: string;
}

export interface IVacationPostParam {
  searchApplSabun?: string; // 5612
  gntCd?: string; // 14
  sYmd?: string; //20220208
  sYmd2?: string;
  eYmd?: string; //20220301
  applYmd?: string;
  eYmd2?: string;
  holDay?: string; // 2
  holDay2?: string;
  closeDay?: number; // 2
  closeDay2?: string;
  searchApplSeq?: string; // 2022020800099
  gntReqReson?: string; // 신청사유내용입니다.
  reqSHm?: string;
  reqSHm2?: string;
  restTime?: string;
  restTime2?: string;
  requestHour?: string;
  requestHour2?: string;
  occCd?: string;
  famCd?: string;
  pregYmd?: string;
  pregYmd2?: string;
  pregYmd3?: string;
  pregWeek?: string;
  multiBirthYn?: string;
  multiBirthYn2?: string;
  divUseYn?: string;
  misStiTerm?: string;
  misStiYmd?: string;
  reqSHm1?: string;
  restTime1?: string;
  requestHour1?: string;
}

export interface IGntCodeItem {
  code: string;
  codeNm: string;
  gntGubunCd: string;
}

// 잔여휴가내역 조회
export const useVacationState = () => {
  const { sabun: searchSabun } = useUserInfo();
  const year = useRecoilValue(currentVacationSearchYear);
  return useRequest<IVacationStatResponseItem>(
    year
      ? {
          url: '/dhl/kr/myhr/timemgt/searchVacationStat',
          params: { searchSabun, year },
        }
      : null,
  );
};

// 근태 리스트 조회
export const useVacationList = () => {
  const { sabun: searchSabun } = useUserInfo();
  const year = useRecoilValue(currentVacationSearchYear);
  const resultSwr = useRequest<IVacationListItemResponseItem[]>({
    url: `/dhl/kr/myhr/timemgt/searchUsedVacationList`,
    params: { searchSabun, year },
  });

  const { mutate } = resultSwr;
  useEffect(() => {
    mutate();
  }, [year, mutate]);
  return resultSwr;
};

// 근태코드 조회
export const useGntCodeList = () => {
  return useRequest<IGntCodeItem[]>({
    url: '/dhl/kr/myhr/timemgt/searchGntCodeList',
  });
};

// 근태상세 선택코드 조회
export const useDtlCodeList = (gntGubunCd?: string, searchApplCd?: string) => {
  return useRequest<IGntCodeItem[]>({
    url: '/dhl/kr/myhr/timemgt/vacationCodeList',
    params: { gubun: gntGubunCd, gntDtlCd: searchApplCd },
  });
};

// 근태 상세정보 조회
export const useVacationDetail = (applSeq?: string) => {
  const { sabun: searchSabun } = useUserInfo();
  return useRequest<IVacationListItemResponseItem>({
    url: '/dhl/kr/myhr/timemgt/getVacationAppDetList',
    params: { searchSabun, applSeq },
  });
};

// 근태 세부내역 저장
export const postVacation = async (params: IVacationPostParam) => {
  return await axiosInstance.post('/dhl/kr/myhr/timemgt/saveVacationAppDet', params);
};

// 근태상세 적용일수 조회
export const useVacationApplyDate = (params: IVacationDateRequestParam) => {
  const { sabun: searchSabun } = useUserInfo();
  return useRequest<{ closeDay: number; restTime: number }>({
    url: '/dhl/kr/myhr/timemgt/getVacationAppDetHolidayCnt',
    params: { ...params, searchSabun },
  });
};

// 근태상세 세부내역 기신청 건 조회
export const useDupleVacationCnt = (params: IVacationDateRequestParam) => {
  const { sabun: searchSabun } = useUserInfo();
  return useRequest<{ cnt: number }>({
    url: '/dhl/kr/myhr/timemgt/getVacationAppDetApplDayCnt',
    params: { ...params, searchSabun },
  });
};

// 보상휴가(code:26) 잔여시간 조회
export const useRemainTimeCnt = () => {
  const { sabun: searchSabun } = useUserInfo();
  return useRequest<{ remainTime: number }>({
    url: '/dhl/kr/myhr/timemgt/getRemainTimeMap',
    params: { searchSabun },
  });
};

// 난임휴가 지원 체크
export const useInfertilityCnt = () => {
  const { sabun: searchSabun } = useUserInfo();
  return useRequest<{ remainTime: number }>({
    url: '/dhl/kr/myhr/timemgt/getVacationAppDetApplCheckCnt',
    params: { searchSabun },
  });
};

export const deleteVacation = async (applSeq: string) => {
  const data = { applSeq }; // 서버로 보낼 데이터
  return await axiosInstance.post(`/dhl/kr/myhr/timemgt/vacation/${applSeq}`, data);
};
