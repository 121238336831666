import { deleteVacation, IVacationListItemResponseItem, useVacationList } from '@apis/vacation';
import CommonDeleteIcon from '@components/common/atoms/CommonDeleteIcon';
import Accordion from '@components/common/molecules/Accordion';
import { ApplicationStatus } from '@constants/ApplicationStatus';
import { COLOR } from '@constants/Color';
import { GlobalFormat } from '@constants/Format';
import LaunchIcon from '@mui/icons-material/Launch';
import { Divider, Paper, Stack } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import { formatDate } from 'src/util/commonUtil';
import styled from 'styled-components';
import VacationRequestModal from './VacationRequestModal';

const SetTitleColor = ({ applStatusCd, applStatusCdNm }: { applStatusCd: string; applStatusCdNm: string }) => {
  const backgroundColor = useMemo(() => {
    switch (applStatusCd) {
      case '11':
        return 'gray';

      case '21':
      case '31':
        return 'green';

      case '23':
      case '33':
        return '#e40303';

      case '99':
        return '#1144ae';

      default:
        return '';
    }
  }, [applStatusCd]);
  return <StatusFont style={{ backgroundColor }}>{applStatusCdNm}</StatusFont>;
};

const VacationItem: FC<IVacationListItemResponseItem> = (item) => {
  const [requestModalShow, isRequestModalShow] = useState(false);
  const { mutate } = useVacationList();
  const deleteHandle = async () => {
    await deleteVacation(item.applSeq);
    mutate();
  };
  return (
    <>
      <ItemContainer>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <HeadDiv>
            <TitleFont>{item.gntNm}</TitleFont>
            <SetTitleColor applStatusCd={item.applStatusCd} applStatusCdNm={item.applStatusCdNm}></SetTitleColor>
          </HeadDiv>

          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <HandleIcon
              onClick={(e) => {
                e.stopPropagation();
                isRequestModalShow(true);
              }}>
              <span style={{ fontWeight: 600 }}>상세보기</span>
              <div style={{ float: 'right' }}>
                <LaunchIcon />
              </div>
            </HandleIcon>
            {item.applStatusCd === ApplicationStatus.임시저장 && <CommonDeleteIcon deleteHandle={deleteHandle} />}
          </Stack>
        </Stack>
        <Divider style={{ margin: '0px ​0px 10px 0p' }} />
        <Row>
          <DetailTitleFont>신청일자</DetailTitleFont>
          <ContentFont>{formatDate(item.applYmd, GlobalFormat.DATE_DASH)}</ContentFont>
        </Row>
        <Row>
          <DetailTitleFont>신청기간</DetailTitleFont>
          <ContentFont>{`${formatDate(item.sYmd, GlobalFormat.DATE_DASH)} ~ ${formatDate(
            item.eYmd,
            GlobalFormat.DATE_DASH,
          )}`}</ContentFont>
        </Row>
        {item.holDay && (
          <Row>
            <DetailTitleFont style={{ flex: '25' }}>총일수</DetailTitleFont>
            <ContentFont style={{ flex: '15' }}>{`${item.holDay ?? 0}일`}</ContentFont>
            <DetailTitleFont style={{ flex: '20' }}>적용일수</DetailTitleFont>
            <ContentFont style={{ flex: '40' }}>{`${item.closeDay ?? 0}일`}</ContentFont>
          </Row>
        )}

        {item?.gntReqReson && (
          <Accordion>
            <Stack direction={'row'}>
              <DetailTitleFont>요청사항</DetailTitleFont>
              <ContentFont>
                <PaperContainer>
                  <ContentPaper elevation={1} style={{ background: COLOR.BG_WHITE }}>
                    <ContentPreFont>{item.gntReqReson}</ContentPreFont>
                  </ContentPaper>
                </PaperContainer>
              </ContentFont>
            </Stack>
          </Accordion>
        )}
      </ItemContainer>
      {item.applSeq && item.gntCd && requestModalShow && (
        <VacationRequestModal
          key={item.applSeq}
          onClose={() => {
            isRequestModalShow(false);
          }}
          open={requestModalShow}
          searchApplSeq={item.applSeq}
          searchApplCd={item.note2}
          searchGntGubunCd={item.gntGubunCd}
          searchGntCd={item.gntCd}
          title={item.gntNm}
          authPg={item.applStatusCd === ApplicationStatus.임시저장 ? '신청' : '상세'}
        />
      )}
    </>
  );
};

const ItemContainer = styled.div`
  padding: 13px;
  background-color: ${COLOR.BG_LIGHTGRAY};
  margin-bottom: 13px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 30%);
`;
const Row = styled.div`
  margin: 4px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const HeadDiv = styled.div`
  display: inline-flex;
`;

const TitleFont = styled.div`
  font-size: 16px;
  color: ${COLOR.BLACK};
  margin-bottom: 10px;
  padding-right: 10px;
`;

const StatusFont = styled.a`
  color: white;
  padding: 4px 8px 4px;
  border-radius: 10px;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 30%);
  height: 25px;
  font-size: 11px;
`;

const DetailTitleFont = styled.div`
  font-size: 12px;
  color: #a0a0a0;
  flex: 25;
  text-align: right;
  padding: 1px;
`;

const ContentFont = styled.div`
  font-size: 12px;
  color: ${COLOR.GRAY};
  flex: 75;
  padding-left: 10px;
`;

const HandleIcon = styled.div`
  color: ${COLOR.RED};
  float: right;
  :active {
    background-color: ${COLOR.ACTIVE_BACKGROUND};
  }
`;
const PaperContainer = styled.div`
  padding: 4px 4px 4px 0;
`;

const ContentPaper = styled(Paper)`
  padding: 12px;
`;

const ContentPreFont = styled(ContentFont)`
  margin-left: 0px;
  white-space: pre-wrap;
`;

export default VacationItem;
