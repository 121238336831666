import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Portal } from '@mui/material';
import React, { ReactNode } from 'react';
import { atom, useRecoilState } from 'recoil';

interface GlobalConfirmInterface {
  title?: string;
  contents?: ReactNode;
  okText?: string;
  okVisible?: boolean;
  cancelText?: string;
  cancelVisible?: boolean;
  onOkPress?: (data?: any) => void;
  onCancelPress?: (data?: any) => void;
}

export const globalConfirmAtom = atom<GlobalConfirmInterface | null>({
  key: 'globalConfirmAtom',
  default: null,
});

export const useGlobalConfirm = () => {
  const [globalConfirmConfig, setGlobalConfirmConfig] = useRecoilState(globalConfirmAtom);
  const defaultOption: GlobalConfirmInterface = {
    okText: '예',
    cancelText: '아니오',
    onCancelPress: () => setGlobalConfirmConfig(null),
    okVisible: true,
    cancelVisible: true,
  };
  return {
    visible: !!globalConfirmConfig,
    open: (options: GlobalConfirmInterface) => setGlobalConfirmConfig({ ...defaultOption, ...options }),
    close: () => setGlobalConfirmConfig(null),
    config: globalConfirmConfig,
    cancelVisible: globalConfirmConfig?.cancelVisible,
    okVisible: globalConfirmConfig?.okVisible,
  };
};

const CommonConfirm = () => {
  const { visible, config, cancelVisible, okVisible } = useGlobalConfirm();
  const { title, contents, okText, cancelText } = config ?? {};

  return (
    <Portal>
      <Dialog open={visible} onClose={config?.onCancelPress}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent style={{ margin: 12 }}>
          <DialogContentText style={{ whiteSpace: 'pre-wrap' }}>{contents}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {cancelVisible && (
            <Button autoFocus onClick={config?.onCancelPress}>
              {cancelText}
            </Button>
          )}
          {okVisible && <Button onClick={config?.onOkPress}>{okText}</Button>}
        </DialogActions>
      </Dialog>
    </Portal>
  );
};

export default CommonConfirm;
