import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { requestInterceptor } from './request';
import { responseErrorInterceptor, responseSuccessInterceptor } from './response';

export type GetRequest = AxiosRequestConfig | null;

interface Return<Data, Error>
  extends Pick<SWRResponse<AxiosResponse<Data>, AxiosError<Error>>, 'isValidating' | 'error' | 'mutate'> {
  data: Data | undefined;
  response: AxiosResponse<Data> | undefined;
}

export interface Config<Data = unknown, Error = unknown>
  extends Omit<SWRConfiguration<AxiosResponse<Data>, AxiosError<Error>>, 'initialData'> {
  initialData?: Data;
}

const baseOptions: AxiosRequestConfig = {
  baseURL: `${process.env.REACT_APP_API_HOST}`,
  timeout: 10000,
  headers: {
    // 'X-XSS-Protection': '0',
    'Content-Type': 'application/json',
  },
};

export const axiosInstance = axios.create(baseOptions);
axiosInstance.interceptors.request.use(requestInterceptor);
axiosInstance.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor);

export const authApi = axios.create(baseOptions);

export const useApi = () => {};

export const useRequest = <Data = unknown, Error = unknown>(
  request?: GetRequest,
  config: Config<Data, Error> = {},
): Return<Data, Error> => {
  const {
    data: response,
    error,
    isValidating,
    mutate,
  } = useSWR<AxiosResponse<Data>, AxiosError<Error>>(request && JSON.stringify(request), () => axiosInstance.request(request!), {
    ...config,
  });

  return {
    data: response && response.data,
    response,
    error,
    isValidating,
    mutate,
  };
};
