import { Button, Paper } from '@mui/material';
import React, { CSSProperties, FC, ReactNode, ReactText } from 'react';
import { useNavigate } from 'react-router-dom';
import { EnumRouteUrl } from 'src/routers/Routers';
import { useAuthorization } from 'src/util/auth';
import styled, { css, keyframes } from 'styled-components';

interface IMenuButtonProps {
  title?: ReactText;
  icon: ReactNode;
  direction: 'left' | 'right';
  delay: number;
  path?: EnumRouteUrl;
}

const MenuButton: FC<IMenuButtonProps> = ({ title, icon, direction, delay, path }) => {
  const styles: CSSProperties = {
    background: '#f0f0f0',
    fontSize: 17,
    fontWeight: 900,
    justifyContent: 'space-evenly',
    paddingTop: 20,
    color: '#7f7f7f',
  };
  const navigate = useNavigate();
  const { isLoggedin, handleLogin } = useAuthorization();
  return (
    <MenuItemContainer
      direction={direction}
      delay={delay}
      onClick={() => {
        if (!isLoggedin) {
          handleLogin();
        } else {
          path && navigate(path);
        }
      }}>
      <MenuButtonContainer style={styles}>
        {icon}
        {title}
      </MenuButtonContainer>
    </MenuItemContainer>
  );
};
const leftAnimation = keyframes`
  0%{
    transform: translateX(-50vw);
  }

  100%{
    transform: translateX(0);
  }
`;
const rightAnimation = keyframes`
  0%{
    transform: translateX(50vw);
  }

  100%{
    transform: translateX(0);
  }
`;

const MenuItemContainer = styled(Paper)<{ direction: 'right' | 'left'; delay: number }>`
  flex: 1;
  height: 100%;
  /* ${({ direction }) => css`
    transform: translateX(${direction === 'left' ? '-50vw' : '50vw'});
  `}
  animation: ${({ direction }) => (direction === 'left' ? leftAnimation : rightAnimation)} 0.5s ease-in-out
    ${({ delay }) => delay}s forwards; */
  width: 100%;
`;

const MenuButtonContainer = styled(Button)`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;
export default MenuButton;
