import { useStudentFamilyName } from '@apis/studentFunds';
import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonSelect from '@components/common/molecules/CommonSelect';
import { FC } from 'react';

const TargetNameSelect: FC<{ famCd: string }> = ({ famCd }) => {
  const { data: targetNameCodeList } = useStudentFamilyName(famCd);

  return (
    <>
      <CommonFormField
        name={'famNm'}
        rules={[
          {
            required: true,
          },
        ]}>
        <CommonSelect
          title='대상자명'
          options={targetNameCodeList?.map((item) => ({
            label: item.codeNm,
            value: item.code,
          }))}
        />
      </CommonFormField>
    </>
  );
};

export default TargetNameSelect;
