import CommonContainer from '@components/common/atoms/CommonContainer';
import HorizontalBlank from '@components/common/atoms/HorizontalBlank';
import { GlobalFormat } from '@constants/Format';
import { useHeaderStore } from '@stores/headerStore';
import moment from 'moment';
import { useEffect, useState } from 'react';
import PayslipList from './templates/PayslipList';
import PayslipSearchForm from './templates/PayslipSearchForm';

const PayslipPage = () => {
  const [year, setYear] = useState<string>(moment().format(GlobalFormat.YEAR));
  const { setConfig } = useHeaderStore();
  useEffect(() => {
    setConfig({ title: '급여명세서' });
  }, [setConfig]);
  return (
    <CommonContainer style={{ padding: 15 }}>
      <PayslipSearchForm year={year} setYear={setYear} />
      <HorizontalBlank height={15} />
      <PayslipList year={year} />
    </CommonContainer>
  );
};

export default PayslipPage;
