import { useFamilyEventStandardData } from '@apis/familyEvent';
import { useUserInfo } from '@components/common/molecules/CommonUserInfo';
import { COLOR } from '@constants/Color';
import { GlobalFormat } from '@constants/Format';
import { Paper } from '@mui/material';
import { Moment } from 'moment';
import React, { FC, useMemo } from 'react';
import NumberFormat from 'react-number-format';
import { formatDate } from 'src/util/commonUtil';
import styled from 'styled-components';
interface IMoneyResultProps {
  occCd?: string;
  famCd?: string;
  occDate?: Moment;
}
const MoneyResult: FC<IMoneyResultProps> = ({ occCd, famCd, occDate }) => {
  const searchApplYmd = useMemo(() => formatDate(occDate, GlobalFormat.DATE_API), [occDate]);
  const famCode = useMemo(() => famCd?.split('|')[0] ?? '', [famCd]);
  const { sabun: searchApplSabun } = useUserInfo();
  const { data: moneyInfo } = useFamilyEventStandardData({ occCd, famCd: famCode, searchApplYmd, searchApplSabun });
  return (
    <Container>
      <Item>
        <TitleFont>사우회</TitleFont>
        <AmountFont>
          <NumberFormat value={moneyInfo?.[0]?.occMon2 ?? 0} displayType={'text'} thousandSeparator={true} suffix={' 원'} />
        </AmountFont>
      </Item>
      <Item>
        <TitleFont>회사</TitleFont>
        <AmountFont>
          <NumberFormat value={moneyInfo?.[0]?.occMon1 ?? 0} displayType={'text'} thousandSeparator={true} suffix={' 원'} />
        </AmountFont>
      </Item>
      <Item>
        <TitleTotalFont>합계</TitleTotalFont>
        <AmountFont>
          <NumberFormat
            value={
              Number(moneyInfo?.[0]?.occMon1 ?? 0) + Number(moneyInfo?.[0]?.occMon2 ?? 0) === 0
                ? 0
                : Number(moneyInfo?.[0]?.occMon1) + Number(moneyInfo?.[0]?.occMon2)
            }
            displayType={'text'}
            thousandSeparator={true}
            suffix={' 원'}
          />
        </AmountFont>
      </Item>
    </Container>
  );
};

const Container = styled(Paper)`
  display: flex;
  background-color: ${COLOR.BG_GRAY} !important;
  padding: 10px 0;
  margin-bottom: 12px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const TitleFont = styled.span`
  color: ${COLOR.RED};
  font-size: 14px;
`;

const TitleTotalFont = styled.span`
  color: rgb(0, 85, 170);
  font-size: 14px;
`;

const AmountFont = styled.span`
  color: ${COLOR.GRAY};
  font-size: 12px;
`;

export default MoneyResult;
