import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonInput from '@components/common/atoms/CommonInput';
import { FC } from 'react';

const StdyyInput: FC = () => {
  return (
    <>
      <CommonFormField
        name={'stdyy'}
        rules={[
          {
            required: true,
          },
        ]}>
        <CommonInput label='지원년도' inputProps={{ maxLength: 4 }} pattern={'number'} />
      </CommonFormField>
    </>
  );
};

export default StdyyInput;
