import { ICommonRequestInfoInterface } from '@apis/common';
import { IFamilyEventPostParam } from '@apis/familyEvent';
import {
  IStudentFundsPostParam,
  postStudentFunds,
  useStudentFundsBankTypeList,
  useStudentFundsDetail,
  useStudentFundsList,
} from '@apis/studentFunds';
import CommonForm, { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonInput from '@components/common/atoms/CommonInput';
import { currentCommonMessageAtom } from '@components/common/atoms/CommonMessage';
import { useUserInfo } from '@components/common/molecules/CommonUserInfo';
import CommonRequestModal from '@components/common/organisms/CommonRequestModal';
import { GlobalFormat } from '@constants/Format';
import moment, { Moment } from 'moment';
import Form from 'rc-field-form';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { formatDate } from 'src/util/commonUtil';
import styled from 'styled-components';
import BankGroup from './BankGroup';
import DiverSelect from './DiverSelect';
import FamilyCodeSelect from './FamilyCodeSelect';
import FundsInfo from './FundsInfo';
import RequestMemo from './RequestMemo';
import SchyySelect from './SchyySelect';
import StdyyInput from './StdyyInput';
import StudentApprovalResult from './StudentApprovalResult';
import StudentFundBankTypeSelect from './StudentFundBankTypeSelect';
import StudentFundsAmount, { useLimiteInfo } from './StudentFundsAmount';
import StudentFundSelect from './StudentFundSelect';
import SupyyInput from './SupyyInput';
import TargetNameSelect from './TargetNameSelect';

interface IFamilyEventRequestModalProps {
  onClose: () => void;
  open: boolean;
  applSeq?: string;
  authPg?: AUTH_TYPE;
}

export interface IFamilyEventFormParam extends Omit<IFamilyEventPostParam, 'famBirYmd' | 'occDate'> {
  famBirYmd: Moment;
  occDate: Moment;
}

const StudentFundsRequestModal: FC<IFamilyEventRequestModalProps> = ({ authPg, open, onClose, applSeq }) => {
  const { data } = useStudentFundsDetail(open ? applSeq : undefined);
  const [form] = Form.useForm();
  const detailData = useMemo(() => data?.[0], [data]);

  useEffect(() => {
    if (applSeq && detailData) {
      form.setFieldsValue({
        ...detailData,
      });
      setSchType(detailData.schType);
      setFamCd(detailData.famCd);
      setStdyy(detailData.stdyy);
    }
  }, [detailData, applSeq, form]);

  const initialValues = {
    occDate: moment(),
    stdyy: formatDate(moment(), GlobalFormat.YEAR),
    bankType: '99',
  };

  const { data: bankTypeForAccountDataList } = useStudentFundsBankTypeList();

  //값이 변할떄마다 화면변경시 필요한 state변수 과도한 rerender를 막기위해 각자 따로선언
  const [schType, setSchType] = useState<string>('');
  const [famCd, setFamCd] = useState<string>('');
  const [famNm, setFamNm] = useState<string>('');
  const [commonInfo, setCommonInfo] = useState<ICommonRequestInfoInterface>();
  const [stdyy, setStdyy] = useState<string>('');
  const [bankType, setBankType] = useState<string>('');
  const setMessage = useSetRecoilState(currentCommonMessageAtom);
  const { mutate } = useStudentFundsList();
  const { sabun: applSabun } = useUserInfo();
  const { applYn, limitYn, resultMon } = useLimiteInfo({
    searchStdyy: stdyy,
    searchFamCd: famCd,
    searchFamNm: famNm,
    searchSchType: schType,
    searchApplSeq: commonInfo?.applSeq,
  });

  const checkAmount = async () => {
    var applamt = 0;
    var searchApplamt = Number(form.getFieldValue('applamt'));

    if (authPg === '신청') {
      // 신청시에는 신청금액
      applamt = searchApplamt;

      if (applYn === 'N') {
        setMessage({ open: true, message: '잔여 한도가 없거나 학자금 지원대상 초과입니다. 다시 확인해주세요.', type: 'error' });
        return false;
      }

      if (applamt < 1) {
        setMessage({ open: true, message: '신청금액을 다시 확인해주세요.', type: 'error' });
        return false;
      }
    } else {
      // 결재시에는 지원금액
      // applamt = parseInt($("#searchPaymon").val().replace(/,/g, ""));

      if (applamt > searchApplamt) {
        setMessage({ open: true, message: '신청금액보다 지원금액이 큽니다. 다시 확인해주세요.', type: 'error' });
        return false;
      }
    }
    if (limitYn === 'Y') {
      if (resultMon - applamt < 0) {
        setMessage({ open: true, message: '학자금지원 한도금액 초과입니다. 신청금액을 다시 확인해주세요.', type: 'error' });
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async (params: IStudentFundsPostParam) => {
    try {
      const isValid = await checkAmount();
      if (!isValid) {
        return;
      }

      const applYmd = formatDate(moment(), GlobalFormat.DATE_API);
      await postStudentFunds({
        ...commonInfo,
        ...params,
        applYmd,
        famres: params.famNm,
        applSabun,
        applSeq: applSeq || commonInfo?.applSeq,
      });
      mutate();
      onClose();
      setMessage({
        open: true,
        message: '신청이 완료되었습니다.',
        type: 'success',
      });
    } catch (e: any) {
      setMessage({
        open: true,
        message: e?.response?.data?.message,
        type: 'error',
      });
    }
  };
  return (
    <CommonRequestModal
      open={open}
      onClose={onClose}
      title={'학자금 신청'}
      applCd={'103'}
      applSeq={applSeq}
      authPg={authPg}
      onSuccessRequestCommonInfo={setCommonInfo}
      onSubmit={async () => {
        await form.validateFields();
        const isValid = await checkAmount();
        if (!isValid) {
          return;
        }
        form.submit();
      }}
      onTemporary={async () => {
        await form.validateFields();
        const isValid = await checkAmount();
        if (!isValid) {
          return;
        }
        form.submit();
      }}
      onCancelDone={() => {
        mutate();
      }}>
      <CommonForm
        readOnly={authPg === '상세'}
        form={form}
        onFinish={(params) => {
          handleSubmit(params);
        }}
        initialValues={initialValues}
        onValuesChange={(changedValue, { schType, stdyy, famCd, famNm, bankType }) => {
          setSchType(schType);
          setFamCd(famCd);
          setStdyy(stdyy);
          setBankType(bankType);
          setFamNm(famNm);

          if (changedValue?.bankType) {
            const accountDataString = bankTypeForAccountDataList?.find((item) => item.code?.startsWith(bankType));
            const accountDataList = accountDataString?.code?.split('|') || [];
            const [payBankCd = '', payAccNo = '', payAccNm = ''] = accountDataList;
            form.setFieldsValue({ payBankCd, payAccNo, payAccNm });
          }
        }}>
        <ModalContainer>
          <StudentFundSelect key={'schType'} />
          {schType && <FundsInfo schType={schType} />}
          <CommonFormField
            name={'schname'}
            rules={[
              {
                required: true,
              },
            ]}>
            <CommonInput label='학교명' />
          </CommonFormField>
          <FamilyCodeSelect key={'famCd'} />
          {famCd && <TargetNameSelect famCd={famCd} key={'famNm'} />}
          <SupyyInput />
          <SchyySelect key={'schyy'} />
          <DiverSelect key={'diver'} />
          {famNm && schType && (
            <StudentFundsAmount
              searchStdyy={stdyy}
              searchFamCd={famCd}
              searchSchType={schType}
              searchFamNm={famNm}
              searchApplSeq={commonInfo?.applSeq}
            />
          )}
          <StdyyInput />
          <StudentFundBankTypeSelect key={'bankType'} />
          <BankGroup bankType={bankType} />
          <RequestMemo key={'memo'} />
          {applSeq && <StudentApprovalResult detailData={detailData} />}
        </ModalContainer>
      </CommonForm>
    </CommonRequestModal>
  );
};

const ModalContainer = styled.div`
  overflow-y: scroll;
  position: relative;
  padding: 15px 0;
  display: grid;
  gap: 16px;
`;

export default StudentFundsRequestModal;
