import { useUserInfo } from '@components/common/molecules/CommonUserInfo';
import { GlobalFormat } from '@constants/Format';
import { currentLoanSearchYear } from '@pages/loan/templates/LoanSearchForm';
import moment from 'moment';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { axiosInstance, useRequest } from 'src/axios/fetcher';
import { ICommonCodeItem } from 'src/util/commonCode';
import { formatDate } from 'src/util/commonUtil';

export interface ILoanListInterface {
  agreeYmd: string;
  applStatusCd: string;
  applInSabun: string;
  applSabun: string;
  sabun: string;
  applYmd: string;
  applSeq: string;
  loanKindCd: string;
  repayTypeCd: string;
  loanYmd: string;
  loanMidYmd: string;
  loanAmt: string;
  repayAmt: string;
  remainAmt: string;
  interastAmt: string;
  repayTotAmt: string;
  intRate: string;
  lastRepayDate: string;
  extYear: string;
  signDate: string;
  repayYear: string;
  loanUseCd: string;
  loanUseNm: string;
  remarks: string;
  bankCd: string;
  accNo: string;
  accNm: string;
}

export interface ILoanInfoItem {
  edate: string;
  limitAmt: string;
  note: string;
  prDoc: string;
  rate: string;
  sdate: string;
  yearLimitCnt: string;
}
export interface ILoanPostParam {
  sabun: string;
  applYmd: string;
  applSeq: string;
  loanKindCd: string;
  repayTypeCd: string;
  loanYmd: string;
  loanMidYmd: string;
  loanAmt: string;
  repayAmt: string;
  interastAmt: string;
  repayTotAmt: string;
  intRate: string;
  lastRepayDate: string;
  extYear: string;
  signDate: string;
  loanUseCd: string;
  loanUseNm: string;
  remarks: string;
  bankCd: string;
  accNo: string;
  accNm: string;
  payAgreeYn: string;
  retAgreeYn: string;
  famCd: string;
  famNm: string;
  diseaseNm: string;
}
export interface ILoanDetailInterface {
  agreeYmd: string;
  applStatusCd: string;
  applInSabun: string;
  sabun: string;
  applYmd: string;
  applSeq: string;
  loanKindCd: string;
  repayTypeCd: string;
  loanYmd: string;
  loanMidYmd: string;
  loanAmt: number;
  repayAmt: number;
  remainAmt: number;
  interastAmt: number;
  repayTotAmt: number;
  intRate: string;
  lastRepayDate: string;
  extYear: string;
  signDate: string;
  repayYear: string;
  loanUseCd: string;
  loanUseNm: string;
  remarks: string;
  bankCd: string;
  accNo: string;
  accNm: string;
  payAgreeYn: string;
  retAgreeYn: string;
  famCd: string;
  famNm: string;
  diseaseNm: string;
}

export const useLoanList = () => {
  const { sabun } = useUserInfo();
  const year = useRecoilValue(currentLoanSearchYear);
  const resultSwr = useRequest<ILoanListInterface[]>(
    sabun && year
      ? {
          url: '/dhl/kr/myhr/welfare/loans',
          params: { sabun, year },
        }
      : null,
  );

  const { mutate } = resultSwr;

  useEffect(() => {
    mutate();
  }, [year, mutate]);
  return resultSwr;
};

export const useLoanType = () => {
  const searchApplYmd = formatDate(moment(), GlobalFormat.DATE_API);
  return useRequest<ICommonCodeItem>({
    url: '/dhl/kr/myhr/welfare/loanType',
    params: { searchApplYmd },
  });
};

interface ILoanStandardInterface {
  dcDbLastAmt: number;
  edate: string;
  intRate: string;
  limitAmt: number;
  loanCnt: number;
  loanKindCd: string;
  loanYmd: string;
  prDoc: string;
  reReqMonth: number;
  remainChk: string;
  remarks: string;
  repayTypeCd: string;
  repayYear: string;
  residualAmt: number;
  sdate: string;
}
export const useLoanStandard = (loanKindCd?: string) => {
  const searchDate = formatDate(moment(), GlobalFormat.DATE_API);
  return useRequest<ILoanStandardInterface>(
    loanKindCd
      ? {
          url: '/dhl/kr/myhr/welfare/loanStandard',
          params: { loanKindCd, searchDate },
        }
      : null,
  );
};

export const useLoanDetail = (searchApplSeq?: string) => {
  return useRequest<ILoanDetailInterface>(
    searchApplSeq
      ? {
          url: '/dhl/kr/myhr/welfare/loan',
          params: { searchApplSeq },
        }
      : null,
  );
};

export const useLoanTypeDetail = (searchApplYmd?: string) => {
  return useRequest<ICommonCodeItem[]>(
    searchApplYmd
      ? {
          url: '/dhl/kr/myhr/welfare/loanType',
          params: { searchApplYmd },
        }
      : null,
  );
};

interface ILoanCountInterface {
  loanKindCd: string;
  sdate: string;
  edate: string;
  repayTypeCd: string;
  repayYear: string;
  intRate: string;
  reReqMonth: string;
  limitAmt: number;
  remarks: string;
  prDoc: string;
  dcDbLastAmt: string;
  loanYmd: string;
  loanCnt: number;
  residualAmt: number;
  remainChk: string;
}

export const useLoanCount = (loanKindCd: string) => {
  const { sabun } = useUserInfo();
  return useRequest<ILoanCountInterface>({
    url: '/dhl/kr/myhr/welfare/loanCnt',
    params: { sabun, loanKindCd },
  });
};

export const getDiseaseFamilyName = ({ famCd, sabun }: { famCd?: string; sabun?: string }) => {
  const queryUrl = queryString.stringifyUrl({
    url: '/dhl/kr/myhr/welfare/sicknessCompensationFamNm',
    query: { famCd, sabun },
  });
  return axiosInstance.get(queryUrl);
};

export const getLoanRemainAmountCheck = ({ loanAmt, sabun }: { loanAmt?: number; sabun?: string }) => {
  const queryUrl = queryString.stringifyUrl({
    url: '/dhl/kr/myhr/welfare/loanRemainAmtChk',
    query: { loanAmt, sabun },
  });
  return axiosInstance.get(queryUrl);
};

export const postLoan = async (params: ILoanPostParam & any) => {
  return await axiosInstance.post('/dhl/kr/myhr/welfare/loan ', params);
};
export const deleteLoan = async (applSeq: string) => {
  const data = { applSeq }; // 서버로 보낼 데이터
  return await axiosInstance.post(`/dhl/kr/myhr/welfare/loan/${applSeq}`, data);
};
