import CommonContainer from '@components/common/atoms/CommonContainer';
import HorizontalBlank from '@components/common/atoms/HorizontalBlank';
import { useHeaderStore } from '@stores/headerStore';
import { useEffect } from 'react';
import LoanList from './templates/LoanList';
import LoanSearchForm from './templates/LoanSearchForm';

const LoanPage = () => {
  const { setConfig } = useHeaderStore();
  useEffect(() => {
    setConfig({ title: '대출신청' });
  }, [setConfig]);
  return (
    <CommonContainer style={{ padding: 15 }}>
      <LoanSearchForm />
      <HorizontalBlank height={20} />
      <LoanList />
    </CommonContainer>
  );
};

export default LoanPage;
