import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Form from 'rc-field-form';
import styled from 'styled-components';
import AddDiseaseItem from './AddDiseaseItem';

const AddDiseaseRequestTemplate = () => {
  return (
    <Container>
      <Form.List name='list'>
        {(fields, { add, remove }) => (
          <>
            <Header>
              <span>질환위로금 신청</span>
              <Tooltip title='Add' arrow>
                <Button color={'primary'} onClick={() => add(null, 0)}>
                  [신규 & 추가입력]
                </Button>
              </Tooltip>
            </Header>
            {fields.map((field) => {
              return <AddDiseaseItem key={field.key} remove={remove} field={field} />;
            })}
          </>
        )}
      </Form.List>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export default AddDiseaseRequestTemplate;
