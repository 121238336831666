import { useGlobalConfirm } from '@components/common/atoms/CommonConfirm';
import { CommonFormField } from '@components/common/atoms/CommonForm';
import { COLOR } from '@constants/Color';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

interface ITermsProps {}

const Terms: FC<ITermsProps> = () => {
  return (
    <CommonFormField name={'terms'}>
      <CheckboxGroup />
    </CommonFormField>
  );
};

interface ICheckboxGroupProps {
  value?: string[];
  onChange?: (values: string[]) => void;
}

const CheckboxGroup: FC<ICheckboxGroupProps> = ({ value, onChange }) => {
  const [selectedValues, setSelectedValues] = useState<string[] | undefined>(value);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const parameterKey = event.target.value;
    if (checked) {
      const checkedArray = [...(value?.filter((item) => item !== parameterKey) ?? []), parameterKey];
      setSelectedValues(checkedArray);
      onChange?.([...(value?.filter((item) => item !== parameterKey) ?? []), parameterKey]);
    } else {
      const checkedArray = [...(value?.filter((item) => item !== parameterKey) ?? [])];
      setSelectedValues(checkedArray);
      onChange?.(checkedArray);
    }
  };

  useEffect(() => {
    value !== undefined && setSelectedValues(value);
  }, [value]);

  const { open: confirmOpen, close: confirmClose } = useGlobalConfirm();
  const showDetail = (e: any, name: string) => {
    const contents =
      name === 'payAgreeYn'
        ? '대출금 지급일의 당월부터 매월 원금균등분할상환 방식으로 급여 공제할 것을 동의합니다.(매월 20일 이후 대출금 지급일의 경우 익월부터 급여 공제합니다.)'
        : '대출기간 중 퇴직할 경우 본인이 수령할 급여 및 퇴직연금에서 미상환 대출잔액을 우선 공제하며, 퇴직연금 사업자와 퇴직연금 수령금 공제 절차에 협조 할 것에 동의하며 향후 이와 관련하여 어떠한 이의도 제기하지 않을 것을 확약합니다.';
    e.stopPropagation();
    confirmOpen({
      title: '동의',
      contents,
      cancelText: '확인',
      okVisible: false,
      onOkPress: async () => {
        confirmClose();
      },
    });
  };

  const selectedValuesEmptyArray = useMemo(() => {
    return selectedValues ? selectedValues : [];
  }, [selectedValues]);
  return (
    <FormGroup>
      <LabelContainer>
        <FormControlLabel
          control={
            <Switch value={'payAgreeYn'} checked={selectedValuesEmptyArray?.includes('payAgreeYn')} onChange={handleChange} />
          }
          label={'급여공제 동의'}
        />
        <DetailButton onClick={(e) => showDetail(e, 'payAgreeYn')}>자세히 보기</DetailButton>
      </LabelContainer>
      <LabelContainer>
        <FormControlLabel
          control={
            <Switch value={'retAgreeYn'} checked={selectedValuesEmptyArray?.includes('retAgreeYn')} onChange={handleChange} />
          }
          label={'퇴직연금우선공제 동의'}
        />
        <DetailButton onClick={(e) => showDetail(e, 'retAgreeYn')}>자세히 보기</DetailButton>
      </LabelContainer>
    </FormGroup>
  );
};

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DetailButton = styled.a`
  color: ${COLOR.RED};
  font-size: 12px;
`;

export default Terms;
