import { downloadFileList } from '@apis/fileUpload';
import EXCEL_ICON from '@assets/images/excelIcon.png';
import PDF_ICON from '@assets/images/pdfIcon.png';
import { COLOR } from '@constants/Color';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import React, { FC, useMemo } from 'react';
import { getExtensionOfFilename } from 'src/util/commonUtil';
import styled from 'styled-components';

interface IThumbnailNotImageProps {
  fileSeq: string;
  seqNo: string;
  rfileNm: string;
}

const EXCEL = ['xlsx', 'xlsm', 'xlsb', 'xltx', 'xltm', 'xls', 'xlt', 'xls', 'xml', 'xml', 'xlam', 'xla', 'xlw', 'xlr'];
const PDF = ['pdf'];
const ThumbnailNotImage: FC<IThumbnailNotImageProps> = ({ fileSeq, seqNo, rfileNm }) => {
  const ext = getExtensionOfFilename(rfileNm);
  const icon = useMemo(() => {
    if (EXCEL.includes(ext)) {
      return <Icon src={EXCEL_ICON} />;
    } else if (PDF.includes(ext)) {
      return <Icon src={PDF_ICON} />;
    } else {
      return <DownloadRoundedIcon style={{ fontSize: 40 }} />;
    }
  }, [ext]);

  return (
    <ThumbnailNotImageContainer
      onClick={() => {
        downloadFileList({ fileSeq, seqNo, rfileNm });
      }}>
      {icon}
    </ThumbnailNotImageContainer>
  );
};

const ThumbnailNotImageContainer = styled.div`
  height: 90px;
  border-radius: 15px;
  border: 1px solid ${COLOR.LIGHT_GRAY};
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
`;
export default ThumbnailNotImage;
