import { deleteFileBySeqNo, downloadBinaryFileList, IUploadedFileListItemInterface } from '@apis/fileUpload';
import { currentCommonMessageAtom } from '@components/common/atoms/CommonMessage';
import { COLOR } from '@constants/Color';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { AxiosResponse } from 'axios';
import { FC, useCallback, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { KeyedMutator } from 'swr';
import { useGlobalConfirm } from '../atoms/CommonConfirm';
import DetailImageModal from '../atoms/DetailImageModal';
import ThumbnailNotImage from '../atoms/ThumbnailNotImage';
import { useUserInfo } from './CommonUserInfo';

const FileThumbnail: FC<{
  applStatusCd?: string;
  imageInfo: IUploadedFileListItemInterface;
  reFetch: KeyedMutator<AxiosResponse<IUploadedFileListItemInterface[], any>>;
}> = ({ applStatusCd, imageInfo, reFetch }) => {
  const { rfileNm, fileSeq, seqNo } = imageInfo;
  const [isImage, setIsImage] = useState(true);
  const [openDetailModal, isOpenDetailModal] = useState(false);
  const { sabun } = useUserInfo();
  const { open: confirmOpen, close: confirmClose } = useGlobalConfirm();
  const [blob, setBlob] = useState<any>('');
  const setMessage = useSetRecoilState(currentCommonMessageAtom);
  useEffect(() => {
    setIsImage(true);
  }, [blob]);

  const getDownloadBinaryFile = useCallback(async () => {
    const res = await downloadBinaryFileList({ fileSeq, seqNo });
    setBlob(`data:image;base64,${res.data}`);
  }, [fileSeq, seqNo]);

  const deleteHandle = async () => {
    await deleteFileBySeqNo({ fileSeq, seqNo, sabun });
    confirmClose();
    reFetch();
  };

  useEffect(() => {
    getDownloadBinaryFile();
  }, [imageInfo, getDownloadBinaryFile]);

  return (
    <ThumbnailContainer onClick={(e) => e.stopPropagation()}>
      <ThumbnailDeleteIcon
        onClick={() => {
          if (applStatusCd != '11' && applStatusCd) {
            setMessage({
              open: true,
              message: '처리완료된 파일을 삭제할 수 없습니다.',
              type: 'error',
            });
          } else {
            confirmOpen({
              title: '첨부파일',
              contents: `해당 파일을 삭제하시겠습니까?`,
              onOkPress: () => {
                deleteHandle();
                //   postFileBySeqNo({ fileSeq, seqNo });
              },
            });
          }
        }}
      />
      <ThumbnailFileNameText>{rfileNm}</ThumbnailFileNameText>
      {isImage && blob ? (
        <ThumbnailImage
          alt={rfileNm}
          src={blob}
          onError={(e) => {
            setIsImage(false);
          }}
          onClick={(e) => {
            isOpenDetailModal(true);
          }}
        />
      ) : (
        <ThumbnailNotImage fileSeq={fileSeq} seqNo={seqNo} rfileNm={rfileNm} />
      )}
      {openDetailModal && (
        <DetailImageModal imageBase64={blob} open={openDetailModal} handleClose={() => isOpenDetailModal(false)} />
      )}
    </ThumbnailContainer>
  );
};

const ThumbnailImage = styled.img`
  height: 90px;
  border-radius: 15px;
  border: 1px solid ${COLOR.LIGHT_GRAY};
  width: 160px;
  vertical-align: bottom;
  object-fit: cover;
`;

const ThumbnailContainer = styled.div`
  font-size: 10px;
  margin: 5px;
  position: relative;
`;
const ThumbnailDeleteIcon = styled(CancelRoundedIcon)`
  position: absolute;
  color: red;
  right: -10px;
  top: -10px;
`;

const ThumbnailFileNameText = styled.span`
  position: absolute;
  padding: 10px;
  width: 100%;
  mix-blend-mode: difference;
  color: ${COLOR.ACTIVE_YELLOW};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  bottom: 0px;
  font-size: 12px;
`;

export default FileThumbnail;
