import { ICommonRequestInfoInterface } from '@apis/common';
import {
  IFamilyEventPostParam,
  postFamilyEvent,
  useFamilyEventDetail,
  useFamilyEventList,
  useFamilyEventStandardData,
} from '@apis/familyEvent';
import CommonContainer from '@components/common/atoms/CommonContainer';
import CommonForm from '@components/common/atoms/CommonForm';
import { currentCommonMessageAtom } from '@components/common/atoms/CommonMessage';
import { useUserInfo } from '@components/common/molecules/CommonUserInfo';
import CommonRequestModal from '@components/common/organisms/CommonRequestModal';
import { GlobalFormat } from '@constants/Format';
import moment, { Moment } from 'moment';
import Form from 'rc-field-form';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { useBankTypeList, useFamilyCodeList } from 'src/util/commonCode';
import { formatDate } from 'src/util/commonUtil';
import styled from 'styled-components';
import AdditionalDocumentInfo from './AdditionalDocumentInfo';
import FamilyApprovalResult from './FamilyApprovalResult';
import FamilyBirthday from './FamilyBirthday';
import FamilyCodeSelect from './FamilyCodeSelect';
import FamilyEventBankGroup from './FamilyEventBankGroup';
import MoneyResult from './MoneyResult';
import OccCodeSelect from './OccCodeSelect';
import OccDate from './OccDate';
import RequestMemo from './RequestMemo';
import SearchBankType from './SearchBankType';
import TargetName from './TargetName';

interface IFamilyEventRequestModalProps {
  onClose: () => void;
  open: boolean;
  authPg: AUTH_TYPE;
  applSeq?: string;
}

export interface IFamilyEventFormParam extends Omit<IFamilyEventPostParam, 'famBirYmd' | 'occDate'> {
  famBirYmd: Moment;
  occDate: Moment;
}

interface ISubSettingInterface {
  wreathYn?: YN;
  occServYn?: YN;
  payMon1?: number;
  payMon2?: number;
  seq?: string;
  bigo?: string;
  memo?: string;
  payYn?: string;
  payYm?: string;
  payYmd?: string;
  occSeq?: string;
  fileSeq?: string;
  prDoc?: string;
  // applSeq: string;
}
const useDataSetting = ({
  setSettings,
  occCd,
  famCd,
  occDate,
}: {
  setSettings: React.Dispatch<React.SetStateAction<ISubSettingInterface | undefined>>;
  occCd: string;
  famCd: string;
  occDate: Moment;
}) => {
  const searchApplYmd = formatDate(occDate, GlobalFormat.DATE_API);
  const famCode = useMemo(() => famCd?.split('|')[0] ?? '', [famCd]);
  const { sabun: searchApplSabun } = useUserInfo();
  const { data } = useFamilyEventStandardData({ occCd, famCd: famCode, searchApplYmd, searchApplSabun });

  useEffect(() => {
    const { wreathYn, occServYn, occMon1, occMon2, ...restData } = data?.[0] ?? {};
    setSettings({
      ...restData,
      wreathYn: wreathYn === 'Y' ? 'Y' : 'N',
      occServYn: occServYn === 'Y' ? 'Y' : 'N',
      payMon1: occMon1 ?? 0,
      payMon2: occMon2 ?? 0,
    });
  }, [data, setSettings]);
};

const FamilyEventRequestModal: FC<IFamilyEventRequestModalProps> = ({ authPg, open, onClose, applSeq }) => {
  const { data } = useFamilyEventDetail(open ? applSeq : null);
  const detailData = useMemo(() => data?.[0], [data]);
  const [form] = Form.useForm();
  const initialValues = {
    occDate: moment(),
    bankType: '99',
  };

  //값이 변할떄마다 화면변경시 필요한 state변수 과도한 rerender를 막기위해 각자 따로선언
  const [occCd, setOccCd] = useState<string>('');
  const [famCd, setFamCd] = useState<string>('');
  const [commonInfo, setCommonInfo] = useState<ICommonRequestInfoInterface>();
  const [bankType, setBankType] = useState<string>('');
  const [occDate, setOccDate] = useState<Moment>(initialValues.occDate);
  const [settings, setSettings] = useState<ISubSettingInterface>();
  const setMessage = useSetRecoilState(currentCommonMessageAtom);
  const { mutate } = useFamilyEventList();

  useEffect(() => {
    if (applSeq && detailData) {
      form.setFieldsValue({
        ...detailData,
        occDate: moment(detailData.occDate),
        famBirYmd: moment(detailData.famBirYmd),
      });
      setOccCd(detailData.occCd);
      setFamCd(detailData.famCd);
      setOccDate(moment(detailData.occDate));
      setBankType(detailData.bankType);
    }
  }, [detailData, applSeq, form]);

  const searchApplYmd = useMemo(() => formatDate(occDate, GlobalFormat.DATE_API), [occDate]);
  const { data: bankTypeForAccountDataList } = useBankTypeList({
    searchApplYmd,
  });
  const { data: familyForYnDataList } = useFamilyCodeList({ occCd, searchApplYmd, cmd: 'getOccAppDetFamCdList' });

  const [famCd_, familyYn, familyInfoYn] = useMemo(() => {
    const familyYnString = famCd ? familyForYnDataList?.find((item) => item.code?.startsWith(famCd)) : null;
    const familyYnList = familyYnString?.code?.split('|') || [];
    return familyYnList;
  }, [familyForYnDataList, famCd]);

  useDataSetting({ occCd, famCd, occDate, setSettings });

  const generateParam = (data: any) => {
    const params: IFamilyEventPostParam = {
      ...detailData,
      ...settings,
      ...data,
      occDate: formatDate(data.occDate, GlobalFormat.DATE_API),
      famBirYmd: formatDate(data.famBirYmd, GlobalFormat.DATE_API),
      famCd: famCd_,
      occMonYn: 'Y',
    };
    return params;
  };
  const { sabun: applSabun } = useUserInfo();
  const handleSubmit = async (params: IFamilyEventPostParam) => {
    try {
      await postFamilyEvent({ ...commonInfo, ...params, applSabun, applSeq: applSeq || commonInfo?.applSeq });
      mutate();
      onClose();
      setMessage({
        open: true,
        message: '신청이 완료되었습니다.',
        type: 'success',
      });
    } catch (e: any) {
      setMessage({
        open: true,
        message: e?.response?.data?.message,
        type: 'error',
      });
    }
  };
  return (
    <CommonRequestModal
      open={open}
      onClose={onClose}
      title={'경조신청'}
      authPg={authPg}
      onSuccessRequestCommonInfo={setCommonInfo}
      applCd={'104'}
      applSeq={applSeq}
      onSubmit={async () => {
        await form.validateFields();
        form.submit();
      }}
      onTemporary={async () => {
        await form.validateFields();
        form.submit();
      }}
      onCancelDone={() => {
        mutate();
      }}>
      <CommonForm
        readOnly={authPg === '상세'}
        noValidate={true}
        form={form}
        onFinish={(data) => {
          const params = generateParam(data);
          handleSubmit(params);
        }}
        initialValues={initialValues}
        onValuesChange={(changedValue, datas: any) => {
          const { occCd, famCd, occDate, bankType } = datas;
          setOccCd(occCd);
          setOccDate(occDate);
          setBankType(bankType);

          if (changedValue?.occCd) {
            form.resetFields(['famCd']);
            setFamCd('');
          } else {
            setFamCd(famCd);
          }
          if (changedValue?.bankType) {
            const accountDataString = bankType
              ? bankTypeForAccountDataList?.find((item) => item.code?.startsWith(bankType))
              : null;
            const accountDataList = accountDataString?.code?.split('|') || [];
            const [, bankCd = '', accNo = '', accNm = ''] = accountDataList;
            form.setFieldsValue({ bankCd, accNm, accNo });
          }
        }}>
        <ModalContainer>
          <CommonContainer>
            <OccCodeSelect />
            <AdditionalDocumentInfo prDoc={settings?.prDoc} />
          </CommonContainer>
          <OccDate occCd={occCd} />
          <FamilyCodeSelect occCd={occCd} occDate={occDate} />
          <TargetName famCd={famCd} occDate={occDate} familyYn={familyYn} />
          <FamilyBirthday familyInfoYn={familyInfoYn} occDate={occDate} occCd={occCd} />
          <MoneyResult occCd={occCd} occDate={occDate} famCd={famCd} />
          <SearchBankType form={form} occDate={occDate} />
          <FamilyEventBankGroup bankType={bankType} />
          <RequestMemo key={'memo'} />
          {applSeq && <FamilyApprovalResult detailData={detailData} />}
        </ModalContainer>
      </CommonForm>
    </CommonRequestModal>
  );
};

const ModalContainer = styled.div`
  padding: 15px 0;
  display: grid;
  gap: 16px;
  overflow-y: scroll;
  position: relative;
`;

export default FamilyEventRequestModal;
