import { useUserInfo } from '@components/common/molecules/CommonUserInfo';
import { GlobalFormat } from '@constants/Format';
import { currentStudentSearchYear } from '@pages/student-funds/templates/StudentSearchForm';
import moment from 'moment';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { axiosInstance, useRequest } from 'src/axios/fetcher';
import { ICommonCodeItem } from 'src/util/commonCode';
import { formatDate } from 'src/util/commonUtil';

export interface IStudentFundsResponseItem {
  applSeq: string;
  sabun: string;
  seq: string;
  docid: string;
  schType: string;
  famCd: string;
  schname: string;
  famres: string;
  famNm: string;
  stdyy: string;
  schyy: string;
  supyy: string;
  diver: string;
  shlno: string;
  applamt: string;
  provamt: string;
  payyymm: string;
  paymon: string;
  paymonCom: string;
  paymonWel: string;
  magamYn: YN;
  chkid: string;
  chkdate: string;
  memo: string;
  applYmd: string;
  payDate: string;
  welPayDate: string;
  applStatusCd: string;
  agreeYmd: string;
  applSabun: string;
  applInSabun: string;
  fileSeq: null;
}

export interface IStudentFundsPostParam {
  applSeq?: string;
  applSabun?: string;
  applYmd: string;
  payDate: string;
  welPayDate: string;
  schType: string;
  provamt: number;
  schname: string;
  famCd: string;
  famres: string;
  famNm: string;
  stdyy: string;
  schyy: string;
  supyy: string;
  diver: string;
  applamt: number;
  memo: string;
  payyymm: string;
  paymon: number;
  paymonCom: string;
  paymonWel: string;
  payBankCd: string;
  payAccNo: string;
  payAccNm: string;
}

export interface IStudentFundsDetailInterface {
  applSeq: number;
  applamt: number;
  provamt: number;
  paymon: number;
  paymonWel: number;
  sabun: string;
  seq: string;
  docid: string;
  schType: string;
  famCd: string;
  schname: string;
  famres: string;
  famNm: string;
  stdyy: string;
  schyy: string;
  supyy: string;
  diver: string;
  shlno: string;
  payyymm: string;
  paymonCom: string;
  magamYn: string;
  chkid: string;
  chkdate: string;
  memo: string;
  applYmd: string;
  payDate: string;
  welPayDate: string;
  applStatusCd: string;
  agreeYmd: string;
  applSabun: string;
  applInSabun: string;
  fileSeq: string;
  payBankCd: string;
  payAccNo: string;
  payAccNm: string;
}

interface ISubSettingInterface {
  schType?: string;
  domComRate?: string;
  intComRate?: string;
  limitAmt?: number;
  prDoc?: string;
  useYn?: YN;
  note?: string;
}
export const useStudentFundsList = () => {
  const { sabun } = useUserInfo();
  const year = useRecoilValue(currentStudentSearchYear);
  const resultSwr = useRequest<IStudentFundsResponseItem[]>(
    sabun && year
      ? {
          url: `/dhl/kr/myhr/welfare/studentFunds`,
          params: { sabun, year },
        }
      : null,
  );

  const { mutate } = resultSwr;

  useEffect(() => {
    mutate();
  }, [year, mutate]);

  return resultSwr;
};
export const useStudentFundsStandardData = (schType?: string) => {
  return useRequest<ISubSettingInterface>(
    schType
      ? {
          url: `/dhl/kr/myhr/welfare/studentFundStandard?schType=${schType}`,
        }
      : null,
  );
};

export const useStudentFundsDetail = (applSeq?: string) => {
  const { sabun: applSabun } = useUserInfo();
  return useRequest<[IStudentFundsDetailInterface]>(
    applSeq
      ? {
          url: `/dhl/kr/myhr/welfare/studentFund`,
          params: { applSeq, applSabun },
        }
      : null,
  );
};

export const useStudentFamilyName = (searchFamCd: string) => {
  const { sabun: searchApplSabun } = useUserInfo();
  return useRequest<ICommonCodeItem[]>({
    url: `/comm/control/myhr/common/code`,
    params: { cmd: 'getSchAppDetFamNmList', searchApplSabun, searchFamCd },
  });
};

export const useStudentFundsBankTypeList = () => {
  const searchApplYmd = formatDate(moment(), GlobalFormat.DATE_API);
  const { sabun: searchApplSabun } = useUserInfo();

  return useRequest<ICommonCodeItem[]>({
    url: `/comm/control/myhr/common/dataList`,
    params: { cmd: 'getEmpBankList', searchApplSabun, searchApplYmd },
  });
};

export interface IStudentFundsAmountInfoRequest {
  searchStdyy?: string;
  searchFamCd?: string;
  searchFamNm?: string;
  searchSchType?: string;
  searchApplSeq?: string;
}
export const useSchLimitStdMap1 = ({ searchStdyy, searchFamCd }: IStudentFundsAmountInfoRequest) => {
  const { sabun: searchApplSabun } = useUserInfo();

  return useRequest<number>(
    searchStdyy && searchFamCd && searchApplSabun
      ? {
          url: `/dhl/kr/myhr/welfare/getSchLimitStdMap1`,
          params: { searchApplSabun, searchStdyy, searchFamCd },
        }
      : null,
  );
};
export const useSchLimitStdMap2 = ({
  searchStdyy,
  searchFamCd,
  searchFamNm,
  searchSchType,
  searchApplSeq,
}: IStudentFundsAmountInfoRequest) => {
  const { sabun: searchApplSabun } = useUserInfo();

  return useRequest<{ PAY_MON: number }>(
    searchApplSabun && searchStdyy && searchFamCd && searchFamNm && searchSchType && searchApplSeq
      ? {
          url: `/dhl/kr/myhr/welfare/getSchLimitStdMap2`,
          params: { searchApplSabun, searchStdyy, searchFamCd, searchFamNm, searchSchType, searchApplSeq },
        }
      : null,
  );
};

export const useStudentFundGubun2List = (workType?: string) => {
  return useRequest<ICommonCodeItem[]>(
    workType
      ? {
          url: `/dhl/kr/myhr/welfare/getSchAppDetStdCd2/${workType}`,
        }
      : null,
  );
};

export const postStudentFunds = async (params: IStudentFundsPostParam) => {
  return await axiosInstance.post('/dhl/kr/myhr/welfare/studentFunds', params);
};

export const deleteStudentFunds = async (applSeq: string) => {
  const data = { applSeq }; // 서버로 보낼 데이터
  return await axiosInstance.post(`/dhl/kr/myhr/welfare/studentFundStandard/${applSeq}`, data);
};