import { usePayslipList } from '@apis/payslip';
import Empty from '@components/common/atoms/Empty';
import { FC } from 'react';
import styled from 'styled-components';
import PayslipItem from './PayslipItem';
interface IPayslipSearchProps {
  year: string;
}
const PayslipList: FC<IPayslipSearchProps> = ({ year }) => {
  const { data: list } = usePayslipList(year);

  if (list?.length === 0) {
    return <Empty msg={'조회 내역이 없습니다.'} />;
  }

  return (
    <>
      <ListContainer>
        {list?.map((item, index) => (
          <PayslipItem {...item} key={index} />
        ))}
      </ListContainer>
    </>
  );
};

const ListContainer = styled.div`
  margin-bottom: 45px;
`;

export default PayslipList;
