import { IVacationDateRequestParam, IVacationListItemResponseItem, useVacationApplyDate } from '@apis/vacation';
import CommonInputHidden from '@components/common/atoms/CommonInputHidden';
import CommonRangeDateFormField from '@components/common/molecules/CommonRangeDateFormField';
import { COLOR } from '@constants/Color';
import { DateRange } from '@mui/lab';
import { Stack } from '@mui/material';
import moment from 'moment';
import { Field, FormInstance } from 'rc-field-form';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

interface IVacationCodeProps {
  gntCd?: string;
  form: FormInstance<any>;
  detailData: IVacationListItemResponseItem | undefined;
}

const RequestRange: FC<IVacationCodeProps> = ({ gntCd, form, detailData }) => {
  const [vacationDateParam, setVacationDateParam] = useState<IVacationDateRequestParam>({ gntCd });
  const { data: applyDate } = useVacationApplyDate(vacationDateParam);

  useEffect(() => {
    setVacationDateParam((origin) => ({ ...origin, gntCd }));
  }, [gntCd]);

  useEffect(() => {
    if (applyDate) {
      form.setFieldsValue({ closeDay: applyDate.closeDay ?? null });
      if (applyDate.closeDay === 0.5) {
        form.setFieldsValue({ holDay: 0.5 });
      }
    }
  }, [applyDate]);

  const handleChangeDate = (dateValues: string[], dates: DateRange<Date> | null) => {
    const [from, to] = dates?.map((item) => item && moment(item)) ?? [];
    const [sYmd, eYmd] = dateValues;

    if (['15', '16'].includes(gntCd || '')) {
      // 오전반차, 오후반차 : 종료일 자동 셋팅
      form.setFieldsValue({ requestRange: [sYmd, sYmd] });
    }

    if (from && to) {
      if (['15', '16'].includes(gntCd || '')) {
        form.setFieldsValue({ holDay: 0.5 });
      } else {
        form.setFieldsValue({ holDay: to.diff(from, 'days') + 1 });
      }
      setVacationDateParam((origin) => ({ ...origin, sYmd, eYmd }));
    } else {
      form.setFieldsValue({ holDay: 0 });
    }
  };

  return (
    <div>
      <CommonRangeDateFormField
        name={'requestRange'}
        fromLabel='신청기간시작'
        toLabel='신청기간종료'
        onChangeDate={handleChangeDate}
        required
      />

      <Stack direction={'row'} margin={1}>
        <Field name={'holDay'}>
          {(control) => {
            return (
              <>
                <Item>{`총일수 : ${detailData?.holDay || control.value || 0}`}</Item>
                <CommonInputHidden />
              </>
            );
          }}
        </Field>
        <Field name={'closeDay'}>
          {(control) => {
            return (
              <>
                <Item>{`적용일수 : ${detailData?.closeDay || control.value}`}</Item>
                <CommonInputHidden />
              </>
            );
          }}
        </Field>
      </Stack>
    </div>
  );
};

const Item = styled.div`
  flex: 1;
  color: ${COLOR.GRAY};
`;

export default RequestRange;
