import { useUserInfo } from '@components/common/molecules/CommonUserInfo';
import { GlobalFormat } from '@constants/Format';
import { currentDiseaseSearchYear } from '@pages/disease/templates/DiseaseSearchForm';
import moment from 'moment';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { axiosInstance, useRequest } from 'src/axios/fetcher';
import { ICommonCodeItem } from 'src/util/commonCode';

export interface IDiseaseListInterface {
  applSeq: string;
  sabun: string;
  applYmd: string;
  agreeYmd: string;
  applStatusCd: string;
  conMon: string;
  payMon: string;
  memo: string;
  applInSabun: string;
  applSabun: string;
}

export interface IDiseaseInfoItem {
  edate: string;
  limitAmt: string;
  note: string;
  prDoc: string;
  rate: string;
  sdate: string;
  yearLimitCnt: string;
}
export interface IDiseasePostParam {
  applSeq: string;
  sabun: string;
  conMon: string;
  payMon: string;
  payYmd: string;
  payYm: string;
  payYn: string;
  payRate: string;
  bankType: string;
  bankCd: string;
  accNo: string;
  accNm: string;
  memo: string;
  bigo: string;
  name: string;
  orgNm: string;
  applYmd: string;
}
export interface IDiseaseTargetPostParam {
  applSeq: string;
  seq: string;
  sabun: string;
  famCd: string;
  famNmS: string;
  famNm: string;
  conSdate: string;
  conEdate: string;
  medicalNm: string;
  reportYn: string;
  reportMonYn: string;
  checkYn: string;
  conMon: string;
  payMon: string;
  bigo: string;
}

export const useDiseaseList = () => {
  const { sabun } = useUserInfo();
  const year = useRecoilValue(currentDiseaseSearchYear);
  const resultSwr = useRequest<IDiseaseListInterface[]>(
    sabun && year
      ? {
          url: '/dhl/kr/myhr/welfare/sicknessCompensations',
          params: { sabun, year },
        }
      : null,
  );

  const { mutate } = resultSwr;

  useEffect(() => {
    mutate();
  }, [year, mutate]);
  return resultSwr;
};

export const useDiseaseInfo = () => {
  return useRequest<IDiseaseInfoItem[]>({
    url: '/dhl/kr/myhr/welfare/sicknessCompensationStandard',
  });
};

export const useDiseaseFamilyList = () => {
  return useRequest<any[]>({
    url: '/dhl/kr/myhr/welfare/sicknessCompensationFamily',
  });
};
export const useDiseaseFamilyName = (famCd?: string) => {
  const { sabun } = useUserInfo();
  return useRequest<any[]>(
    famCd
      ? {
          url: '/dhl/kr/myhr/welfare/sicknessCompensationFamNm',
          params: { famCd, sabun },
        }
      : null,
  );
};

export const useDiseaseDetail = (searchApplSeq?: string) => {
  const { sabun } = useUserInfo();
  return useRequest<IDiseasePostParam>(
    searchApplSeq
      ? {
          url: '/dhl/kr/myhr/welfare/sicknessCompensation',
          params: { sabun, searchApplSeq },
        }
      : null,
  );
};

export const useYearDuplicationYn = ({ yearLimitAmt, searchApplSeq }: { yearLimitAmt?: string; searchApplSeq?: string }) => {
  const { sabun: searchApplSabun } = useUserInfo();
  return useRequest<string>(
    yearLimitAmt && searchApplSeq && searchApplSabun
      ? {
          url: '/dhl/kr/myhr/welfare/dupleSicknessCompensationCnt',
          params: { searchApplSabun, yearLimitAmt, searchApplSeq },
        }
      : null,
  );
};

export const getFamilyDuplicationYn = async ({
  yearLimitAmt,
  conSdate,
  conEdate,
  famNm,
  searchApplSabun,
}: {
  yearLimitAmt?: string;
  conSdate?: string;
  conEdate?: string;
  famNm?: string;
  searchApplSabun?: string;
}) => {
  const queryUrl = queryString.stringifyUrl({
    url: `/dhl/kr/myhr/welfare/sicknessCompensationDupYn`,
    query: { yearLimitAmt, conSdate, conEdate, famNm, searchApplSabun },
  });
  return await axiosInstance.get<YN>(queryUrl);
};

export const useDiseaseTargetDetail = (searchApplSeq?: string) => {
  const { sabun } = useUserInfo();
  return useRequest<IDiseaseTargetPostParam[]>(
    searchApplSeq
      ? {
          url: '/dhl/kr/myhr/welfare/sicknessCompensationDtl',
          params: { sabun, searchApplSeq },
        }
      : null,
  );
};

export const useDiseaseBankCodeList = () => {
  const searchApplYmd = moment().format(GlobalFormat.DATE_API);
  const { sabun } = useUserInfo();

  return useRequest<ICommonCodeItem[]>({
    url: '/dhl/kr/myhr/welfare/sicknessCompensationBanktype',
    params: { sabun, searchApplYmd },
  });
};

export const postDiseaseTarget = async (params: IDiseaseTargetPostParam & any) => {
  return await axiosInstance.post('/dhl/kr/myhr/welfare/sicknessCompensationDtl', params);
};
export const postDisease = async (params: IDiseasePostParam & any) => {
  return await axiosInstance.post('/dhl/kr/myhr/welfare/sicknessCompensation', params);
};
export const deleteDisease = async (applSeq: string) => {
  const data = { applSeq }; // 서버로 보낼 데이터
  return await axiosInstance.post(`/dhl/kr/myhr/welfare/sicknessCompensation/${applSeq}`, data);
};
