import { COLOR } from '@constants/Color';
import { Stack, TextField } from '@mui/material';
import { Field } from 'rc-field-form';
import React, { FC } from 'react';
import styled from 'styled-components';

interface IVacationCodeProps {
  bSYmd?: string;
  bEYmd?: string;
  bCloseDay?: string;
}

const CancelRequestRange: FC<IVacationCodeProps> = ({ bSYmd, bEYmd, bCloseDay }) => {
  return (
    <div>
      <div style={{ display: 'flex' }}>
        <TextField
          style={{ flex: 1, backgroundColor: '#ededed' }}
          InputProps={{ style: { padding: '0 4px', fontSize: 16, color: 'rgba(0,0,0,0.6)' } }}
          value={bSYmd || '시작일자'}
        />
        <Seperator>~</Seperator>
        <TextField
          style={{ flex: 1, backgroundColor: '#ededed' }}
          InputProps={{ style: { padding: '0 4px', fontSize: 16, color: 'rgba(0,0,0,0.6)' } }}
          value={bEYmd || '종료일자'}
        />
      </div>
      <Stack direction={'row'} margin={1}>
        <Field name={'closeDay'}>
          <Item>{`적용일수 : ${bCloseDay || ''}`}</Item>
        </Field>
      </Stack>
    </div>
  );
};

const Item = styled.div`
  flex: 1;
  color: ${COLOR.GRAY};
`;
const Seperator = styled.div`
  padding: 10px;
`;
export default CancelRequestRange;
