import { deleteFamilyEvent, IFamilyEventResponseItem, useFamilyEventList } from '@apis/familyEvent';
import ApplStatusCdTemplate from '@components/common/atoms/ApplStatusCdTemplate';
import CommonDeleteIcon from '@components/common/atoms/CommonDeleteIcon';
import VerticalBlank from '@components/common/atoms/VerticalBlank';
import { ApplicationStatus } from '@constants/ApplicationStatus';
import { COLOR } from '@constants/Color';
import { GlobalFormat } from '@constants/Format';
import LaunchIcon from '@mui/icons-material/Launch';
import { Divider, Stack } from '@mui/material';
import { FC, ReactText, useEffect, useState } from 'react';
import { selectCodeName, useOccCodeList } from 'src/util/commonCode';
import { formatDate } from 'src/util/commonUtil';
import styled from 'styled-components';
import AmountTemplate from './AmountTemplate';
import FamilyEventRequestModal from './FamilyEventRequestModal';

interface IFamilyEventItemProps {
  data: IFamilyEventResponseItem;
  index: ReactText;
}

const TitleCodeName = ({ occCd }: { occCd: string }) => {
  const { data } = useOccCodeList();
  const [codeName, setCodeName] = useState<string>();
  useEffect(() => {
    const result = selectCodeName(occCd, data);
    setCodeName(result);
  }, [data, occCd]);
  return <>{codeName}</>;
};

const FamilyEventItem: FC<IFamilyEventItemProps> = ({ data, index }) => {
  const [requestModalShow, isRequestModalShow] = useState(false);
  const { mutate } = useFamilyEventList();
  const deleteHandle = async () => {
    await deleteFamilyEvent(data.applSeq);
    mutate();
  };
  return (
    <Container>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <BigTitleFont>
          <TitleCodeName occCd={data.occCd} />
          <VerticalBlank width={10} />
          <ApplStatusCdTemplate status={data.applStatusCd} />
        </BigTitleFont>

        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
          <HandleIcon
            onClick={(e) => {
              e.stopPropagation();
              isRequestModalShow(true);
            }}>
            <DetailShowFont>상세보기</DetailShowFont>
            <div style={{ marginTop: 3 }}>
              <LaunchIcon />
            </div>
          </HandleIcon>
          {data.applStatusCd === ApplicationStatus.임시저장 && <CommonDeleteIcon deleteHandle={deleteHandle} />}
        </Stack>
      </Stack>

      <Stack marginBottom={1} direction={'row'} spacing={2} divider={<Divider orientation='vertical' flexItem />}>
        <BaseFont>{data.famNm}</BaseFont>
        <BaseFont>{data.famCdNm}</BaseFont>
      </Stack>

      <Row>
        <TitleFont>신청일자</TitleFont>
        <ContentFont>{formatDate(data.applYmd, GlobalFormat.DATE_DASH)}</ContentFont>
        <TitleFont>경조일자</TitleFont>
        <ContentFont>{formatDate(data.occDate, GlobalFormat.DATE_DASH)}</ContentFont>
      </Row>
      <Row>
        <TitleFont>결재일자</TitleFont>
        <ContentFont>{formatDate(data.agreeYmd, GlobalFormat.DATE_DASH)}</ContentFont>
        <TitleFont>지급일자</TitleFont>
        <ContentFont>{formatDate(data.payYmd, GlobalFormat.DATE_DASH)}</ContentFont>
      </Row>
      <AmountTemplate association={data.payMon2} company={data.payMon1} />
      {data.applSeq && requestModalShow && (
        <FamilyEventRequestModal
          authPg={data.applStatusCd === ApplicationStatus.임시저장 ? '신청' : '상세'}
          open={requestModalShow}
          onClose={() => isRequestModalShow(false)}
          applSeq={data.applSeq}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  background: ${COLOR.BG_LIGHTWT};
  padding: 15px;
`;

const BigTitleFont = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 20px;
  font-weight: 700;
  color: ${COLOR.RED_DARKEN};
`;

const Row = styled.div`
  margin: 4px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const HandleIcon = styled.span`
  font-size: 16px;
  padding: 5px 5px 5px 5px;
  margin-right: -5px;
  border-radius: 10px;
  display: flex;
  color: ${COLOR.RED};
  :active {
    background-color: ${COLOR.ACTIVE_RED};
  }
`;

const BaseFont = styled.span`
  font-size: 16px;
  color: ${COLOR.GRAY};
`;

const TitleFont = styled.span`
  color: ${COLOR.GRAY};
  font-size: 14px;
  flex: 45;
  padding: 1px;
`;
const ContentFont = styled.div`
  font-size: 14px;
  color: ${COLOR.LIGHT_GRAY};
  flex: 55;
`;

const DetailShowFont = styled.span`
  font-weight: 600;
`;

export default FamilyEventItem;
