import {
  useDeductionCalculMethod,
  useDeductionDetail,
  usePaymentCalculMethod,
  usePaymentDetail,
  usePayslipDetail,
  useSalaryEtc,
} from '@apis/payslip';
import Accordion from '@components/common/molecules/Accordion';
import CommonRequestModal from '@components/common/organisms/CommonRequestModal';
import React, { FC, useMemo } from 'react';
import styled from 'styled-components';

interface IPayslipRequestModalProps {
  onClose: () => void;
  open: boolean;
  payActionCd?: string;
}
const PayslipRequestModal: FC<IPayslipRequestModalProps> = ({ open, onClose, payActionCd }) => {
  const { data } = usePayslipDetail(payActionCd);
  const detailData = useMemo(() => data?.[0], [data]);
  const workDay = detailData?.workDdCnt.replace(/[^0-9]/g, '');
  const { data: paymentDetail } = usePaymentDetail(payActionCd);
  const [paymentDetailTotal, paymentDetailRest] = useMemo(() => {
    const list = paymentDetail?.filter((_, index) => index !== 0);
    return [paymentDetail?.[0], list];
  }, [paymentDetail]);
  const { data: deductionDetail } = useDeductionDetail(payActionCd);
  const [deductionDetailTotal, deductionDetailRest] = useMemo(() => {
    const list = deductionDetail?.filter((_, index) => index !== 0);
    return [deductionDetail?.[0], list];
  }, [deductionDetail]);
  const { data: paymentCalculMethod } = usePaymentCalculMethod(payActionCd);
  const { data: deductionCalculMethod } = useDeductionCalculMethod(payActionCd);
  const { data: salaryEtc } = useSalaryEtc(payActionCd);

  return (
    <>
      <CommonRequestModal open={open} onClose={onClose} title={detailData?.payNm || ''} onCancelDone={() => {}}>
        <ModalContainer>
          <PayslipTable>
            <colgroup>
              <col width='25%' />
              <col width='40%' />
              <col width='18%' />
              <col width='17%' />
            </colgroup>
            <tbody>
              <tr>
                <PayslipTd color='#ebeef0'>급여지급기간</PayslipTd>
                <PayslipTd color='#f7f9fc'>{detailData?.paySchedule}</PayslipTd>
                <PayslipTd color='#ebeef0'>지급일자</PayslipTd>
                <PayslipTd color='#f7f9fc'>{detailData?.paymentYmd}</PayslipTd>
              </tr>
              <tr>
                <PayslipTd color='#ebeef0'>근로일수</PayslipTd>
                <PayslipTd color='#f7f9fc'>{workDay}일</PayslipTd>
                <PayslipTd color='#ebeef0'></PayslipTd>
                <PayslipTd color='#f7f9fc'></PayslipTd>
              </tr>
            </tbody>
          </PayslipTable>

          <PayslipTable>
            <colgroup>
              <col width='25%' />
              <col width='30%' />
              <col width='25%' />
              <col width='20%' />
            </colgroup>
            <tbody>
              <tr>
                <PayslipTd color='#ebeef0'>사번</PayslipTd>
                <PayslipTd color='#f7f9fc'>{detailData?.sabun}</PayslipTd>
                <PayslipTd color='#ebeef0'>성명</PayslipTd>
                <PayslipTd color='#f7f9fc'>{detailData?.name}</PayslipTd>
              </tr>
              <tr>
                <PayslipTd color='#ebeef0'>소속</PayslipTd>
                <PayslipTd color='#f7f9fc'>{detailData?.orgNm}</PayslipTd>
                <PayslipTd color='#ebeef0'>직위</PayslipTd>
                <PayslipTd color='#f7f9fc'>{detailData?.jikweeNm}</PayslipTd>
              </tr>
            </tbody>
          </PayslipTable>

          <PayslipTable>
            <colgroup>
              <col width='25%' />
              <col width='30%' />
              <col width='25%' />
              <col width='20%' />
            </colgroup>
            <tbody>
              <tr>
                <PayslipTd color='#ebeef0'>지급총액(A)</PayslipTd>
                <PayslipAmountTd color='#f7f9fc'>{detailData?.totEarningMon}</PayslipAmountTd>
                <PayslipTd color='#ebeef0'>공제총액(B)</PayslipTd>
                <PayslipAmountTd color='#f7f9fc'>{detailData?.totDedMon}</PayslipAmountTd>
              </tr>
              <tr>
                <PayslipTd color='#ebeef0'>계좌번호</PayslipTd>
                <PayslipTd color='#f7f9fc'>
                  {detailData?.bankNm}
                  <br />
                  {detailData?.accountNo}
                </PayslipTd>
                <PayslipTd color='#ebeef0'>실수령액 (A-B)</PayslipTd>
                <PayslipAmountTd color='#fad5e6'>{detailData?.resultMon}</PayslipAmountTd>
              </tr>
            </tbody>
          </PayslipTable>

          <PayslipTable>
            <colgroup>
              <col width='50%' />
              <col width='50%' />
            </colgroup>
            <tbody>
              <tr>
                <PayslipTd colSpan={2} color='#d6ecee' style={{ textAlign: 'center' }}>
                  지급내역
                </PayslipTd>
              </tr>
              {paymentDetailRest?.map((item, index) => (
                <tr key={item.reportNm}>
                  <PayslipTd color='#ebeef0'>{item.reportNm}</PayslipTd>
                  <PayslipAmountTd color='#f7f9fc'>{item.resultMon}</PayslipAmountTd>
                </tr>
              ))}
              <tr>
                <PayslipTd color='#faeef4'>지급총액(A)</PayslipTd>
                <PayslipAmountTd color='#faeef4'>{paymentDetailTotal?.resultMon}</PayslipAmountTd>
              </tr>
            </tbody>
          </PayslipTable>

          <PayslipTable>
            <colgroup>
              <col width='50%' />
              <col width='50%' />
            </colgroup>
            <tbody>
              <tr>
                <PayslipTd colSpan={5} color='#e8f0c0' style={{ textAlign: 'center' }}>
                  공제내역
                </PayslipTd>
              </tr>
              {deductionDetailRest?.map((item, index) => (
                <tr key={item.reportNm}>
                  <PayslipTd color='#ebeef0'>{item.reportNm}</PayslipTd>
                  <PayslipAmountTd color='#f7f9fc'>{item.resultMon}</PayslipAmountTd>
                </tr>
              ))}
              <tr>
                <PayslipTd color='#faeef4'>공제총액(B)</PayslipTd>
                <PayslipAmountTd color='#faeef4'>{deductionDetailTotal?.resultMon}</PayslipAmountTd>
              </tr>
              <tr>
                <PayslipTd color='#faeef4'>실수령액(A-B)</PayslipTd>
                <PayslipAmountTd color='#faeef4'>{detailData?.resultMon}</PayslipAmountTd>
              </tr>
            </tbody>
          </PayslipTable>

          <PayslipTable>
            <colgroup>
              <col width='50%' />
              <col width='50%' />
            </colgroup>
            <tbody>
              <tr>
                <PayslipTd colSpan={4} color='#d7dadc' style={{ textAlign: 'center' }}>
                  근태내역
                </PayslipTd>
              </tr>
              <tr>
                <PayslipTd color='#ebeef0'>구분</PayslipTd>
                <PayslipTd color='#ebeef0'>시간</PayslipTd>
              </tr>
              {salaryEtc?.map(
                (item, index) =>
                  item.bizNm === '[근태]근무가감' && (
                    <tr key={item.bizCd + item.elementCd}>
                      <PayslipAmountTd color='#f7f9fc' style={{ textAlign: 'center' }}>
                        {item.elementNm}
                      </PayslipAmountTd>
                      <PayslipAmountTd color='#f7f9fc' style={{ textAlign: 'center' }}>
                        {item.eleValue.split('.')[0] === '' ? '0' + item.eleValue : item.eleValue}
                      </PayslipAmountTd>
                    </tr>
                  ),
              )}
            </tbody>
          </PayslipTable>

          <div>
            <div style={{ textAlign: 'center', color: '#4b4b4b', fontSize: 11, paddingBottom: '3px' }}>계산방법보기</div>
            <Accordion>
              <PayslipTable>
                <colgroup>
                  <col width='20%' />
                  <col width='60%' />
                  <col width='20%' />
                </colgroup>
                <tbody>
                  <tr>
                    <PayslipTd colSpan={3} color='#ebeef0' style={{ textAlign: 'center', color: 'black' }}>
                      지급계산방법
                    </PayslipTd>
                  </tr>
                  <tr>
                    <PayslipTd color='#f7f9fc' style={{ textAlign: 'center', color: 'black' }}>
                      지급항목
                    </PayslipTd>
                    <PayslipTd color='#f7f9fc' style={{ textAlign: 'center', color: 'black' }}>
                      계산방법
                    </PayslipTd>
                    <PayslipTd color='#f7f9fc' style={{ textAlign: 'center', color: 'black' }}>
                      금액
                    </PayslipTd>
                  </tr>
                  {paymentCalculMethod?.map((item, index) => (
                    <tr key={item.elementNm}>
                      <PayslipTd color='#f7f9fc' style={{ color: 'black' }}>
                        {item.elementNm}
                      </PayslipTd>
                      <PayslipTd color='#f7f9fc' style={{ color: 'black' }}>
                        {item.formula}
                      </PayslipTd>
                      <PayslipAmountTd color='#f7f9fc' style={{ color: 'black' }}>
                        {item.resultMonFor}
                      </PayslipAmountTd>
                    </tr>
                  ))}
                </tbody>
              </PayslipTable>

              <PayslipTable>
                <colgroup>
                  <col width='20%' />
                  <col width='60%' />
                  <col width='20%' />
                </colgroup>
                <tbody>
                  <tr>
                    <PayslipTd colSpan={5} color='#ebeef0' style={{ textAlign: 'center', color: 'black' }}>
                      공제계산방법
                    </PayslipTd>
                  </tr>
                  <tr>
                    <PayslipTd color='#f7f9fc' style={{ textAlign: 'center', color: 'black' }}>
                      공제항목
                    </PayslipTd>
                    <PayslipTd color='#f7f9fc' style={{ textAlign: 'center', color: 'black' }}>
                      계산방법
                    </PayslipTd>
                    <PayslipTd color='#f7f9fc' style={{ textAlign: 'center', color: 'black' }}>
                      금액
                    </PayslipTd>
                  </tr>
                  {deductionCalculMethod?.map((item, index) => (
                    <tr key={item.elementNm}>
                      <PayslipTd color='#f7f9fc' style={{ color: 'black' }}>
                        {item.elementNm}
                      </PayslipTd>
                      <PayslipTd color='#f7f9fc' style={{ color: 'black' }}>
                        {item.formula}
                      </PayslipTd>
                      <PayslipAmountTd color='#f7f9fc' style={{ color: 'black' }}>
                        {item.resultMonFor}
                      </PayslipAmountTd>
                    </tr>
                  ))}
                </tbody>
              </PayslipTable>
            </Accordion>
          </div>
        </ModalContainer>
      </CommonRequestModal>
    </>
  );
};

const ModalContainer = styled.div`
  display: grid;
  gap: 16px;
  overflow-y: scroll;
  position: relative;
`;
const PayslipTable = styled.table`
  width: 100%;
  border: 1px solid #dae1e6;
`;
const PayslipTd = styled.td`
  height: 26px;
  font-size: 11px;
  padding: 0 4px;
  background: ${(props) => props.color || 'gray'};
  text-align: center;
  white-space: break-spaces;
`;

const PayslipAmountTd = styled(PayslipTd)`
  text-align: right;
`;

export default PayslipRequestModal;
