import { useUserInfo } from '@components/common/molecules/CommonUserInfo';
import { currentFamilyEventSearchYear } from '@pages/family-event/templates/FamilyEventSearchForm';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { axiosInstance, useRequest } from 'src/axios/fetcher';

export interface IFamilyEventResponseItem {
  applSeq: string;
  sabun: string;
  applYmd: string;
  agreeYmd: string;
  applStatusCd: string;
  occCd: string;
  manageCd: string;
  famCd: string;
  famNm: string;
  famCdNm: string;
  occDate: string;
  payMon1: number;
  payMon2: number;
  memo: string;
  applInSabun: string;
  applSabun: string;
  occMonYn: string;
  wreathYn: string;
  occServYn: string;
  payYmd: string;
}

export interface IFamilyEventPostParam {
  applSeq?: string;
  occSeq: string;
  applSabun?: string;
  occCd: string;
  famCd: string;
  famNm: string;
  famBirYmd: string;
  occDate: string;
  payYm: string;
  payYmd: string;
  payYn: YN;
  occMonYn: YN;
  bigo: string;
  wreathYn: string;
  wreathMon: string;
  occServYn: YN;
  occServMon: string;
  memo: string;
  bankType: string;
  bankCd: string;
  accNo: string;
  accNm: string;
  fileSeq: string;
  payMon1: number;
  payMon2: number;
}

export interface IFamilyEventDetailInterface {
  applSeq: string;
  sabun: string;
  applYmd: string;
  agreeYmd: string;
  applStatusCd: string;
  occCd: string;
  manageCd: string;
  famCd: string;
  famNm: string;
  famCdNm: string;
  occDate: string;
  payMon1: string;
  payMon2: string;
  memo: string;
  applInSabun: string;
  applSabun: string;
  occMonYn: string;
  wreathYn: string;
  occServYn: string;
  name: string;
  orgNm: string;
  famBirYmd: string;
  payYmd: string;
  bigo: string;
  gempYmd: string;
  wreathMon: string;
  occServMon: string;
  bankType: string;
  bankCd: string;
  accNo: string;
  accNm: string;
}

export interface ICongraturationStandardInterface {
  seq: string;
  occCd: string;
  famCd: string;
  occMon1: number;
  occMon2: number;
  useYn: string;
  prDoc: string;
  memo1: string;
  wreathYn: string;
  occServYn: string;
}

export const useFamilyEventList = () => {
  const { sabun } = useUserInfo();
  const year = useRecoilValue(currentFamilyEventSearchYear);
  const resultSwr = useRequest<IFamilyEventResponseItem[]>({
    url: `/dhl/kr/myhr/welfare/congrats`,
    params: { sabun, year },
  });

  const { mutate } = resultSwr;

  useEffect(() => {
    mutate();
  }, [year, mutate]);
  return resultSwr;
};

export const useFamilyEventDetail = (applSeq?: string | null) => {
  return useRequest<[IFamilyEventDetailInterface]>(
    applSeq
      ? {
          url: `/dhl/kr/myhr/welfare/congrat`,
          params: { applSeq },
        }
      : null,
  );
};

export const postFamilyEvent = async (params: IFamilyEventPostParam) => {
  return await axiosInstance.post('/dhl/kr/myhr/welfare/congrat', params);
};
export const deleteFamilyEvent = async (applSeq: string) => {
  const data = { applSeq }; // 서버로 보낼 데이터
  return await axiosInstance.post(`/dhl/kr/myhr/welfare/congratStandard/${applSeq}`, data);
};

export const useFamilyEventStandardData = ({
  occCd,
  famCd,
  searchApplYmd,
  searchApplSabun,
}: {
  occCd?: string;
  famCd?: string;
  searchApplYmd?: string;
  searchApplSabun?: string;
}) => {
  return useRequest<ICongraturationStandardInterface[]>(
    occCd && famCd && searchApplYmd
      ? {
          url: `/dhl/kr/myhr/welfare/congratStandard`,
          params: { searchApplSabun, searchApplYmd, occCd, famCd },
        }
      : null,
  );
};
