import { deleteStudentFunds, IStudentFundsResponseItem, useStudentFundsList } from '@apis/studentFunds';
import ApplStatusCdTemplate from '@components/common/atoms/ApplStatusCdTemplate';
import CommonDeleteIcon from '@components/common/atoms/CommonDeleteIcon';
import VerticalBlank from '@components/common/atoms/VerticalBlank';
import { ApplicationStatus } from '@constants/ApplicationStatus';
import { COLOR } from '@constants/Color';
import { GlobalFormat } from '@constants/Format';
import LaunchIcon from '@mui/icons-material/Launch';
import { Divider, Stack } from '@mui/material';
import { FC, ReactText, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { selectCodeName, useFamilyCodeList, useSchoolTypeCodeList } from 'src/util/commonCode';
import { formatDate } from 'src/util/commonUtil';
import styled from 'styled-components';
import StudentFundsRequestModal from './StudentFundsRequestModal';
// import StudentFundsRequestModal from './StudentFundsRequestModal';

interface IStudentFundsItemProps {
  data: IStudentFundsResponseItem;
  index: ReactText;
}

const SchTypeCodeName = ({ schType }: { schType: string }) => {
  const { data } = useSchoolTypeCodeList();
  const [codeName, setCodeName] = useState<string>();
  useEffect(() => {
    const result = selectCodeName(schType, data);
    setCodeName(result);
  }, [data, schType]);
  return <>{codeName}</>;
};

const FamilyCodeName = ({ famCd }: { famCd: string }) => {
  const { data } = useFamilyCodeList({ cmd: 'getSchAppDetFamCdList' });
  const [codeName, setCodeName] = useState<string>();
  useEffect(() => {
    const result = selectCodeName(famCd, data);
    setCodeName(result);
  }, [data, famCd]);
  return <>{codeName}</>;
};

const StudentFundsItem: FC<IStudentFundsItemProps> = ({ data, index }) => {
  const [requestModalShow, isRequestModalShow] = useState(false);
  const { mutate } = useStudentFundsList();
  const deleteHandle = async () => {
    await deleteStudentFunds(data.applSeq);
    mutate();
  };
  return (
    <Container>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <BigTitleFont>
          <SchTypeCodeName schType={data.schType} />
          <VerticalBlank width={10} />
          <ApplStatusCdTemplate status={data.applStatusCd} />
        </BigTitleFont>
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
          <HandleIcon
            onClick={(e) => {
              e.stopPropagation();
              isRequestModalShow(true);
            }}>
            <DetailShowFont>상세보기</DetailShowFont>
            <div style={{ marginTop: 3 }}>
              <LaunchIcon />
            </div>
          </HandleIcon>
          {data.applStatusCd === ApplicationStatus.임시저장 && <CommonDeleteIcon deleteHandle={deleteHandle} />}
        </Stack>
      </Stack>

      <Stack marginBottom={1} direction={'row'} spacing={2} divider={<Divider orientation='vertical' flexItem />}>
        <BaseFont>
          <FamilyCodeName famCd={data.famCd} />
        </BaseFont>
        <BaseFont>{data.famNm}</BaseFont>
        <BaseFont>{data.schname}</BaseFont>
      </Stack>
      <Row>
        <TitleFont>신청일자</TitleFont>
        <ContentFont>{formatDate(data.applYmd, GlobalFormat.DATE_DASH)}</ContentFont>
        <TitleFont>신청금액</TitleFont>
        <ContentFont>
          <NumberFormat value={data.applamt ?? 0} displayType={'text'} thousandSeparator={true} suffix={' 원'} />
        </ContentFont>
      </Row>
      <Row>
        <TitleFont>결재일자</TitleFont>
        <ContentFont>{formatDate(data.applYmd, GlobalFormat.DATE_DASH)}</ContentFont>
        <TitleRedFont>지급금액</TitleRedFont>
        <ContentRedFont>
          <NumberFormat value={data.paymon ?? 0} displayType={'text'} thousandSeparator={true} suffix={' 원'} />
        </ContentRedFont>
      </Row>
      {data.applSeq && requestModalShow && (
        <StudentFundsRequestModal
          authPg={data.applStatusCd === ApplicationStatus.임시저장 ? '신청' : '상세'}
          open={requestModalShow}
          onClose={() => isRequestModalShow(false)}
          applSeq={data.applSeq}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  background: ${COLOR.BG_LIGHTWT};
  padding: 15px;
`;

const BigTitleFont = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 20px;
  font-weight: 700;
  color: ${COLOR.RED_DARKEN};
`;

const Row = styled.div`
  margin: 4px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const HandleIcon = styled.span`
  font-size: 16px;
  padding: 5px 5px 5px 5px;
  margin-right: -5px;
  border-radius: 10px;
  color: ${COLOR.RED};
  display: flex;
  :active {
    background-color: ${COLOR.ACTIVE_RED};
  }
`;

const BaseFont = styled.span`
  font-size: 16px;
  color: ${COLOR.GRAY};
`;

const TitleFont = styled.span`
  color: ${COLOR.GRAY};
  font-size: 14px;
  flex: 47;
  padding: 1px;
`;
const ContentFont = styled.div`
  font-size: 14px;
  color: ${COLOR.LIGHT_GRAY};
  flex: 53;
`;
const TitleRedFont = styled(TitleFont)`
  color: red;
`;
const ContentRedFont = styled(ContentFont)`
  color: red;
`;

const DetailShowFont = styled.span`
  font-weight: 600;
`;

export default StudentFundsItem;
