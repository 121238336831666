import { useEffect, useState } from 'react';
import { selectCodeName, useCommonCodeList } from 'src/util/commonCode';

const CommonCodeName = ({ code, grpCd }: { code: string; grpCd: string }) => {
  const { data } = useCommonCodeList(grpCd);
  const [codeName, setCodeName] = useState<string>();
  useEffect(() => {
    const result = selectCodeName(code, data);
    setCodeName(result);
  }, [data, code]);
  return <>{codeName}</>;
};

export default CommonCodeName;
