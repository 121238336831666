import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonSelect from '@components/common/molecules/CommonSelect';
import { FC } from 'react';
import { useStudentYYCodeList } from 'src/util/commonCode';

const SchyySelect: FC = () => {
  const { data: schyyCodeList } = useStudentYYCodeList();
  return (
    <>
      <CommonFormField
        name={'schyy'}
        rules={[
          {
            required: true,
          },
        ]}>
        <CommonSelect
          title='학년'
          options={schyyCodeList?.map((item) => ({
            label: item.codeNm,
            value: item.code,
          }))}
        />
      </CommonFormField>
    </>
  );
};

export default SchyySelect;
