import { useEmpWorkType } from '@apis/common';
import {
  IStudentFundsAmountInfoRequest,
  useSchLimitStdMap1,
  useSchLimitStdMap2,
  useStudentFundGubun2List,
  useStudentFundsStandardData,
} from '@apis/studentFunds';
import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonInput from '@components/common/atoms/CommonInput';
import { COLOR } from '@constants/Color';
import { FC, useMemo } from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

export const useLimiteInfo = (params: IStudentFundsAmountInfoRequest) => {
  const { data: amountInfo1 } = useSchLimitStdMap1(params);
  const { data: amountInfo2 } = useSchLimitStdMap2(params);
  const workType = useEmpWorkType();
  const { data: studentFundGubun2List } = useStudentFundGubun2List(workType);
  const { data: standardData } = useStudentFundsStandardData(params?.searchSchType);

  const stdLimitCnt = useMemo(() => {
    const limit = studentFundGubun2List?.find((item) => item.code === '01')?.codeValue;
    return parseInt(limit ?? '0');
  }, [studentFundGubun2List]);

  let applYn = 'N';
  const cnt = amountInfo1 ?? 0;
  const payMon = amountInfo2?.PAY_MON ?? 0;//
  const limitAmt = standardData?.limitAmt ?? 0;
  const limitYn = useMemo(() => (limitAmt > 0 ? 'Y' : 'N'), [limitAmt]);
  const includeYn = (amountInfo2?.PAY_MON ?? 0) > 0 ? 'Y' : 'N';
  let resultMon = 0;
  let resultMsg = '';

  //if(schMap1 && schMap2 && schMap3) {

  if (includeYn === 'Y') {//지급액있을경우
    if (limitYn === 'Y') {//한도제한 있을경우
      if (limitAmt - payMon > 0) {//한도보다 지급액이 작을경우 한도-지급액 금액설정
        applYn = 'Y';
        resultMon = limitAmt - payMon;
      } else {
        applYn = 'N';
        resultMon = 0;
        resultMsg = '잔여한도없음';
      }
    } else {
      applYn = 'Y';

      resultMon = 0;
      resultMsg = '한도제한없음';
    }
  } else if (includeYn === 'N') {//지급액이 없을 경우
    if (cnt <= stdLimitCnt) {//지원자녀수보다 작을경우
      applYn = 'Y';
      if (limitYn === 'Y') {//한도가 있을경우
        resultMon = limitAmt;//한도금액을 금액설정
      } else {
        resultMon = 0;
        resultMsg = '한도제한없음';//한도가 없을경우 금액 0 설정
      }
    } else {
      applYn = 'N';
      resultMon = 0;
      resultMsg = '지원가능대상초과';
    }
  }
  return {
    applYn,
    limitYn,
    resultMon,
    resultMsg,
    limitAmt,
  };
};

const StudentFundsAmount: FC<IStudentFundsAmountInfoRequest> = (params) => {
  const { applYn, limitYn, resultMon, resultMsg } = useLimiteInfo(params);
  const renderResultMessage = () => {
    if (applYn === 'Y') {
      if (limitYn === 'Y') {
        return (
          <NumberFormat
            value={resultMon ?? 0}
            displayType={'text'}
            thousandSeparator={true}
            suffix={' 원'}
            prefix='신청가능금액: '
          />
        );
      } else {
        return resultMsg;
      }
    } else {
      return '신청불가 - ' + resultMsg;
    }
  };

  return (
    <div>
      <CommonFormField
        name={'applamt'}
        rules={[
          {
            required: true,
          },
        ]}>
        <CommonInput label='신청금액' pattern='money' />
      </CommonFormField>
      <ResultMessageFont>{renderResultMessage()}</ResultMessageFont>
    </div>
  );
};

const ResultMessageFont = styled.div`
  margin: 6px 0;
  color: ${COLOR.GRAY};
`;

export default StudentFundsAmount;
