import CommonContainer from '@components/common/atoms/CommonContainer';
import HorizontalBlank from '@components/common/atoms/HorizontalBlank';
import { useHeaderStore } from '@stores/headerStore';
import { useEffect } from 'react';
import DiseaseList from './templates/DiseaseList';
import DiseaseSearchForm from './templates/DiseaseSearchForm';

const DiseasePage = () => {
  const { setConfig } = useHeaderStore();
  useEffect(() => {
    setConfig({ title: '질환위로금' });
  }, [setConfig]);
  return (
    <CommonContainer style={{ padding: 15 }}>
      <DiseaseSearchForm />
      <HorizontalBlank height={20} />
      <DiseaseList />
    </CommonContainer>
  );
};

export default DiseasePage;
