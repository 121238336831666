import { COLOR } from '@constants/Color';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Modal } from '@mui/material';
import React, { FC } from 'react';
import styled from 'styled-components';
interface IDetailImageModalProps {
  imageBase64: string;
  open: boolean;
  handleClose: () => void;
}

const DetailImageModal: FC<IDetailImageModalProps> = ({ imageBase64, open, handleClose }) => {
  return (
    <ModalRoot open={open} onClick={(e) => e.stopPropagation()}>
      <ModalContainer>
        <CloseIcon onClick={handleClose} style={{ fontSize: 40 }} />
        <Image src={imageBase64} alt='자세히보기' />
      </ModalContainer>
    </ModalRoot>
  );
};
const ModalRoot = styled(Modal)`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CloseIcon = styled(CancelRoundedIcon)`
  color: ${COLOR.BG_GRAY};
  align-self: flex-end;
  margin-bottom: 10px;
`;

const Image = styled.img`
  width: 90vw;
  max-height: 80vh;
  object-fit: contain;
`;

export default DetailImageModal;
