import { useVacationList } from '@apis/vacation';
import Empty from '@components/common/atoms/Empty';
import { FC } from 'react';
import VacationItem from './VacationItem';

const VacationList: FC = () => {
  const { data: list } = useVacationList();

  if (list?.length === 0) {
    return <Empty />;
  }

  return (
    <>
      {list?.map((item, index) => (
        <VacationItem {...item} key={`vacation_key_${item.applSeq}`} />
      ))}
    </>
  );
};

export default VacationList;
