import CommonContainer from '@components/common/atoms/CommonContainer';
import VacationStatus from '@pages/vacation/templates/VacationStatus';
import { useHeaderStore } from '@stores/headerStore';
import { useEffect } from 'react';
import VacationCancelList from './templates/VacationCancelList';
import VacationCancelSearchForm from './templates/VacationCancelSearchForm';

const VacationCancelPage = () => {
  const { setConfig } = useHeaderStore();
  useEffect(() => {
    setConfig({ title: '근태취소신청' });
  }, [setConfig]);

  return (
    <CommonContainer style={{ padding: 15 }}>
      <VacationCancelSearchForm />
      <VacationStatus />
      <VacationCancelList />
    </CommonContainer>
  );
};

export default VacationCancelPage;
