import Form, { Field, FormProps } from 'rc-field-form';
import { FieldProps } from 'rc-field-form/es/Field';
import { FormInstance, Meta } from 'rc-field-form/es/interface';
import React, { FC } from 'react';
import styled from 'styled-components';

const CommonForm: FC<FormProps & { readOnly?: boolean }> = ({ form, children, readOnly, ...props }) => {
  return (
    <Form form={form} {...props} style={{ position: 'relative' }}>
      {children}
      {readOnly && <ReadonlyMask />}
    </Form>
  );
};
export interface IFieldChildrenProps {
  meta?: Meta;
  form?: FormInstance;
  iserror?: boolean;
  value?: any;
}

export const CommonFormField: FC<FieldProps> = ({ children, ...restProps }) => {
  return (
    <Field {...restProps}>
      {(control, meta, form) => {
        const childrenAny: any = children;
        const iserror = (meta?.errors?.length ?? 0) > 0;

        return React.cloneElement(childrenAny, { meta, form, iserror, ...control });
      }}
    </Field>
  );
};

const ReadonlyMask = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
`;

export default CommonForm;
