import { AxiosRequestConfig } from 'axios';
import { msalInstance } from 'src/App';
import { loginRequest } from 'src/authConfig';

export const requestInterceptor = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const account = msalInstance.getAllAccounts()[0];

  const accessTokenRequest = {
    ...loginRequest,
    account,
  };

  // Use the same msalInstance instance provided to MsalProvider
  const accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest).catch((error) => {
    msalInstance.acquireTokenRedirect(accessTokenRequest).catch((error) => {
      // Acquire token interactive failure
      console.error(error);
    });
  });

  let accessToken = accessTokenResponse?.accessToken;
  //console.log('accessToken', accessToken);
  if (accessToken) {
    if (config.headers) config.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return config;
};
