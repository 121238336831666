import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonSelect from '@components/common/molecules/CommonSelect';
import { GlobalFormat } from '@constants/Format';
import { Moment } from 'moment';
import { FormInstance } from 'rc-field-form';
import React, { FC } from 'react';
import { useBankTypeList } from 'src/util/commonCode';
import { formatDate } from 'src/util/commonUtil';
interface ISearchBankTypeProps {
  form: FormInstance<any>;
  occDate?: Moment;
}

const SearchBankType: FC<ISearchBankTypeProps> = ({ form, occDate }) => {
  const { data: bankTypeList } = useBankTypeList({ searchApplYmd: formatDate(occDate, GlobalFormat.DATE_API) });

  return (
    <CommonFormField
      name={'bankType'}
      rules={[
        {
          required: true,
        },
      ]}>
      <CommonSelect
        title='계좌구분'
        options={bankTypeList?.map((item) => ({
          label: item.codeNm,
          value: item.code.split('|')[0],
        }))}
      />
    </CommonFormField>
  );
};

export default SearchBankType;
