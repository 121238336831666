import { useUserInfo } from '@components/common/molecules/CommonUserInfo';
import { useRequest } from 'src/axios/fetcher';

export interface ICommonCodeItem {
  code: string;
  codeNm: string;
  codeValue: string;
  memo: string;
  note1: string;
  note2: string;
  note3: string;
  note4: string;
}

interface ICommonCodeParam {
  occCd?: string;
  famCd?: string;
  searchApplYmd?: string;
  famBirYmd?: string;
  occDate?: string;
  cmd?: string;
}

export const selectCodeName = (code: string, codeGroup?: ICommonCodeItem[]) =>
  codeGroup?.find((item) => item.code === code)?.codeNm;

export const useCommonCodeList = (grpCd: string) => {
  return useRequest<ICommonCodeItem[]>({
    url: `/comm/control/myhr/common/code`,
    params: { cmd: 'commonCodeList', grpCd },
  });
};

export const useBankCodeList = () => {
  return useRequest<ICommonCodeItem[]>({
    url: `/comm/control/myhr/common/code`,
    params: { cmd: 'commonCodeList', grpCd: 'H30001' },
  });
};

export const useApplStatusCodeList = () => {
  return useRequest<ICommonCodeItem[]>({
    url: `/comm/control/myhr/common/code`,
    params: { cmd: 'commonCodeList', grpCd: 'R10010' },
  });
};
export const useSchoolTypeCodeList = () => {
  return useRequest<ICommonCodeItem[]>({
    url: `/comm/control/myhr/common/code`,
    params: { cmd: 'commonCodeList', grpCd: 'B60050' },
  });
};
export const useStudentYYCodeList = () => {
  return useRequest<ICommonCodeItem[]>({
    url: `/comm/control/myhr/common/code`,
    params: { cmd: 'commonCodeList', grpCd: 'B60051' },
  });
};
export const useDiverCodeList = () => {
  return useRequest<ICommonCodeItem[]>({
    url: `/comm/control/myhr/common/code`,
    params: { cmd: 'commonCodeList', grpCd: 'B60052' },
  });
};
export const useOccCodeList = () => {
  return useRequest<ICommonCodeItem[]>({
    url: `/comm/control/myhr/common/code`,
    params: { cmd: 'getOccAppDetOccCdList', useYn: 'Y' },
  });
};

export const useFamilyNameList = ({ famCd, searchApplYmd }: ICommonCodeParam) => {
  const { sabun: searchApplSabun } = useUserInfo();

  return useRequest<ICommonCodeItem[]>({
    url: `/comm/control/myhr/common/code`,
    params: { cmd: 'getOccAppDetFamNmList', useYn: 'Y', searchApplSabun, famCd, searchApplYmd },
  });
};
export const useStudentFundGubunList = () => {
  return useRequest<ICommonCodeItem[]>({
    url: `/comm/control/myhr/common/dataList`,
    params: { cmd: 'getSchAppDetStdCd' },
  });
};

export const useFamilyCodeList = ({ occCd, searchApplYmd, cmd }: ICommonCodeParam) => {
  const { sabun: searchApplSabun } = useUserInfo();
  return useRequest<ICommonCodeItem[]>({
    url: `/comm/control/myhr/common/code`,
    params: {
      cmd,
      useYn: 'Y',
      occCd,
      searchApplYmd,
      searchApplSabun,
    },
  });
};
export const useBankTypeList = ({ searchApplYmd }: ICommonCodeParam) => {
  const { sabun: searchApplSabun } = useUserInfo();

  return useRequest<ICommonCodeItem[]>({
    url: `/comm/control/myhr/common/dataList`,
    params: { cmd: 'getOccAppBankList', searchApplSabun, searchApplYmd },
  });
};

interface ICongraturationStandardInterface {
  seq: string;
  occCd: string;
  famCd: string;
  occMon1: number;
  occMon2: number;
  useYn: string;
  prDoc: string;
  memo1: string;
  wreathYn: string;
  occServYn: string;
}

export const useSettingInfo = ({ occCd, famCd, searchApplYmd }: ICommonCodeParam) => {
  const { sabun: searchApplSabun } = useUserInfo();
  return useRequest<ICongraturationStandardInterface[]>(
    occCd && famCd && searchApplYmd
      ? {
          url: `/dhl/kr/myhr/welfare/congratStandard`,
          params: { searchApplSabun, searchApplYmd, occCd, famCd },
        }
      : null,
  );
};

export const useApplStatusCd = () => {
  return useRequest<ICommonCodeItem[]>({
    url: '/dhl/kr/myhr/certificate/getCommCodeList',
    params: { grpCd: 'R10010' },
  });
};

export const useApplCd = () => {
  return useRequest<ICommonCodeItem[]>({
    url: '/dhl/kr/myhr/certificate/getAddlCodeList',
    params: { comboViewYn: 'Y', useYn: 'Y' },
  });
};

export const useReqYy = () => {
  return useRequest<[ICommonCodeItem]>({
    url: `/dhl/kr/myhr/certificate/getCertiYearList`,
  });
};

export const useAppBizCd = () => {
  return useRequest<ICommonCodeItem[]>({
    url: '/dhl/kr/myhr/approval/getCommCodeList',
    params: { grpCd: 'R10040' },
  });
};

export const useApprApplStatusCd = () => {
  return useRequest<ICommonCodeItem[]>({
    url: '/dhl/kr/myhr/approval/getAddlCodeList',
    params: { grpCd: 'R10040' },
  });
};

export const useMiscarriagePeriodList = () => {
  return useRequest<ICommonCodeItem[]>({
    url: `/comm/control/myhr/common/code`,
    params: { cmd: 'commonCodeList', grpCd: 'T20001' },
  });
};
