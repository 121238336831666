import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonInputDate from '@components/common/atoms/CommonInputDate';
import { currentCommonMessageAtom } from '@components/common/atoms/CommonMessage';
import { Moment } from 'moment';
import React, { FC } from 'react';
import { useSetRecoilState } from 'recoil';
interface IFamilyBirthdayProps {
  occDate: Moment;
  occCd: string;
  familyInfoYn: string;
}
const FamilyBirthday: FC<IFamilyBirthdayProps> = ({ occDate, occCd, familyInfoYn }) => {
  const setMessage = useSetRecoilState(currentCommonMessageAtom);
  return (
    <CommonFormField
      name={'famBirYmd'}
      rules={[
        {
          required: familyInfoYn === 'Y',
        },
        {
          validator: async (_, familyBirthday) => {
            let age = occDate.year() - familyBirthday.year();
            /*
            const m = occDate.month() - familyBirthday.month();
            if (m < 0 || (m === 0 && occDate.date() < familyBirthday.date())) {
              age--;
            } */

            if (occCd === '00009' && ![69, 70, 71].includes(age)) {
              setMessage({
                open: true,
                message: '경조대상의 나이가 경조일 기준 만 69, 70세만 신청 가능합니다.',
                type: 'error',
              });
              return Promise.reject('경조대상의 나이가 경조일 기준 만 69, 70세만 신청 가능합니다.');
            } else {
              return Promise.resolve();
            }
          },
        },
      ]}>
      <CommonInputDate label='대상자 생년월일' />
    </CommonFormField>
  );
};

export default FamilyBirthday;
