import { useDiseaseFamilyName } from '@apis/disease';
import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonInput from '@components/common/atoms/CommonInput';
import CommonSelect from '@components/common/molecules/CommonSelect';
import { FC } from 'react';
import { CommonDiseaseItemInterface } from './AddDiseaseItem';
interface ITargetNameProps {
  famCd?: string;
}
const TargetName: FC<ITargetNameProps & CommonDiseaseItemInterface> = ({ field, famCd }) => {
  const { data: familyNameList } = useDiseaseFamilyName(famCd);
  return (
    <CommonFormField
      rules={[
        {
          required: true,
        },
      ]}
      name={[field.name, 'famNm']}>
      {(familyNameList?.length ?? 0) > 0 ? (
        <CommonSelect
          title='대상자명'
          options={familyNameList?.map((item) => ({
            label: item.codeNm,
            value: item.code,
          }))}
        />
      ) : (
        <CommonInput label='대상자명' variant='outlined' />
      )}
    </CommonFormField>
  );
};

export default TargetName;
