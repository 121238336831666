import { useUserInfo } from '@components/common/molecules/CommonUserInfo';
import { useRequest } from 'src/axios/fetcher';

export interface IPayslipListInterface {
  payActionCd: string;
  payActionNm: string;
}

export interface IPayslipDetailResponse {
  name: string;
  sabun: string;
  orgNm: string;
  totEarningMon: string;
  totDedMon: string;
  taxBaseMon: string;
  bankNm: string;
  accountNo: string;
  resultMon: string;
  bigo: string;
  jikweeNm: string;
  paySchedule: string;
  payNm: string;
  paymentYmd: string;
  workDdCnt: string;
  totWorkHhCnt: string;
  elementCd: string;
  elementNm: string;
  reportNm: string;
  formula: string;
  resultMonor: string;
}

export interface IPaymentHistoryResponse {
  reportNm: string;
  resultMon: string;
}

export interface IPaymentCalculMethodResponse {
  elementNm: string;
  formula: string;
  resultMonFor: string;
}

export interface IPaymentSalaryEtcResponse {
  bizCd: string;
  bizNm: string;
  elementCd: string;
  elementNm: string;
  eleValue: string;
  unit: string;
}

// 급여일자 리스트 조회
export const usePayslipList = (year?: string) => {
  const { sabun } = useUserInfo();
  return useRequest<IPayslipListInterface[]>({
    url: '/dhl/kr/myhr/payslip/payslips',
    params: { sabun, year },
  });
};

// 급여명세서 조회
export const usePayslipDetail = (payActionCd?: string) => {
  const { sabun } = useUserInfo();
  return useRequest<IPayslipDetailResponse[]>({
    url: '/dhl/kr/myhr/payslip/payslip',
    params: { sabun, payActionCd },
  });
};

// 지급내역 조회
export const usePaymentDetail = (payActionCd?: string) => {
  const { sabun } = useUserInfo();
  return useRequest<IPaymentHistoryResponse[]>(
    payActionCd
      ? {
          url: '/dhl/kr/myhr/payslip/paymentHistory',
          params: { sabun, payActionCd, elementType: 'A' },
        }
      : null,
  );
};

// 공제내역 조회
export const useDeductionDetail = (payActionCd?: string) => {
  const { sabun } = useUserInfo();
  return useRequest<IPaymentHistoryResponse[]>(
    payActionCd
      ? {
          url: '/dhl/kr/myhr/payslip/paymentHistory',
          params: { sabun, payActionCd, elementType: 'D' },
        }
      : null,
  );
};

// 지급 계산방법 조회
export const usePaymentCalculMethod = (payActionCd?: string) => {
  const { sabun } = useUserInfo();
  return useRequest<IPaymentCalculMethodResponse[]>(
    payActionCd
      ? {
          url: '/dhl/kr/myhr/payslip/salaryCalculMethod',
          params: { sabun, payActionCd, elementType: 'A' },
        }
      : null,
  );
};

// 공제 계산방법 조회
export const useDeductionCalculMethod = (payActionCd?: string) => {
  const { sabun } = useUserInfo();
  return useRequest<IPaymentCalculMethodResponse[]>(
    payActionCd
      ? {
          url: '/dhl/kr/myhr/payslip/salaryCalculMethod',
          params: { sabun, payActionCd, elementType: 'D' },
        }
      : null,
  );
};

// 근태/기타내역 조회
export const useSalaryEtc = (payActionCd?: string) => {
  const { sabun } = useUserInfo();
  return useRequest<IPaymentSalaryEtcResponse[]>(
    payActionCd
      ? {
          url: '/dhl/kr/myhr/payslip/salaryEtc',
          params: { sabun, payActionCd },
        }
      : null,
  );
};
