import { COLOR } from '@constants/Color';
import { ReadonlyBackground } from '@constants/CommonStyles';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { InputAdornment, TextField } from '@mui/material';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { IFieldChildrenProps } from './CommonForm';
import CommonVibe from './CommonVibe';
interface ICommonInputDateProps extends IFieldChildrenProps {
  label: ReactNode;
  value?: Date;
  readOnly?: boolean;
  onChange?: (date: Date | null, keyboardInputValue?: string) => void;
}

const CommonInputDate: FC<ICommonInputDateProps> = ({ label, value, onChange, iserror, meta, readOnly, ...props }) => {
  const [date, setDate] = useState<Date | null>(value ?? null);
  const handleChange = (date: Date | null, textInput?: string) => {
    setDate(date);
    onChange && onChange(date, textInput);
  };

  useEffect(() => {
    setDate(value ?? null);
  }, [value]);

  const validBorder = useMemo(() => {
    return iserror ? { boxShadow: `0px 0px 0px 2px ${COLOR.RED}`, width: 'calc(100% - 4px)', margin: '0 2px' } : {};
  }, [iserror]);
  const readOnlyStyle = useMemo(() => (readOnly ? ReadonlyBackground : {}), [readOnly]);
  return (
    <CommonVibe iserror={!!iserror}>
      <MobileDatePicker
        label={label}
        inputFormat='YYYY-MM-DD'
        value={date}
        onChange={handleChange}
        cancelText={''}
        okText={'선택'}
        renderInput={(params) => (
          <TextField
            style={readOnlyStyle}
            color={'secondary'}
            fullWidth
            {...params}
            InputLabelProps={{
              ...params.InputLabelProps,
              style: {
                fontSize: 16,
              },
            }}
            InputProps={{
              ...params.InputProps,
              readOnly,
              style: {
                fontSize: 16,
                height: 50,
                ...validBorder,
                ...readOnlyStyle,
              },
              endAdornment: (
                <InputAdornment position='end'>
                  <EventRoundedIcon color={'primary'} />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </CommonVibe>
  );
};

export default CommonInputDate;
