import { IProofRequestParam } from '@apis/proofRequest';
import CommonButton from '@components/common/atoms/CommonButton';
import { currentCommonMessageAtom } from '@components/common/atoms/CommonMessage';
import HorizontalBlank from '@components/common/atoms/HorizontalBlank';
import VerticalBlank from '@components/common/atoms/VerticalBlank';
import Select from '@components/common/molecules/CommonSelect';
import { FC, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { useApplCd, useApplStatusCd } from 'src/util/commonCode';
import styled from 'styled-components';
import ProofRequestModal from './ProofRequestModal';
interface IProofSearchFormProps {
  setParam: React.Dispatch<React.SetStateAction<IProofRequestParam | undefined>>;
}
// 제증명 신청
const ProofSearchForm: FC<IProofSearchFormProps> = ({ setParam }) => {
  const { data: listApplCd } = useApplCd();
  const { data: listApplStatusCd } = useApplStatusCd();
  const [applCd, setApplCd] = useState<string>();
  const [applStatusCd, setApplStatusCd] = useState<string>();
  const [requestModalShow, isRequestModalShow] = useState(false);
  const setMessage = useSetRecoilState(currentCommonMessageAtom);
  const insertClick = () => {
    if (!applCd) {
      setMessage({
        open: true,
        message: '신청서종류를 선택하여 주십시오.',
        type: 'warning',
      });
    } else {
      if (applCd === '13' || applCd === '14') {
        setMessage({
          open: true,
          message: '재직자는 경력증명서를 신청할 수 없습니다.',
          type: 'warning',
        });
      } else {
        isRequestModalShow(true);
      }
    }
  };
  return (
    <>
      <HorizontalBlank height={6} />
      <Select
        options={
          listApplCd?.map((item) => {
            const label = item.codeNm;
            const value = item.code;
            return { label, value };
          }) || []
        }
        title={'신청서종류'}
        onChangeItem={setApplCd}></Select>
      <HorizontalBlank height={15} />
      <Select
        options={
          listApplStatusCd?.map((item) => {
            const label = item.codeNm;
            const value = item.code;
            return { label, value };
          }) || []
        }
        title={'결재상태'}
        onChangeItem={setApplStatusCd}></Select>
      <ButtonContainer>
        <CommonButton
          onClick={() => {
            setParam({
              applCd,
              applStatusCd,
            });
          }}>
          조회
        </CommonButton>
        <VerticalBlank width={15} />
        <CommonButton onClick={insertClick}>신청</CommonButton>
        {requestModalShow && (
          <ProofRequestModal
            open={requestModalShow}
            onClose={() => isRequestModalShow(false)}
            searchApplCd={applCd}
            authPg={'신청'}
          />
        )}
      </ButtonContainer>
    </>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;
export default ProofSearchForm;
