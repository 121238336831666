import { AxiosResponse } from 'axios';
import { RefreshTokenAndReattempRequests } from './refreshToken';

export const responseSuccessInterceptor = async (response: AxiosResponse<any>) => {
  return response;
};

export const responseErrorInterceptor = async (error: any) => {
  // 인증 에러 처리
  const isUnauthorized = error?.response?.status === 401 || error?.response?.status === 403;
  //console.log('isUnauthorized', isUnauthorized, error);
  if (isUnauthorized) {
    return RefreshTokenAndReattempRequests(error);
  }

  // 네트웍 에러 (타임아웃 포함)
  let isNetworkError = false;
  if (error?.message === 'Network Error' || error?.code === 'ECONNABORTED') {
    error.code = 'ECONNABORTED';
    isNetworkError = true;
  }

  if (isNetworkError) {
  } else {
    console.log(
      error?.response?.config?.method?.toUpperCase(),
      error?.response?.config?.url,
      error?.response?.status,
      error?.response,
    );
  }

  return Promise.reject(error);
};
