import CommonContainer from '@components/common/atoms/CommonContainer';
import { useHeaderStore } from '@stores/headerStore';
import { useEffect } from 'react';
import StudentFundsList from './templates/StudentFundsList';
import StudentEventSearchForm from './templates/StudentSearchForm';

const StudentFundsPage = () => {
  const { setConfig } = useHeaderStore();
  useEffect(() => {
    setConfig({ title: '학자금' });
  }, [setConfig]);
  return (
    <>
      <CommonContainer style={{ padding: 15 }}>
        <StudentEventSearchForm />
      </CommonContainer>
      <StudentFundsList />
    </>
  );
};

export default StudentFundsPage;
