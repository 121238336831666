import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonSelect from '@components/common/molecules/CommonSelect';
import { FC } from 'react';
import { useDiverCodeList } from 'src/util/commonCode';

const DiverSelect: FC = () => {
  const { data: diverCodeList } = useDiverCodeList();
  return (
    <>
      <CommonFormField
        name={'diver'}
        rules={[
          {
            required: true,
          },
        ]}>
        <CommonSelect
          title='분기'
          options={diverCodeList?.map((item) => ({
            label: item.codeNm,
            value: item.code,
          }))}
        />
      </CommonFormField>
    </>
  );
};

export default DiverSelect;
