import { IProofRequestParam, useProofList } from '@apis/proofRequest';
import Empty from '@components/common/atoms/Empty';
import { FC } from 'react';
import ProofItem from './ProofItem';

const ProofList: FC<IProofRequestParam> = (param) => {
  const { data: list } = useProofList(param);

  if (list?.length === 0) {
    return <Empty />;
  }
  return (
    <>
      {list?.map((item, index) => (
        <ProofItem data={item} index={index} key={item.applSeq} />
      ))}
    </>
  );
};

export default ProofList;
