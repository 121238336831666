import { useAddress } from '@apis/proofRequest';
import { CommonFormField } from '@components/common/atoms/CommonForm';
import CommonInput from '@components/common/atoms/CommonInput';
import { currentCommonMessageAtom } from '@components/common/atoms/CommonMessage';
import CommonSelect from '@components/common/molecules/CommonSelect';
import { FormInstance } from 'rc-field-form';
import { FC, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';

interface IProofOfEmpKorModalProps {
  form: FormInstance<any>;
  addressType: string;
  tenure?: string;
}

const ProofOfEmpEng: FC<IProofOfEmpKorModalProps> = ({ form, addressType }) => {
  const { data: addrData } = useAddress({ searchAddrSelect: addressType });
  const setMessage = useSetRecoilState(currentCommonMessageAtom);
  useEffect(() => {
    form.setFieldsValue({ address: addrData?.[0]?.engAddr ?? '' });
  }, [addrData, form]);
  return (
    <ModalContainer>
      <CommonFormField
        name={'empYmd'}
        rules={[
          {
            required: true,
          },
        ]}>
        <CommonInput label='재직기간' variant='outlined' />
      </CommonFormField>
      <CommonFormField
        name={'addressType'}
        rules={[
          {
            required: true,
          },
        ]}>
        <CommonSelect
          title='주소구분'
          options={[
            { label: '회사주소', value: '1' },
            { label: '현주소', value: '2' },
            { label: '주민등록지', value: '3' },
          ]}
        />
      </CommonFormField>
      <CommonFormField
        name={'address'}
        rules={[
          {
            required: true,
          },
        ]}>
        <CommonInput label='주소' variant='outlined' />
      </CommonFormField>
      <CommonFormField
        name={'purpose'}
        rules={[
          {
            required: true,
          },
          {
            validator: (_, value) => {
              if (!value) {
                setMessage({
                  open: true,
                  message: '용도를 입력하여 주십시오.',
                  type: 'error',
                });
                return Promise.reject('용도를 입력하여 주십시오.');
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}>
        <CommonInput label='용도' variant='outlined' />
      </CommonFormField>
      <CommonFormField
        name={'resNoYn'}
        rules={[
          {
            required: true,
          },
          {
            validator: (_, value) => {
              if (!value) {
                setMessage({
                  open: true,
                  message: '인쇄항목을 선택하여 주십시오.',
                  type: 'error',
                });
                return Promise.reject('인쇄항목을 선택하여 주십시오.');
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}>
        <CommonSelect
          title='인쇄항목선택'
          options={[
            { label: '주민번호 포함', value: 'Y' },
            { label: '주민번호 불포함', value: 'N' },
          ]}
        />
      </CommonFormField>
      <CommonFormField name={'etc'}>
        <CommonInput label='기타' variant='outlined' />
      </CommonFormField>
    </ModalContainer>
  );
};

const ModalContainer = styled.div`
  padding: 15px 0;
  display: grid;
  gap: 16px;
  overflow-y: scroll;
  position: relative;
`;

export default ProofOfEmpEng;
